<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCONFERENCIACONCLUIDATIPOASSISTENCIAVENDA.CONFERENCIACONCLUIDA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless" style="overflow:hidden !important">
  
            <div class="panel">

                
                <div class="columns" >
                    <div class="column " >
                        <b-notification  type="is-info" has-icon icon-size="is-medium" :closable="false" role="alert" >
                            {{$t('MODALCONFERENCIACONCLUIDATIPOASSISTENCIAVENDA.MENSAGEMCONFERENCIACONCLUIDA')}}
                        </b-notification> 
                    </div>
                    <br>
                </div>   

                <p class="panel-block">
                    <b-field :label="$t('MODALCONFERENCIACONCLUIDATIPOASSISTENCIAVENDA.PLANTAS')">
                    </b-field>
                </p>                
                
                <div class="panel-block">                    
                    <b-radio v-model="possuiPlantasInstalacao"
                        name="possuiPlantasInstalacao"                                    
                        native-value="true">
                        {{$t('SISTEMA.SIM')}}
                    </b-radio>                    
                    <b-radio v-model="possuiPlantasInstalacao"
                        name="possuiPlantasInstalacao"                                    
                        native-value="false">
                        {{$t('SISTEMA.NAO')}}
                    </b-radio>                    
                </div>           
                                                         
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="conferenciaConcluida">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removerconferenciaConcluidaDeVenda');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'    
    
    export default {
        props: {
            id: Number,
            vendaId: Number                                                                          
        },
        data() {
            return {                                               
                salvandoDados :false,
                possuiPlantasInstalacao:false,
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            return true;

        },
        methods: {        

            conferenciaConcluida(){
                this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.vendaId}`);     
                    params.push(`plantas=${this.possuiPlantasInstalacao}`);     
                                                                                                              
                    try {
                                   
                        this.$http.get(`/api/venda/ConferenciaConcluidaTipoAssistencia?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;                                                            
                            this.$emit('close'); 
                            this.$router.back();                                 
                        });                             
                        
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
            },

            

        }
    }
</script>