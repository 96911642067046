<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p v-if="!validarAssinatura" class="modal-card-title">{{$t('MODALCONCLUIRASSINATURAGERENTEVENDA.CONFERENCIACONCLUIDA')}}</p>
            <p v-else class="modal-card-title">{{$t('MODALCONCLUIRASSINATURAGERENTEVENDA.CONCLUIRASSINATURA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">

            <b-notification type="is-warning">                           
                <p>{{$t('MODALCONCLUIRASSINATURAGERENTEVENDA.AVISO')}}</p>
            </b-notification>            
  
            <div class="panel">

                <p class="panel-block">
                    <b-field :label="$t('MODALCONCLUIRASSINATURAGERENTEVENDA.APROVARCONTRATO')">
                    </b-field>
                </p>                
                
                <div class="panel-block">                    
                    <b-radio v-model="aprovado"
                        name="aprovacao"                                    
                        native-value="true">
                        {{$t('SISTEMA.SIM')}}
                    </b-radio>                    
                    <b-radio v-model="aprovado"
                        name="aprovacao"                                    
                        native-value="false">
                        {{$t('SISTEMA.NAO')}}
                    </b-radio>                    
                </div>   

            

                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                    

                       
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removerassinaturaConcluidaGerenteVenda');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'    
    
    export default {
        props: {            
            vendaId: Number
        },
        data() {
            return {                                               
                salvandoDados :false,                
                observacoes:null,   
                aprovado:true,
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            return true;

        },
        methods: {
            conferenciaConcluida(){
                this.salvandoDados=true;
                    const params = [];                    
                    if (this.vendaId) params.push(`id=${this.vendaId}`);
                    params.push(`observacoes=${this.observacoes}`);
                    params.push(`aprovado=${this.aprovado}`);    

                                        
                    try {
                                   
                        this.$http.get(`/api/venda/AssinaturaConcluidaGerenteComercial?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;                                                            
                            this.$emit('close'); 
                            this.$router.back();                                 
                        });                             
                        
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
            },
            async confirmar() {


                if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                }           
                
                this.conferenciaConcluida();
                
            
            },
            

        }
    }
</script>