<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALFATURAMENTOPOSVENDA.FATURAMENTO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALFATURAMENTOPOSVENDA.AVISO')}}</p>
            </b-notification>
            <div class="panel">
                 
                <p class="panel-block">
                    <b-field :label="$t('MODALFATURAMENTOPOSVENDA.FATURAMENTOTIPO')">
                    </b-field>
                </p>

                <div class="panel-block">
                    
                    <div class="columns">                                                                                                    
                        <div class="column">                            
                            <b-radio v-model="tipoFaturamento"  
                                name="tipoFaturamento"                                    
                                native-value="total">
                                {{$t('MODALFATURAMENTOPOSVENDA.FATURAMENTOTOTAL')}}
                            </b-radio>
                        </div>
                        <div class="column ">                            
                            <b-radio v-model="tipoFaturamento" 
                                name="tipoFaturamento"                                    
                                native-value="parcial">
                                {{$t('MODALFATURAMENTOPOSVENDA.FATURAMENTOPARCIAL')}}
                            </b-radio>
                        </div>                                         
                
                    </div>                      
                </div>
                <p v-if="tipoFaturamento=='parcial'" class="panel-block">
                    <b-field :label="$t('GUIAATENDIMENTO.AMBIENTES')">
                    </b-field>
                </p>                
                <div v-if="tipoFaturamento=='parcial'" class="panel-block"> 
                    <table  v-if="posics !=null && posics.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>EList</th>
                                <th>Nota Fiscal</th> 
                                <th>Prazo</th>                                                 
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(p, index) in posics" :key="index">
                                            
                                <td >
                                    <b-checkbox  @input="changed(p)"
                                    size="is-small" type="is-info"  v-model="p.liberarParcial"></b-checkbox >                                    
                                </td>                                
                                <td>{{ p.elist  }}</td>
                                <td>{{ p.notaFiscal  }}</td>
                                <td>{{ $moment(p.dataHoraPrevisao).format("DD/MM/YYYY")  }}</td>                                                                                                                                                              
                            </tr>
                        </tbody>                        
                    </table>  
                </div>                     
                            

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removerconfirmarFaturamento',row); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            row: Object
        },
        data() {
            return {                              
                observacoes: null,
                salvandoDados :false,
                tipoFaturamento: "total",
                posics:[],
                posicsSelecionados:[],                
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.atualizarPosics();
        },
        methods: {

            atualizarPosics(){


                this.salvandoDados=true;
                const params = [];                                                                                                                      
                params.push(`guiaId=${this.row.guiaId}`);  
                params.push(`unidadeId=${this.unidadeId}`);  
                                                                        
                try {                            
                    this.$http.get(`/api/integracao/AtualizarPrazoAmbienteEList?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {                                                
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                              
                        this.loadPosics();                                                                                                                          
                    }).catch((error) => {    

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: "Ocorreu um erro na integração com Elist!",
                            type: 'is-danger',
                            queue: false
                        });                                                    
                        throw error;

                    });                               
                    
                } catch (e) {
                    this.salvandoDados=false;                        
                    console.error(e);
                }                    

            },            
            loadPosics() {    
                this.salvandoDados = true;
                
                const params = [];                                                                                                                      
                params.push(`guiaId=${this.row.guiaId}`);                  

                this.$http.get(`/api/posvenda/ListarPosics?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.posics = data;                       
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                  
                    });               
                                    
                                                     
            },                


            changed(posic){

                if(posic.notaFiscal ==null || posic.notaFiscal ==0 || posic.notaFiscal =='0'){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALISTARPOSICS.MENSAGEMAPENASMBIENTESFATURADOS'),
                        type: 'is-warning',
                        hasIcon: true                                          
                    });  
                    setTimeout(() => { posic.liberarParcial = false;},500);                   
                    return;                           
                }

                if(posic.liberarParcial){
                    this.posicsSelecionados.push(posic);
                }else{
                    this.posicsSelecionados = this.posicsSelecionados.filter(x=>x.elist != posic.elist);
                }
                    
            },                                      
            confirmarFaturamentoTotal(){

                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.row.id}`);                                                                                    
                
                    this.$http.get(`/api/posvenda/AtualizarStatusFaturamento?${params.join('&')}`)
                    .then((res) => {
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false; 
                        this.$emit('close');                                                          
                    });  
                
            }, 
            
            confirmarFaturamentoParcial(){

    
                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.row.id}`);                                                                                    

                
                    this.$http.post(`/api/posvenda/FaturamentoParcial?${params.join('&')}`,this.posicsSelecionados)
                    .then((res) => {
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                         
                        this.$emit('loadAsyncData');                                                          
                        this.$emit('close');                                                          
                    });  
                
            },              
 
            async confirmar() {

                if(this.tipoFaturamento=="parcial"){
                 
                    if(this.posicsSelecionados.length<=0){
                            this.$buefy.dialog.alert({
                                title: this.$t('SISTEMA.ATENCAO'),
                                message: this.$t('MODALFATURAMENTOPOSVENDA.MENSAGEMSELECIONEAMBIENTESFATURADOS'),
                                type: 'is-warning',
                                hasIcon: true
                            }); 
                            return false;                              
                    }else{
                        this.confirmarFaturamentoParcial();
                    }
                 
                }else{
                    this.confirmarFaturamentoTotal();
                }
                
            },
            

        }
    }
</script>