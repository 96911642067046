<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCONFERENCIACONCLUIDAVENDA.CONFERENCIACONCLUIDA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless" style="overflow:hidden !important">
  
            <div class="panel">

                
                <div class="columns" >
                    <div class="column " >
                        <b-notification  type="is-info" has-icon icon-size="is-medium" :closable="false" role="alert" >
                            {{$t('MODALCONFERENCIACONCLUIDAVENDA.MENSAGEMCONFERENCIACONCLUIDA')}}
                        </b-notification> 
                    </div>
                    <br>
                </div>
                <div class="columns" >
                    <div class="column " >
                    <article class="panel">
                        <div class="column" >
                            <b-field>
                                <b-upload 
                                accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                multiple expanded drag-drop v-model="files" :disabled="files && files.length==1">
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-medium"> </b-icon>
                                            </p>
                                            <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>
                                            <p>({{$t('ANEXOS.APENASPDF')}})</p>
                                        </div>
                                    </section>
                                </b-upload>                                          
                            </b-field>
                            <div v-if="files" class="tags">
                                <span v-for="(file, index) in files"
                                    :key="index" class="tag is-primary" >
                                    {{file.name}}
                                    <button class="delete is-small" type="button" @click="deleteFile(index)">
                                    </button>
                                </span>                                            
                            </div>                                          
                        </div>                                                                                   
                    </article>                                     
                    </div>                                        
                </div>                

                <p class="panel-block">
                    <b-field :label="$t('MODALCONFERENCIACONCLUIDAVENDA.RESERVA')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="3"></textarea>                                      
                </div>                    
                
                       
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removerconferenciaConcluidaDeVenda');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'    
    
    export default {
        props: {
            id: Number,
            vendaId: Number                                                                          
        },
        data() {
            return {                                               
                salvandoDados :false,
                files: [],
                observacoes:null,
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            return true;

        },
        methods: {
            incluirAnexo(fileList) {
                const formData = new FormData()
                formData.append('id', this.id);
                formData.append('type', 'guia');
                formData.append('etapa', 'conferenciaconcluida');

                Array
                    .from(Array(fileList.length).keys())
                    .map(x => {                        
                        formData.append('anexo', fileList[x], fileList[x].name);
                    });

                this.uploadingAnexo = 0;
                this.isLoading = true;                    
                this.$http.post('/api/atendimento/anexoGuia', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    });                    
                    this.isLoading = false; 
                    this.conferenciaConcluida();            
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
            },            
            deleteFile(index){                
                this.files.splice(index, 1)            
            },
            conferenciaConcluida(){
                this.salvandoDados=true;
                    const params = [];                    
                    if (this.vendaId) params.push(`id=${this.vendaId}`);       
                    if (this.observacoes) params.push(`observacoes=${this.observacoes}`);   
                    
                              
                    try {
                                   
                        this.$http.get(`/api/venda/ConferenciaConcluida?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;                                                            
                            this.$emit('close'); 
                            this.$router.back();                                 
                        });                             
                        
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
            },
            async confirmar() {


                if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                     
                        return false;
                }                           
                                              
                if(this.files == null || this.files.length<1){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('MODALCONFERENCIACONCLUIDAVENDA.MENSAGEMCONFERENCIACONCLUIDA'),
                        type: 'is-danger',
                        queue: false
                    });          
                    return false;          
                }  

                this.incluirAnexo(this.files);
            
            },
            

        }
    }
</script>