const utilFile = {
    async  resizeImage(blobURL,contentType,fileName,percentual) {

        return new Promise((resolve, reject) => {
                          
            //Objeto auxiliar do tipo Imagem                                    
            const image = new Image();
            image.src = blobURL; 
            
            // Evento que dispara quando finalizar ocarregamento do Blob no objeto do tipo imagem
            image.onload = function() {                        
                
                //Cria um elemento do tipo canvas
                const canvas = document.createElement('canvas');

                //Cria o canvas com tamanho da imagem
                const width = image.width;
                const height = image.height;    
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, width, height);                        

                //Adiciona o elemento canvas com a imagem carregada no DOM da pagina.
                document.getElementById("otimizar_imagem").appendChild(canvas);                    

                // Recupera a imagem do canvas em base64, diminuindo a qualidade em %
                const base64data =  canvas.toDataURL("image/jpeg", percentual );
                
                //Convert a imagem base64 em Blob/ novamente
                const sliceSize = 512;
                const byteCharacters = window.atob(base64data.split(',')[1]);
                const byteArrays = [
                ];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                const newBlob = new Blob(byteArrays, { type: contentType, lastModified: new Date() });
                                
                newBlob.lastModifiedDate = new Date();
                newBlob.name = fileName;                        
                resolve(newBlob);
            }
        });
                                          
    },

    async  createFileReader(file) {
                                                                                                                               
        return new Promise((resolve, reject) => {
                
                //Cria um FileReader para ler a imagem
                const reader = new FileReader();                                                                         
                reader.readAsArrayBuffer(file);

                // Evento que dispara quando finalizar a leitura da imagem
                reader.onloadend = function(event) {
                    
                    //Cria uma blob com a imagem
                    const blob = new Blob([event.target.result]); 
                    window.URL = window.URL || window.webkitURL;
                    const blobURL = window.URL.createObjectURL(blob); 
                    resolve(blobURL);                             
                };                        

                
            });

                                        
    }, 
    
    isFileImage(file) {
        return file && file['type'].split('/')[0] === 'image';
    },    
    
    
  }
  
  export default utilFile