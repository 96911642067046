<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALINTEGRACAOELIST.ATUALIZARCLIENTES')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALINTEGRACAOELIST.AVISO')}}</p>
            </b-notification>            
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALINTEGRACAOELIST.DATACADASTRO')">
                    </b-field>
                </p>
                <div class="panel-block">                    
                    <periodoHora :dataInicial.sync="datas[0]"
                            :dataFinal.sync="datas[1]" />
                    <b-button class="is-primary" title="Pesquisar Clientes" @click="listarClientesEList()" icon-left="magnify"></b-button>                                                                           
                </div>                  
                                       

                <p  class="panel-block">
                    <b-field :label="$t('GUIAATENDIMENTO.CLIENTES')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <table  v-if="clientes !=null && clientes.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th>
                                    <b-checkbox  @input="changedAll()"
                                                    size="is-small" type="is-info"  v-model="selecionadoTodos"></b-checkbox >                                                                                                                        
                                </th>
                                <th>Código</th>
                                <th>Nome</th>
                                <th>Email</th>                                                                                                      
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(cliente, index) in clientes" :key="index">
                                
                                <td>
                                        <b-checkbox  @input="changed(cliente)"
                                        size="is-small" type="is-info"  v-model="cliente.selecionado"></b-checkbox >                                    
                                </td>                                
                                <td>{{ cliente.codigoExterno  }}</td>
                                <td>{{ cliente.nome }}</td>                                                    
                                <td>{{ cliente.email }}</td>                                                                                                                                                                 
    
                            </tr>
                        </tbody>                        
                    </table>  
                </div>                  
  

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); " >{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import periodoHora from '@/components/periodoHora.vue'
    import { mapState } from 'vuex'
    
    export default {
        data() {
            return {                                              
                salvandoDados :false,
                datafiltro: null,
                clientes:[],
                clientesSelecionados:[],
                messages:[],
                datas: [
                    null,
                    null
                ],                
                selecionadoTodos:false                  
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        components: {
            periodoHora
        },        
        methods: {

            changedAll(){

                if(this.selecionadoTodos){

                    this.clientes.forEach((item) => {
                        item.selecionado=true;
                        this.changed(item);
                    });                    

                }else{

                    this.clientes.forEach((item) => {
                        item.selecionado=false;
                        this.changed(item);
                    });                        

                }

            },               
            changed(cliente){

                if(cliente.selecionado){
                    this.clientesSelecionados.push(cliente);
                }else{
                    this.clientesSelecionados = this.clientesSelecionados.filter(x=>x.codigoExterno != cliente.codigoExterno);
                }
                    
            },            
            listarClientesEList(){

                
                const dataInicial = this.$moment(this.datas[0]);
                const dataFinal = this.$moment(this.datas[1]);

                const diff = dataFinal.diff(dataInicial, 'days');


                if(diff>7){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALINTEGRACAOELIST.MENSAGEMPERIODOPERMITIDO'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;                              
                }                
          
                
                this.salvandoDados = true;   
                
                const params = [];                                                                                                                                     
                params.push(`unidadeId=${this.unidadeId}`);
                params.push(`dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`);

                this.$http.get(`/api/integracao/ListarClientesEList?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.clientes = data;                                               
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                  
                    });                 

            },
            async confirmar() {


                if(this.clientesSelecionados.length<=0){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALINTEGRACAOELIST.MENSAGEMSELECIONECLIENTES'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;                              
                }

                this.salvandoDados=true;                                                                                                
                this.$http.post(`/api/integracao/AtualizarClientesEList`,this.clientesSelecionados)
                .then(res => res.data)
                .then(data => {  
                    this.messages = data; 
                    this.salvandoDados=false;
                }).finally(() => {                                
                    if(this.messages !=null && this.messages.length>0){
                        this.messages.forEach((m) => {

                        this.$buefy.toast.open({
                            duration: 10000,
                            message: m.message,
                            type: m.type,
                            queue: false
                        })                                    

                        });                  
                    }
                    
                    this.$emit('loadData');  
                    this.$emit('close');                                                          
                });                     
    

            },
            

        }
    }
</script>