<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALLISTARCONTRATOS.LISTARCONTRATOS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALLISTARCONTRATOS.AVISO')}}</p>
            </b-notification>            
            <div class="panel">
                 
                <p  class="panel-block">
                    <b-field :label="$t('MODALLISTARCONTRATOS.CONTRATOS')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <table  v-if="contratos !=null && contratos.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Contrato</th>
                                <th>Data Contrato</th>                                                                                                                                                                      
                                <th>Valor Prazo</th> 
                                <th>Valor Vista</th> 
                                <th>Valor Vista Sem Indicador</th> 
                                <th>Tipo Contrato</th> 
                                
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(contrato, index) in contratos" :key="index">
                                
                                <td>
                                        <b-checkbox :disabled="contratoSelecionado!=null && !contrato.selecionado"  @input="changed(contrato)"
                                        size="is-small" type="is-info"  v-model="contrato.selecionado"></b-checkbox >                                    
                                </td>                                
                                <td>{{ contrato.codigoContrato  }}</td>
                                <td>{{ $moment(contrato.dataContrato).format("DD/MM/YYYY") }}</td>                                     
                                <td>R$ {{ contrato.valorPrazo.toFixed(2).toString().replace('.',',') }}</td> 
                                <td>R$ {{ contrato.valorVista.toFixed(2).toString().replace('.',',') }}</td> 
                                <td>R$ {{ contrato.valorVistaSemIndicador.toFixed(2).toString().replace('.',',') }}</td>                                 
                                <td>{{ contrato.tipoContrato  }}</td>
                            </tr>
                        </tbody>                        
                    </table>  
                </div>


            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            guiaId: Number,   
            clienteCodigoExterno: Number  
        },
        data() {
            return {                                              
                salvandoDados :false,                
                contratos:[],                
                contratoSelecionado:null,
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.loadContratos();
        },   
        methods: {
                         
                          
            changed(contrato){
                if(contrato.selecionado){
                    this.contratoSelecionado = contrato;                    
                }else{
                    this.contratoSelecionado = null;                    
                }                                
                
            },               
            loadContratos() {    
                this.salvandoDados = true;   
                
                const params = [];                                                                                                      
                params.push(`clienteId=${this.clienteCodigoExterno}`);  
                params.push(`guiaId=${this.guiaId}`);  
                params.push(`unidadeId=${this.unidadeId}`);                 

                this.$http.get(`/api/integracao/ListarContratosEList?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.contratos = data;                       
                        this.isLoading=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                  
                    });               
                    
            },                        

           
            async confirmar() {
                              
                if(this.contratoSelecionado==null){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALLISTARPECAS.MENSAGEMSELECIONECONTRATO'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;                              
                }else{                    
                    this.$emit('preencherDadosContrato', this.contratoSelecionado);
                    this.$emit('close');                                  
                }
                
      
                
            },
            

        }
    }
</script>