<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALALTERARVALORPREVISTO.ALTERARVALORPREVISTO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless" >
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALALTERARVALORPREVISTO.AVISO')}}</p>
            </b-notification>           
                         
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALALTERARVALORPREVISTO.VALORPREVISTO')">
                    </b-field>
                </p>
                <div class="panel-block">   
                    
                    <button class="button is-light" type="button">R$</button>         
                    <b-input step=".00000001"  type="number" v-model="valorPrevisto"> </b-input>                                                                      
                </div>                  
               

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number,    
            clienteId: Number,                                                           
        },
        data() {
            return {                          
               
                salvandoDados :false,
                valorPrevisto:(0).toFixed(2),
              
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        methods: { 

            async confirmar() {         

                    if (!this.valorPrevisto || this.valorPrevisto <=0 ) {
                        return;
                    }       
                                        
                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.id}`);                                                                    
                    params.push(`valorPrevisto=${this.valorPrevisto}`);                                                            
                    
                                        
                    try {                                                
                        this.$http.get(`/api/atendimento/AlterarValorPrevisto?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;    
                            this.$emit('loadData')                            
                            this.$emit('close');                                                               
                        });                             
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
                

            },
            

        }
    }
</script>