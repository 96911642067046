<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SISTEMA.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="150" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="data" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PRAZOCADASTRO.DIAS')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="number" min="1" maxlength="150" v-model="model.dias"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>                                       
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('prazo-alterar')"
                                      :loading="salvandoDados"
                                      native-type="button" @click="salvarPrazo()"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    dias: null
                },
                ano: new Date().getFullYear(),
                salvandoDados: false
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/prazo?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;                        
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('PRAZOCADASTRO.PRAZO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([                                
                'unidadeId',                
            ]),                
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }
                
                this.model.UnidadeDeCadastro = { id : this.unidadeId};
                
                this.$http.post(`/api/manutencao/prazo`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            },
            salvarPrazo() {
                this.onSubmit();
            }
        }
    }
</script>