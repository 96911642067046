<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCONCLUIRELETROANEXOVENDA.ELETROSCONCLUIDO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">

            <b-notification type="is-info">                           
                <p>{{$t('MODALCONCLUIRELETROANEXOVENDA.MENSAGEMELETROSCONCLUIDA')}}</p>
            </b-notification>            
  
            <div class="panel">


                <p class="panel-block">
                    <b-field :label="$t('MODALCONCLUIRELETROANEXOVENDA.ELETROS')">
                    </b-field>
                </p>       
                <p class="panel-block">

                    <b-field class="file is-primary" :class="{'has-name': !!eletros}">
                        <b-upload
                        accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                            v-model="eletros" class="file-label">
                            
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>                                                                                        
                                    <span class="file-label">Carregar Aquivo</span>
                                </span>                                            
                            <span class="file-name" v-if="eletros">
                                {{ eletros.name }}
                            </span>
                        </b-upload>
                    </b-field>                 

                </p>
                       
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removereletrosAnexoConcluidaVenda');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'    
    
    export default {
        props: {
            id: Number,
            vendaId: Number                                                                          
        },
        data() {
            return {                                               
                salvandoDados :false,
                files: [],
                eletros:null,                
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            return true;

        },
        methods: {
            incluirAnexo(file,etapa) {
                const formData = new FormData()
                formData.append('id', this.id);
                formData.append('type', 'guia');
                formData.append('etapa',etapa);                      
                formData.append('anexo', file, file.name);
                  

                this.uploadingAnexo = 0;
                this.isLoading = true;                    
                this.$http.post('/api/atendimento/anexoGuia', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    });                    
                    this.isLoading = false; 
                    
                    this.eletrosConcluida();            
                    
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
            },            
            deleteFile(index){                
                this.files.splice(index, 1)            
            },
            eletrosConcluida(){
                this.salvandoDados=true;
                    const params = [];                    
                    if (this.vendaId) params.push(`id=${this.vendaId}`);                                                            
                                        
                    try {
                                   
                        this.$http.get(`/api/venda/EletrosAnexoConcluida?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;                                                            
                            this.$emit('close'); 
                            this.$router.back();                                 
                        });                             
                        
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
            },
            async confirmar() {

                if(this.eletros == null ){

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('MODALCONCLUIRELETROANEXOVENDA.MENSAGEMELETROSCONCLUIDA'),
                        type: 'is-danger',
                        queue: false
                    });          
                    return false;          
                }  
                
                this.incluirAnexo(this.eletros,"eletros");
            
            },
            

        }
    }
</script>