<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALVERSTATUSATUALGUIA.VERSTATUS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless" >            
            <div class="media" style="text-align: center;">
                <div class="media-content">
                   
                    <div>
                            <span class="icon has-text-info is-large"> <i class="mdi mdi-information mdi-48px"></i> </span>
                            <p style="font-size:20px"><b>{{ processo }}</b> <a @click="$emit('encontrarCliente',processo,etapa,clienteNome); $emit('close');"> 
                                <b-icon icon="map-marker" size="is-small" title="Encontrar Cliente" type="is-info"></b-icon>                                  
                            </a> <br>{{ etapa }}</p>
                    </div>
                   
                         
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-info" @click="$emit('close')">{{$t('SISTEMA.OK')}}</b-button>            
        </footer>
    </div>
</template>
<script>    

    import { mapState } from 'vuex'
    
    export default {
        props: {
            processo: String,   
            etapa: String,   
            clienteNome: String
                                                              
        },
        data() {
            return {                          
               
                salvandoDados :false
              
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
    }
</script>