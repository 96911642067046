<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('NOTIFICAGUIA.REPORTAR')}}</p>
        </header>
        <section class="modal-card-body">
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('NOTIFICAGUIA.EMAIL')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-input maxlength="100" v-model="emailDestinos"></b-input>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('NOTIFICAGUIA.EMAILCC')">
                    </b-field>
                </p>                
                <p class="panel-block">
                    <b-input maxlength="100" v-model="emailCopias"></b-input>
                </p>                
                <p class="panel-block">
                    <b-field :label="$t('NOTIFICAGUIA.INFO')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-input maxlength="150" type="textarea" v-model="informacoes"></b-input>
                </p>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                      :loading="salvandoDados"
                      @click="NotificaGuia()">{{$t('SISTEMA.ENVIAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object            
        },
        data() {
            return {                                
                motivoAlertaExameSelecionado: [],
                emailDestinos: null,
                emailCopias: null,
                informacoes: '',
                salvandoDados: false
            }
        },
        computed: {

        },
        created() {

            const params = [                                        
                    `guiaId=${this.guia.id}`                                
                ];


            this.$http.get(`/api/atendimento/EmailsRelacionadosGuia?${params.join('&')}`)
                .then(res => res.data)
                .then(data => {
                
                    this.emailDestinos = data.destinos.join(';');
                    this.emailCopias = data.copias.join(';');
                    
                })                
                                            
        },
        methods: {
            NotificaGuia() {
                this.salvandoDados = true;

                const params = [                                        
                    `guiaId=${this.guia.id}`,                    
                    `informacoes=${this.informacoes}`,
                    `emailDestinos=${this.emailDestinos}`,
                    `emailCopias=${this.emailCopias}`
                ];  
                
                this.$http.post(`/api/atendimento/NotificaGuia?${params.join('&')}`)
                    .then((res) => {
                        this.salvandoDados = false
                        if (res.body.erro) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.body.erro,
                                type: 'is-danger'
                            })
                        } else {
                            this.$emit('close')
                        }
                    })                
                
       
            }
        }
    }
</script>