<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALOBRANAOCONFORMEPOSVENDA.OBRANAOCONFORME')}}</p>
        </header>
        <section class="modal-card-body is-paddingless" >
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALOBRANAOCONFORMEPOSVENDA.AVISO')}}</p>
            </b-notification>            
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');  $emit('removerobranaoconforme');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number,                                                               
        },
        data() {
            return {                          
                observacoes: null,
                salvandoDados :false                
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        methods: {

            async confirmar() {

                    if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }       
                                        
                    this.salvandoDados=true;
                    const params = [];                    
                    if (this.id) params.push(`id=${this.id}`);                                                                                
                    if(this.observacoes) params.push(`observacoes=${this.observacoes}`);
                                        
                    try {                            
                        this.$http.get(`/api/posvenda/ObraNaoConforme?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;    
                            this.$emit('loadData')                            
                            this.$emit('close'); 
                            this.$router.back()                                    
                        });                             
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
                

            },
            

        }
    }
</script>