<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCANCELANDOAGENDA.CANCELARAGENDA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALCANCELANDOAGENDA.AVISOCANCELAMENTO')}}</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoAgenda"
                            type="is-danger">
                {{ erroCancelamentoAgenda }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALCANCELANDOAGENDA.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded v-model="motivoCancelamentoAgendaSelecionado">
                        <option v-for="(motivo,index) in listaMotivosCancelamentoAgenda" :key="index"
                                :value="motivo.descricao">
                            {{ motivo.descricao }}
                        </option>
                    </b-select>
                    
                </div>
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarCancelamentoAgenda">{{$t('MODALCANCELANDOAGENDA.CONFIRMARCANCELAMENTO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number                                          
        },
        data() {
            return {
                procurarMotivosCancelamentoAgenda: '',
                listaMotivosCancelamentoAgenda: [],
                motivoCancelamentoAgendaSelecionado: null,
                erroCancelamentoAgenda: null,
                observacoes: null                        
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            this.loadListaMotivosCancelamentoAgenda()
        },
        methods: {
            loadListaMotivosCancelamentoAgenda() {
                
                const params = [
                    `unidadeId=${this.unidadeId}`,                                        
                ].join('&')
                
                this.$http.get(`/api/search/MotivosCancelamentoAgenda?${params}`)
                    .then(m => {
                        this.listaMotivosCancelamentoAgenda = m.data
                    })
            },
            async confirmarCancelamentoAgenda() {

                if(this.motivoCancelamentoAgendaSelecionado!=null ){
                    const params = [];
                    const acao = 'cancelar';

                    if (this.id) params.push(`id=${this.id}`);
                                                            
                    params.push(`motivo=${this.motivoCancelamentoAgendaSelecionado}`);
                    params.push(`acao=${acao}`);
                    
                    if(this.observacoes) params.push(`observacoes=${this.observacoes}`);

                    try {
                        const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                        const res = await this.$http.get(url);
                        this.$emit('loadData')                            
                        this.$emit('close')                        
                    } catch (e) {
                        console.error(e);
                    }
                }

            },
            

        }
    }
</script>