<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCONCLUIRASSINATURADIGITALVENDA.CONFERENCIACONCLUIDA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">

            <b-notification type="is-warning">                           
                <p>{{$t('MODALCONCLUIRASSINATURADIGITALVENDA.MENSAGEMCONFERENCIACONCLUIDA')}}</p>
            </b-notification>            
  
            <div class="panel">

       
                <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('MODALCONCLUIRASSINATURADIGITALVENDA.SIGNATARIOS')}}
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>{{$t('MODALCONCLUIRASSINATURADIGITALVENDA.EMAIL')}}</th>
                                                <th>{{$t('MODALCONCLUIRASSINATURADIGITALVENDA.TIPO')}}</th>    
                                                <th>{{$t('MODALCONCLUIRASSINATURADIGITALVENDA.ASSINATURACOMFOTO')}}</th>   
                                                <th></th>                                                        
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in signatarios" v-bind:key="index">                                               
                                                <td>
                                                    <input class="input" type="text" v-model="item.email">
                                                </td>                                                
                                                <td>
                                                    <b-select expanded v-model="item.acao" >                                        
                                                        <option :value="1"> Assinar</option>                                                                           
                                                    </b-select>                                                                                                         
                                                </td>
                                                <td>
                                                    <b-switch  v-model="item.assinaturaComFoto"  type="is-success">
                                                        Foto?
                                                    </b-switch>                                                                                                                                                        
                                                </td>     
                                                <td>
                                                    <b-button
                                                        type="is-danger"
                                                        native-type="button"
                                                        icon-left="delete"
                                                        class="button-delete"
                                                        @click="deleteItem(item)"
                                                    ></b-button>                                                 
                                                </td>
                   
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addSignatario">
                                        {{$t('MODALCONCLUIRASSINATURADIGITALVENDA.ADICIONARSIGNATARIO')}}
                                    </button>
                                </div>
                    </article>                                   
                               
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removerconcluirAssinaturaDigitalDeVenda');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'    
    
    export default {
        props: {            
            vendaId: Number                                                                          
        },
        data() {
            return {                                               
                salvandoDados :false,           
            
                signatarios: []               
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            return true;

        },
        methods: {

            deleteItem(item) {
      
                this.signatarios = this.signatarios.filter((i) => i.email !== item.email);            
            },            
            addSignatario() {
                this.signatarios.push({                    
                    email: null,
                    acao: 1,
                    assinaturaComFoto: false                  
                });
            },            
            assinaturaDigital(){
                    this.salvandoDados=true;
                    
                    const formData = new FormData(); 
                    formData.append('assinatura.vendaId', this.vendaId);  
                    

                                            
                    for(let i = 0; i < this.signatarios.length;i++){
                        
                        if(this.signatarios[i].email){
                            formData.append('assinatura.signatarios['+i+'].email', this.signatarios[i].email);                                                                                                              
                            formData.append('assinatura.signatarios['+i+'].acao', this.signatarios[i].acao);
                            formData.append('assinatura.signatarios['+i+'].assinaturaComFoto', this.signatarios[i].assinaturaComFoto);                                                                                                              
                        }

                    }                    
                                        
                    try {
                                   
                        this.$http.post(`/api/venda/AssinaturaDigital`, formData)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;                                                            
                            this.$emit('loadData'); 
                            this.$emit('close');                                
                        });                             
                        
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
            },
            async confirmar() {


                if(this.signatarios.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('MODALCONCLUIRASSINATURADIGITALVENDA.MENSAGEMCADASTRESIGNATARIO'),
                        type: 'is-danger',
                        queue: false
                    });          
                    return false;  
                }

                this.assinaturaDigital();                
            
            },
            

        }
    }
</script>