<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALIBERARENTREGAS.LIBERARENTREGAS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALIBERARENTREGAS.MENSAGEMCONFERIRENTREGAS')}}</p>
            </b-notification>  
            <div class="panel">

                    <table v-if="codigoLote >0" class="table is-fullwidth is-striped is-hoverable"> 
                        <thead>
                            <tr>
                                <th>Cliente</th>
                                <th>Elists</th>
                                <th>Entrega</th>
                            </tr>
                        </thead>    
                        <tbody>
                            <tr  v-for="(item, index) in carregarEntregasLote" v-bind:key="index">
                                <td>{{item.clienteNome}}</td>
                                <td>{{item.elist}}</td>
                                <td>{{item.entrega}}</td>                                
                            </tr>  
                        </tbody>                         
                                               
                    </table>   

                    <table v-else class="table is-fullwidth is-striped is-hoverable"> 
                        <thead>
                            <tr>
                                <th>Cliente</th>
                                <th>Elists</th>
                                <th>Entrega</th>
                            </tr>
                        </thead>    
                        <tbody>
                            <tr  v-for="(item, index) in entregasSelecionados" v-bind:key="index">
                                <td>{{item.clienteNome}}</td>
                                <td>{{item.elist}}</td>
                                <td><textarea class="textarea" v-model="item.entrega" rows="2"></textarea>  </td>
                            </tr>  
                        </tbody>                                                   
                    </table>                                     

                                                   
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     v-if="codigoLote ===0"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button v-if="codigoLote && codigoLote >0" @click="abrirPdf()"  icon-left="eye"  type="is-info"  
            >{{$t('SISTEMA.PDF')}}</b-button>                        
            <b-button @click="$emit('close');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'       
    
    export default {
        props: {
            entregasSelecionados: Array, 
            codigoLote: {
                type: Number,
                default: 0
            },                                                                       
        },
        data() {
            return {                                               
                salvandoDados :false,
                carregarEntregasLote : []                
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            if(this.codigoLote > 0){
                this.loadDataLiberacaoEntrega();
            }
            return true;

        },
        methods: {

            abrirPdf(){                
                const url = `/api/posvenda/LiberarEntregaPDF?CodigoLote=`+this.codigoLote;
                window.open(url, "_blank");
            },  
            loadDataLiberacaoEntrega(){
                this.$http.get('/api/posvenda/RetornaPosVendaPorLoteId?codigoLote=' + this.codigoLote)
                        .then(res => res.data)
                        .then(data => {

                            if(data && data !=""){
                                this.carregarEntregasLote = data;                                                    
                            }

                        });
            },
            async confirmar() {

                 this.$buefy.dialog.confirm({
                    message: this.$t('MODALIBERARENTREGAS.MENSAGEMCONFIRMARENTREGAS'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {   
                                                                                                      
                        try {
                            this.salvandoDados=true; 
                            this.$http.post(`/api/posvenda/ConfirmarLiberacaoEntrega`,this.entregasSelecionados)
                            .then(res => res.data)
                            .then(data => {
                                this.salvandoDados=false; 
                                this.codigoLote = data;
                                this.abrirPdf();                                    
                            }).finally(() => {                                
                                this.salvandoDados = false;  
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: this.$t('SISTEMA.SALVOSUCESSO'),
                                    type: 'is-success',
                                    queue: false
                                });                                               
                                this.$emit('close');                                                                           
                                this.$emit('recarregarEntregas');                                   
                            });                            

                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }
                            
                        
                        return true;
                    },
                    onCancel: () => {                        
                        this.salvandoDados = false;    
                    },
                })  
            
            },
            

        }
    }
</script>