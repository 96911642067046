<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALVISTORIAPARCIALPOSVENDA.VISTORIAPARCIAL')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALVISTORIAPARCIALPOSVENDA.AVISO')}}</p>
            </b-notification>
            <div class="panel">

                <p class="panel-block">
                    <b-field :label="$t('MODALVISTORIAPARCIALPOSVENDA.AMBIENTESLIBERADOS')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <table  v-if="ambientes !=null && ambientes.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Opção</th>
                                <th>Ambiente</th> 
                                <th>Tipo</th>
                                <th>Situação</th>   
                                <th>Valor</th>                                                
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(ambiente, index) in ambientes" :key="index">
                                
                                <td>
                                        <b-checkbox  @input="changed(ambiente)"
                                        size="is-small" type="is-info"  v-model="ambiente.selecionado"></b-checkbox >                                    
                                </td>                                
                                <td>{{ ambiente.opcao  }}</td>
                                <td>{{ ambiente.descricao }}</td>                                                    
                                <td>{{ ambiente.tipoDescricao }}</td>                            
                                <td>{{ ambiente.tipoSituacao }}</td> 
                                <td>R$ {{ ambiente.valor.toString().replace('.',',') }}</td>                                                                                                                                                              
    
                            </tr>
                        </tbody>                        
                    </table>  
                </div>                                                  

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removerVistoriaParcial'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number,
            guiaId:Number
        },
        data() {
            return {                                              
                salvandoDados :false,                
                ambientes:[],
                ambientesSelecionados:[],                   
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.loadAmbientes();
        },
        methods: {

            changed(ambiente){

                if(ambiente.selecionado){
                    this.ambientesSelecionados.push(ambiente);
                }else{
                    this.ambientesSelecionados = this.ambientesSelecionados.filter(x=>x.id != ambiente.id);
                }
                    
            },               
            loadAmbientes() {              
                this.$http.get('/api/atendimento/ambientes?id=' + this.guiaId)
                    .then(res => res.data)
                    .then(data => {
                        this.ambientes = data;

                    });

                    
            },             
            
            confirmarVistoriaParcial(){

                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.id}`);                                                                                    
                
                    this.$http.post(`/api/posvenda/VistoriaParcial?${params.join('&')}`,this.ambientesSelecionados)
                    .then((res) => {
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                 
                        this.$emit('close');                                
                        this.$router.back();                          
                    });  
                
            },              
 
            async confirmar() {


                if(this.ambientesSelecionados.length<=0){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALVISTORIAPARCIALPOSVENDA.MENSAGEMSELECIONEAMBIENTESVISTORIADOS'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;
                }else{
                    this.confirmarVistoriaParcial();
                }                            

                
            },
            

        }
    }
</script>