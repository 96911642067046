<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SISTEMA.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="150" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>

                        <article class="panel">
                            <div class="panel-heading has-text-centered">
                                {{$t('CUSTOCADASTRO.MOTIVOS')}}
                            </div>
                            <div class="panel-block" style="overflow-x: auto;">
                                <table class="table is-bordered  is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{{$t('SISTEMA.CODIGO')}}</th>                                            
                                            <th>{{$t('SISTEMA.NOME')}}</th>                                                                                                                               
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in model.motivos?.filter(i => i.show===true)" v-bind:key="index">
                                            <td><b-button
                                                type="is-danger"
                                                native-type="button"
                                                icon-left="delete"
                                                class="button-delete"
                                                @click ="deleteItem(item,index)" >
                                            </b-button>                                            
                                            </td>                                            
                                            <td> <input class="input" type="number" v-model="item.codigoMotivo" > </td>                                                 
                                            <td> <input class="input" type="text" v-model="item.nome" maxlength="100"> </td>                                                                                                                                                                     
                                        </tr>
                                    </tbody>
                                </table>
                            </div>     
                                <div class="panel-heading has-text-centered">
                                    <b-button
                                        type="is-success"
                                        native-type="button"
                                        icon-left="plus"
                                        class="center"
                                        @click="addItem()">
                                        {{$t('SISTEMA.INCLUIR')}}
                                    </b-button>  
                                </div>                                                     
                        </article>                            
                    </div>                                       
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('custo-alterar')"
                                      :loading="salvandoDados"
                                      native-type="button" @click="salvarCusto()"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    motivos:[]
                    
                },                
                salvandoDados: false
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/custo?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;  
                        
                        this.model.motivos.forEach((motivo) => { 
                            motivo.show = true;                             
                        });                        
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('CUSTOCADASTRO.CUSTO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([                                
                'unidadeId',                
            ]),                
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            addItem() {
                this.model.motivos.push({
                    id: 0,
                    nome: null,
                    codigoMotivo:null,
                    show:true,                    
                })
            },
            
            deleteItem(item,index) {
                if(item.id==0){
                    this.model.motivos.splice(index, 1);
                }else{
                    item.show = false; 
                }
                
                this.$forceUpdate();                
            },               
                       
            onSubmit() {
                this.salvandoDados = true;

                let todosMotivosPreenchidos = true;  
                
                this.model.motivos.filter(i => i.show===true).forEach((motivo) => {
                                                     
                    if(!motivo.nome || !motivo.codigoMotivo){
                        todosMotivosPreenchidos= false;
                     }
                }); 

                if(this.model.id == null){
                    this.model.id = 0
                }
                
                this.model.UnidadeDeCadastro = { id : this.unidadeId};

                if(todosMotivosPreenchidos){
                
                    this.$http.post(`/api/manutencao/custo`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
                }else{
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.CAMPOSOBRIGATORIOS'),
                        type: 'is-warning',
                        hasIcon: true                                          
                    });                    
                    this.salvandoDados = false;
                    return null;
                } 

            },
            salvarCusto() {
                this.onSubmit();
            }
        }
    }
</script>