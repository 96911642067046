<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-info">
            <p class="modal-card-title">{{$t('GUIAATENDIMENTO.CHECKLISTVENDA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="box ">         

                        <div class="columns">                                                                                                    
                                <div class="column ">
                                    <label class="label">{{$t('MODALCHECKLISTVENDA.CHECKCONSENSO')}}</label>
                                    <b-radio v-model="model.checkconsenso"
                                        name="consenso"                                    
                                        native-value="true">
                                        {{$t('SISTEMA.SIM')}}
                                    </b-radio>
                                </div>
                                <div class="column ">
                                    <label class="label" style="visibility: hidden;">{{$t('MODALCHECKLISTVENDA.CHECKCONSENSO')}}</label>
                                    <b-radio v-model="model.checkconsenso"
                                        name="consenso"                                    
                                        native-value="false">
                                        {{$t('SISTEMA.NAO')}}
                                    </b-radio>
                                </div>                                                              
                        </div>  

                        <div v-show="model.checkconsenso == 'true'" class="columns">                                                                                                    
                                <div class="column ">
                                    <b-field  :label="$t('MODALCHECKLISTVENDA.PRAZOCONSENSO')">
                                        <b-datepicker  ref="data"                                        
                                                        :placeholder="$t('AGENDAMENTO.SELECIONARHORA')"
                                                        v-model="model.dataHoraConsenso"
                                                        icon="calendar-today" >
                                        </b-datepicker>                  
                                    </b-field> 
                                    <br>                                      
                                    <br>                                      
                                    <br>                                      
                                    <br>                                      
                                    <br>                                      
                                    <br>                 
                                    <br>                 
                                    <br>                 
                                    <br>                 
                                </div>

                        </div>                          



                        <div v-show="model.checkconsenso == 'false'">                                                                
                            <div class="columns">                                                                                                    
                                    <div class="column ">
                                        <label class="label">{{$t('MODALCHECKLISTVENDA.CHECKPLANTASTECNICAS')}}</label>
                                        <b-radio v-model="model.checkplantastecnicas"
                                            name="plantastecnicas"                                    
                                            native-value="true">
                                            {{$t('SISTEMA.SIM')}}
                                        </b-radio>
                                    </div>
                                    <div class="column ">
                                        <label class="label" style="visibility: hidden;">{{$t('MODALCHECKLISTVENDA.CHECKPLANTASTECNICAS')}}</label>
                                        <b-radio v-model="model.checkplantastecnicas"
                                            name="plantastecnicas"                                    
                                            native-value="false">
                                            {{$t('SISTEMA.NAO')}}
                                        </b-radio>
                                    </div>   
                
                            </div> 
                            <div v-show="model.checkplantastecnicas == 'true'"  class="columns">                                                                                                            
                                    <div class="column ">
                                        <b-field class="file is-primary" :class="{'has-name': !!checkplantastecnicasFile}">
                                            <b-upload 
                                                accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                                v-model="checkplantastecnicasFile" class="file-label">
                                                <span class="file-cta">
                                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                                    <span class="file-label">Carregar Aquivo</span>
                                                </span>
                                                <span class="file-name" v-if="checkplantastecnicasFile">
                                                    {{ checkplantastecnicasFile.name }}
                                                </span>
                                            </b-upload>
                                        </b-field>               
                                    </div>                                                       
                        
                            </div>                       

                            <div class="columns">                                                                                                    
                                    <div class="column ">
                                        <label class="label">{{$t('MODALCHECKLISTVENDA.CHECKPLANTASINSTALACAOCLIENTE')}}</label>
                                        <b-radio v-model="model.checkplantasmontagemcliente"
                                            name="plantasmontagemcliente"                                    
                                            native-value="true">
                                            {{$t('SISTEMA.SIM')}}
                                        </b-radio>
                                    </div>
                                    <div class="column ">
                                        <label class="label" style="visibility: hidden;">{{$t('MODALCHECKLISTVENDA.CHECKPLANTASINSTALACAOCLIENTE')}}</label>
                                        <b-radio v-model="model.checkplantasmontagemcliente"
                                            name="plantasmontagemcliente"                                    
                                            native-value="false">
                                            {{$t('SISTEMA.NAO')}}
                                        </b-radio>
                                    </div>                                         
                        
                            </div> 
                            <div v-show="model.checkplantasmontagemcliente == 'true'"  class="columns">                                                                                                            
                                    <div class="column ">
                                        <b-tooltip  size="is-small" label="Obrigatório anexar print do email enviado ao cliente" position="is-right" type="is-light"  >
                                            <b-field class="file is-primary" :class="{'has-name': !!checkplantasmontagemclienteFile}">
                                                <b-upload
                                                accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                                 v-model="checkplantasmontagemclienteFile" class="file-label">
                                                    
                                                        <span class="file-cta">
                                                            <b-icon class="file-icon" icon="upload"></b-icon>                                                                                        
                                                            <span class="file-label">Carregar Aquivo</span>
                                                        </span>                                            
                                                    <span class="file-name" v-if="checkplantasmontagemclienteFile">
                                                        {{ checkplantasmontagemclienteFile.name }}
                                                    </span>
                                                </b-upload>
                                            </b-field>         
                                        </b-tooltip>      
                                    </div>                                                       
                        
                            </div>                              

                            <div class="columns">                                                                                                    
                                    <div class="column ">
                                        <label class="label">{{$t('MODALCHECKLISTVENDA.CHECKPLANTASINSTALACAOIMPRESSAO')}}</label>
                                        <b-radio v-model="model.checkplantasmontagemimpressao"
                                            name="plantasmontagemimpressao"                                    
                                            native-value="true">
                                            {{$t('SISTEMA.SIM')}}
                                        </b-radio>
                                    </div>
                                    <div class="column ">
                                        <label class="label" style="visibility: hidden;">{{$t('MODALCHECKLISTVENDA.CHECKPLANTASINSTALACAOIMPRESSAO')}}</label>
                                        <b-radio v-model="model.checkplantasmontagemimpressao"
                                            name="plantasmontagemimpressao"                                    
                                            native-value="false">
                                            {{$t('SISTEMA.NAO')}}
                                        </b-radio>
                                    </div>                                                                        
                        
                            </div>  

                            <div v-show="model.checkplantasmontagemimpressao== 'true'"  class="columns">                                                                                                        
                                    <div class="column ">
                                        <b-field class="file is-primary" :class="{'has-name': !!checkplantasmontagemimpressaoFile}">
                                            <b-upload 
                                                accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                                v-model="checkplantasmontagemimpressaoFile" class="file-label">
                                                <span class="file-cta">
                                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                                    <span class="file-label">Carregar Aquivo</span>
                                                </span>
                                                <span class="file-name" v-if="checkplantasmontagemimpressaoFile">
                                                    {{ checkplantasmontagemimpressaoFile.name }}
                                                </span>
                                            </b-upload>
                                        </b-field>               
                                    </div>                                                                         
                        
                            </div>                              

                            <div class="columns">                                                                                                    
                                    <div class="column ">
                                        <label class="label">{{$t('MODALCHECKLISTVENDA.CHECKARQUIVOSREDE')}}</label>
                                        <b-radio v-model="model.checkarquivosrede"
                                            name="arquivosrede"                                    
                                            native-value="true">
                                            {{$t('SISTEMA.SIM')}}
                                        </b-radio>
                                    </div>
                                    <div class="column ">
                                        <label class="label" style="visibility: hidden;">{{$t('MODALCHECKLISTVENDA.CHECKARQUIVOSREDE')}}</label>
                                        <b-radio v-model="model.checkarquivosrede"
                                            name="arquivosrede"                                    
                                            native-value="false">
                                            {{$t('SISTEMA.NAO')}}
                                        </b-radio>
                                    </div> 
                                                                                            
                            </div>                              

                            <div v-if="false" class="columns">   
                                <div class="column is-12">
                                    <b-field :label="$t('SISTEMA.OBSERVACAO')">      
                                        <textarea class="textarea" v-model="observacao" rows="3"></textarea>      
                                    </b-field>
                                </div>
                            </div>  
                        </div>                              
                                                                                                                                                      
                                                  
                                                                                                                                        
            </div>
        </section>
        <footer class="modal-card-foot">
           
            <b-button  type="is-success" icon-left="check-circle" :loading="salvandoDados"  @click="salvarDados"  > {{$t('SISTEMA.SALVAR')}}</b-button>      


            <b-button @click="$emit('close');  $emit('removerCheckListDeVenda');"> {{$t('SISTEMA.FECHAR')}}</b-button>
        </footer>
    </div>
</template>

<style scoped>
    .is-clearfix{
        width:  100% !important;
    }
</style>

<script>    
    
    import { mapGetters, mapState } from 'vuex'  

    export default {
        props: {
            guia: Object
        },
        data() {
            return {
                model:{
                    guiaId: this.guia.id,
                    clienteId: this.guia.clienteId,
                    unidadeId: this.unidadeId,                                     
                                  
                },
                observacao:null,
                checkplantastecnicasFile:null,
                checkplantasmontagemimpressaoFile:null,
                checkplantasmontagemclienteFile:null,
                salvandoDados :false,
                fileList: [],
                fileTipyList: [],
            }
        },
        computed: {

            ...mapState([
                'unidadeId',
                'config',                                             
                'usuario', 
            ]),
            ...mapGetters([
                'isInRole'
            ]),           
        },   
        mounted() {            
            if(!this.model.unidadeId || this.model.unidadeId == undefined)
                this.model.unidadeId = this.unidadeId
            
            this.loadDataVenda(this.guia.id);
        
        },             
        methods: {

            enviarAnexos(){            

                if(this.model.checkplantastecnicas== 'true' && this.checkplantastecnicasFile != null){                        
                    this.fileList.push(this.checkplantastecnicasFile);
                    this.fileTipyList.push('plantastecnicas');
                }     

                if(this.model.checkplantasmontagemimpressao== 'true' && this.checkplantasmontagemimpressaoFile != null){                                 
                    this.fileList.push(this.checkplantasmontagemimpressaoFile);
                    this.fileTipyList.push('plantasimpressao');
                }   
                if(this.model.checkplantasmontagemcliente== 'true' && this.checkplantasmontagemclienteFile != null){                                 
                    this.fileList.push(this.checkplantasmontagemclienteFile);
                    this.fileTipyList.push('plantascliente');
                }                       
                

            },

            validarCampos(){


                // Se marcado como consenso, verificar se tem data do consenso
                if(this.model.checkconsenso== 'true' && this.model.dataHoraConsenso == null){
                    return false;
                }

                // Se marcado como sim, verificar se tem anexos
                if(this.model.checkplantastecnicas== 'true' && this.checkplantastecnicasFile == null){
                    return false;
                }

                // Se marcado como sim, verificar se tem anexos
                if(this.model.checkplantasmontagemimpressao== 'true' && this.checkplantasmontagemimpressaoFile == null){
                    return false;
                }        
                
                // Se marcado como sim, verificar se tem anexos
                if(this.model.checkplantasmontagemcliente== 'true' && this.checkplantasmontagemclienteFile == null){
                    return false;
                }                  

                return true;
            },

            loadDataVenda(guiaId){
                this.$http.get('/api/venda/retornaVendaPorGuiaId?guiaId=' + this.guia.id)
                        .then(res => res.data)
                        .then(data => {

                            if(data && data !=""){
                                this.model = data;
                                
                                this.model.checkconsenso =  this.model.checkconsenso ? 'true' : 'false';
                                this.model.checkplantasmontagemcliente =  this.model.checkplantasmontagemcliente ? 'true' : 'false';
                                this.model.checkplantasmontagemimpressao =  this.model.checkplantasmontagemimpressao ? 'true' : 'false';
                                this.model.checkplantastecnicas =  this.model.checkplantastecnicas ? 'true' : 'false';                                
                                this.model.checkarquivosrede =  this.model.checkarquivosrede ? 'true' : 'false';                                
                            }

                        });
            },

            salvarDados(){

                this.formData = new FormData(); 

                if(this.observacao)
                    this.model.observacao = this.observacao;

                 this.$buefy.dialog.confirm({
                    message: this.$t('ATENDIMENTO.MENSAGEMCONFIRMACHECKLIST'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {   
                        
                        if(this.validarCampos()){
                            this.salvandoDados=true;

                            this.enviarAnexos();
                                                        
                            this.formData.append("efetivarVenda.Id", this.model.id);
                            this.formData.append("efetivarVenda.Checkplantasmontagemcliente", this.model.checkplantasmontagemcliente);
                            this.formData.append("efetivarVenda.Checkplantasmontagemimpressao", this.model.checkplantasmontagemimpressao);
                            this.formData.append("efetivarVenda.Checkplantastecnicas", this.model.checkplantastecnicas);
                            this.formData.append("efetivarVenda.Checkconsenso", this.model.checkconsenso);
                            this.formData.append("efetivarVenda.Checkarquivosrede", this.model.checkarquivosrede);

                            if(this.model.dataHoraConsenso !=null)
                                this.formData.append("efetivarVenda.DataHoraConsenso", this.$moment(this.model.dataHoraConsenso).format('YYYY-MM-DDTHH:mm:ss'));                            
                                            
                            for(let i = 0; i < this.fileList.length;i++){
                                
                                this.formData.append('files', this.fileList[i]);                                                                                                              
                            }

                            for(let i = 0; i < this.fileTipyList.length;i++){
                                
                                this.formData.append('efetivarVenda.filesType', this.fileTipyList[i]);                                                                                                              
                            }                            
                                                                                                                        
                            try {                                
                                this.$http.post(`/api/venda/SalvarCheckListVenda`,this.formData)
                                .then((res) => {
                                    this.salvandoDados=false;
                                    
                                }).finally(() => {                                
                                    this.isLoading = false;    
                                    this.$emit('loadData')                            
                                    this.$emit('close');   
                                    this.$router.back();                                         
                                });                            

                                
                            } catch (e) {
                                this.salvandoDados=false;                        
                                console.error(e);
                            }

                                

                        }else{
                            this.$buefy.dialog.alert({
                                title: this.$t('SISTEMA.ATENCAO'),
                                message: this.$t('SISTEMA.CAMPOSOBRIGATORIOS'),
                                type: 'is-warning',
                                hasIcon: true                                          
                            });                    
                            this.salvandoDados = false;
                            return false;                                            
                        }
                        
                        return true;
                    },
                    onCancel: () => {                        
                        this.guia.status = 0;
                        this.negociacao = null;
                        this.salvandoDados = false;    
                    },
                })  
       
       
            },            

        }
    }
</script>