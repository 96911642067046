<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <filtros :tipo="'anexos'" @filtrar="filtrar($event)" @exportar="exportar($event)"  ></filtros>
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>     

            <b-table v-if="lista.length>0" :data="lista"
                    :loading="isLoading"                    
                    striped
                    hoverable                                                            
                    backend-sorting
                    sort-icon-size="is-small">                    

                <template >

                    <b-table-column v-slot="props" field="Guia" :label="$t('SISTEMA.GUIA')" >
                        {{ props.row.guiaId }}                        
                    </b-table-column>

                    <b-table-column v-slot="props" field="Nome" :label="$t('SISTEMA.NOME')" >
                        {{ props.row.fileName }}                        
                    </b-table-column>

                    <b-table-column v-slot="props" field="Cliente" :label="$t('SISTEMA.CLIENTE')" >
                        {{ props.row.clienteNome }}                        
                    </b-table-column>
                    
                    <b-table-column v-slot="props" field="Vendedor" :label="$t('SISTEMA.VENDEDOR')" >
                        {{ props.row.funcionarioComercialNome }}                        
                    </b-table-column>                    

                    <b-table-column v-slot="props" field="editar" :label="$t('SISTEMA.EDITAR')" >         
                        
                        <b-button class="is-primary is-small" icon-left="pencil"                                    
                            @click="abrirGuia( props.row.guiaId, props.row.unidadeId, props.row.status)" title="Abrir Projeto"></b-button>  

                    </b-table-column>                       
                    
            
                    
                    
                </template>
            </b-table>              
          
        </div>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import filtros from '@/components/filtrosRelatorio.vue';

    export default {
        data() {
            return {
                isLoading: false ,
                lista:[]
            }
        },
        components: {
            titlebar,
            filtros
        },
        computed: {
            titleStack() {
                return [
                    'Relatório',
                    'Manutenção',
                    'Anexos Duplicados'
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'unidadeId',
                'config',                                             
                'usuario', 
            ]),             
        },        
        methods: {
            abrirGuia(guiaId,unidadeId,status){

                if(status==0){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId}})
                }else if (status == 1){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,fluxoVenda:true }})
                }else if(status == 5){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,fluxoVenda:true }})
                }
                
                this.$parent.close();

            },
            filtrar(filtros){

                    const params = {                        
                        nome: (filtros.nome !=null) ? filtros.nome : null,                                                
                        status: (filtros.status !=null) ? filtros.status : null,
                        funcionarioLogadoId: this.usuario.funcionarioId,  
                    }

                    if (filtros.unidade?.id != null){                         
                        params.unidadeId = filtros.unidade?.id;
                    }else if (filtros.unidade?.id == null && !this.usuario.administrador){ 
                        params.unidadeId = this.unidadeId;
                    }                       

                this.carregarLista(params);  

            },

            exportar(filtros){

                    const params = [];

                    if (filtros.nome != null) params.push(`nome=${filtros.nome}`);  
                    if (filtros.status != null) params.push(`status=${filtros.status}`);                  

                    if (filtros.unidade?.id != null){ 
                        params.push(`unidadeId=${filtros.unidade?.id}`);   
                    }else if (filtros.unidade?.id == null && !this.usuario.administrador){ 
                        params.push(`unidadeId=${this.unidadeId}`);   
                    }
                                                    
                    params.push(`funcionarioLogadoId=${this.usuario.funcionarioId}`);                     
                    
                    window.open(`/api/relatorio/AnexosDuplicadosXLS?${params.join('&')}`,'_blank');
                
            },                              
            carregarLista(params){                                   
                    this.isLoading = true;
                    this.$http.post('/api/relatorio/AnexosDuplicados', params)
                    .then(res => res.data)
                    .then(data => {                         
                            this.lista = data;                           
                    }).finally(() => {
                        this.isLoading = false;
                    })
                    
            },
 
            
        }
    }
</script>