<template>
    <transition name="fade">
        <div class="column is-2 is-fullheight sidebar-default" v-if="sidebarIsOpened">
            <aside class="menu">
                <p class="menu-label">
                    <button class="delete is-small is-pulled-right" id="close-sidebar" aria-label="close" @click="SET_SIDEBAR(false)" title="Fechar o menu"></button>
                </p>
                <figure class="image is-64x64 has-image-centered is-hidden-mobile">
                    <img v-if="foto==null" class="is-rounded" src="../assets/pessoa.png">
                    <img v-else class="is-rounded" :src="foto">
                </figure>
                <h4 class="title is-6 has-text-centered is-marginless">
                    {{ usuario.apelido ?? usuario.nome }}
                </h4>
                <p class="is-size-7 has-text-centered">
                    {{ usuario.nivel }}
                </p>
                <hr class="dropdown-divider">

                <b-field v-if="usuario.administrador && alterandoUnidade" 
                         label="Unidade" 
                         label-position="on-border">
                    <b-select size="is-small" v-model="unidadeId"
                              expanded>
                        <option v-for="(item, index) in unidades" 
                                v-bind:key="index" 
                                :value="item.id">{{item.nome}}</option>
                    </b-select>
                </b-field>
                <p v-else class="is-size-7 has-text-centered">
                    Unidade: <b>{{unidadeNome}}</b>
                    <a v-if="usuario.administrador" href="#" class="has-text-grey" @click="alterandoUnidade = true" title="Alterar unidade">
                        <b-icon  icon="pencil" size="is-small"></b-icon>
                    </a>                    
                </p>

                <section >
                    <hr class="dropdown-divider">

                    <div class="is-flex">
                        <b-tabs type="is-toggle-rounded" size="is-small" expanded>
                            
                                <form @submit.prevent="listarAtendimentos" >
                                    <b-field label="Código do Cliente" label-position="inside">
                                        <b-input v-model="codigoClienteExterno" type="number" insideSidebar expanded></b-input>
                                    </b-field>
                                </form>
                                                        
                        </b-tabs>
                    </div>
                </section>                

           
                <hr class="dropdown-divider">


                <ul class="menu-list">                 

                    <li v-if="isInRole('menu-atendimento')">
                        <router-link @click.native="HANDLE_RESIZE()" :to="{ name: 'atendimento' }"> {{$t('SISTEMA.ATENDIMENTO')}}</router-link>
                    </li>

                    <li v-if="isInRole('menu-vendas')">                                                    
                        <router-link @click.native="HANDLE_RESIZE()" :to="{ name: 'venda' }"> {{$t('SISTEMA.VENDAS')}}</router-link>                    
                    </li>
                    <li v-if="isInRole('menu-posvenda')" >                        
                        <router-link @click.native="HANDLE_RESIZE()" :to="{ name: 'posvenda' }"> {{$t('SISTEMA.POSVENDA')}}</router-link>                    
                    </li>                                        

                    <li v-if="isInRole('menu-manutencao')">
                        <router-link @click.native="HANDLE_RESIZE()" :to="{ name: 'manutencao' }"> {{$t('SISTEMA.MANUTENCAO')}}</router-link>
                    </li>

                    <li v-if="isInRole('menu-relatorio')">
                        <router-link @click.native="HANDLE_RESIZE()" :to="{ name: 'relatorio' }"> {{$t('SISTEMA.RELATORIO')}}</router-link>
                    </li>                    
                    
                </ul>
                <hr class="dropdown-divider">
                <ul class="menu-list">
                    <li>
                        <a @click="logout">
                            <i class="fas fa-sign-out-alt"></i>
                            {{$t('SISTEMA.SAIR')}}
                        </a>
                    </li>
                </ul>
            </aside>
        </div>
    </transition>    
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex'
    import { mapMutations } from 'vuex'

    export default {
        data() {
            return {
                alterandoUnidade: false,
                foto: null,       
                codigoClienteExterno:null
            }
        },
        computed: {
            ...mapState([
                'config',
                'usuario',
                'unidadeId',
                'unidades'
            ]),
            ...mapState('componentes', [
                'sidebarIsOpened'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
            unidadeId: {
                get() {
                    return this.$store.state.unidadeId;
                },
                set(value) {
                    this.alterandoUnidade = false;
                    this.$store.commit('SET_UNIDADE', value);
                }
            },
            unidadeNome() {
                const unidade = this.unidades?.find(x => x.id == this.unidadeId);
                return unidade?.nome;
            },
        },
        created(){
            this.getImage();
        },
        methods: {
            listarAtendimentos(){
                let url = '';
                if(this.codigoClienteExterno){
                    url = '/api/manutencao/cliente?id=' + this.codigoClienteExterno+"&codigoExterno=true&unidadeId="+this.unidadeId;
                }

                this.$http.get(url)
                    .then(res => {
                        const codigoCliente = res.data.id;

                        if(codigoCliente > 0){
                            this.$router.push({ name: 'guia-list', query: { clienteId: codigoCliente } });  
                        }else{
                            this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('SISTEMA.CLIENTENAOENCONTRADO'),
                                    type: 'is-warning',
                                    queue: false
                                })                            
                        }

                    }).catch((error) => {
                        this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('SISTEMA.CLIENTENAOENCONTRADO'),
                                    type: 'is-warning',
                                    queue: false
                                })                                                    
                    }).finally(() => {                        
                        this.codigoClienteExterno = null;
                    });

            },
            getImage() {
                if (!this.foto){                    
                    this.foto = '/api/manutencao/funcionarioFoto?id=' + this.usuario.funcionarioId +`&_cl=${new Date().getTime()}`;
                }

                return this.foto;
            },            
            ...mapActions([
                'logout'
            ]),
            ...mapMutations('componentes', [
                'HANDLE_RESIZE',
                'SET_SIDEBAR',
            ])
        }
}
</script>