<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCADASTROASSISTENCIA.CADASTROASSISTENCIA')}}</p>
        </header>
       
        <section class="modal-card-body is-paddingless">              
            <div class="panel">                
                <p class="panel-block">
                    <searchIdName  :id.sync="clienteCodigoExternoSelecionado" :codigoExterno="true"
                    :trazDesativado="false" :label="$t('SISTEMA.CLIENTE')" table="Cliente" :camposAdicionais="'&unidadeId='+unidadeId" :leitura="apenasLeitura">
                    </searchIdName>                                         
                    <searchIdName  :id.sync="funcionarioConferenteIdSelecionado" :trazDesativado="false" 
                    :label="$t('GUIAATENDIMENTO.CONFERENTE')" table="FuncionarioConferente">
                    </searchIdName>                                               
                </p>   



                <p class="panel-block">
                    <b-field :label="$t('MODALCADASTROASSISTENCIA.CUSTO')">
                    </b-field>
                </p>
                <div class="panel-block">                                         
                    <b-select expanded v-model="custoId" >
                        <option :value="null"></option>
                        <template v-for="(item, index) in listaCustos">
                            <option v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                        </template>
                    </b-select>                                                                                                                      
                </div>   
                <p class="panel-block">
                    <b-field :label="$t('MODALCADASTROASSISTENCIA.MOTIVO')">
                    </b-field>
                    <b-button  type="button" class="is-info is-pulled-right ml-2" @click="listarMotivos()" icon-left="magnify" title="Selecionar Motivo"></b-button>
                </p>
                <div class="panel-block">                                         
                    <b-input maxlength="300"   type="text" v-model="motivo"></b-input>                                                                                                                        
                </div>                                              

                <p class="panel-block">
                    <b-field :label="$t('MODALCADASTROASSISTENCIA.TITULO')">
                    </b-field>
                    <b-button  type="button" class="is-info is-pulled-right ml-2" @click="listarPecas((DataHoraAssistenciaTecnica==null))" icon-left="cogs" title="Selecionar Peça"></b-button>
                </p>
                <div class="panel-block">
                    <b-input maxlength="150"  type="text" v-model="titulo"></b-input>                                           
                </div>                                

                <div class="panel-block">
                <div  v-if="id>0 " class="columns">
                    <div class="column">
                    <b-field v-if="id>0 " :label="$t('GUIAATENDIMENTO.LETRA')">
                    </b-field>
                        <b-input maxlength="150"  type="text" v-model="letra" :disabled="true"></b-input>                                           
                    </div>   
                    <div class="column">
                        <b-field v-if="id>0 " :label="$t('GUIAATENDIMENTO.ELIST')">
                        </b-field>                        
                        <b-input maxlength="150"  type="text" v-model="eList" :disabled="true"></b-input>                                           
                    </div>              
                    <div class="column">
                        <b-button v-if="id>0 && DataHoraAssistenciaTecnica!=null" icon-left="home-edit" size="is-small" class="is-info ml-2"
                                        @click="atualizarListarAmbientes(true,false)"                                       
                                        title="Selecionar e atualizar ambientes do EList"></b-button>                                                    
                    </div>                         
                </div>                
            </div>                

                <p class="panel-block">
                    <b-field :label="$t('MODALCADASTROASSISTENCIA.DESCRICAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacao" rows="3"></textarea>                                      
                </div>  

                <div class="panel-block">                    
                    <b-field  v-if="id>0 && DataHoraAssistenciaTecnica==null  && DataHoraCancelado==null">
                        <b-switch v-model="concluirAssistencia" @input="atualizarListarAmbientes(true,true)" type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.CONCLUIDO')}}
                        </b-switch>    
                        <b-switch v-if="DataHoraDevolucaoSupervisor==null" v-model="devolverSupervisor" @input="devolucaoSupervisor()"  type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.DEVOLVERSUPERVISOR')}}
                        </b-switch>  
                        <b-switch v-if="DataHoraDevolucaoSupervisor!=null" v-model="resolverPendencia" @input="resolucaoPendencia()"  type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.INFORMACOESPENDENTESRESOLVIDAS')}}
                        </b-switch>                                                                                            
                    </b-field> 
                    
                    <b-field  v-if="id>0 && DataHoraAssistenciaTecnica!=null && DataHoraEnviarFabrica==null && DataHoraCancelado==null">
                        <b-switch v-model="enviarfabrica"   type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.ENVIARFABRICA')}}
                        </b-switch>  
                        <b-switch v-if="isInRole('posvenda-assistencia-voltar')"  @input="voltarAssistencia()"  v-model="voltarassistencia"   type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.VOLTARASSISTENCIA')}}
                        </b-switch>                                                                    
                    </b-field> 
                    <b-field  v-if="id>0 && DataHoraEnviarFabrica!=null && DataHoraFinalizarEfinance==null && DataHoraCancelado==null">
                        <b-switch v-model="finalizarEfinance" type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.FINALIZAREFINANCE')}}
                        </b-switch>   
                        <b-switch v-if="isInRole('posvenda-assistencia-voltar')"  @input="voltarAssistencia()"  v-model="voltarassistencia"   type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.VOLTARASSISTENCIA')}}
                        </b-switch>                                                                       
                    </b-field> 
                    <b-field  v-if="id>0 && DataHoraFinalizarEfinance!=null && DataHoraFinalizarFinanceiro==null && DataHoraCancelado==null">
                        <b-switch v-model="finalizarFinanceiro" type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.FINALIZARFINANCEIRO')}}
                        </b-switch>   
                        <b-switch v-if="isInRole('posvenda-assistencia-voltar')"  @input="voltarAssistencia()"  v-model="voltarassistencia"   type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.VOLTARASSISTENCIA')}}
                        </b-switch>                                                                       
                    </b-field>                     
                    <b-field  v-if="id>0 && DataHoraFinalizarFinanceiro!=null && DataHoraConcluido==null && DataHoraCancelado==null">
                        <b-switch v-model="fecharAssistencia" type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.FECHARASSISTENCIA')}}
                        </b-switch> 
                        <b-switch v-if="isInRole('posvenda-assistencia-voltar')"  @input="voltarAssistencia()"  v-model="voltarassistencia"   type="is-success">
                            {{$t('MODALCADASTROASSISTENCIA.VOLTARASSISTENCIA')}}
                        </b-switch>                                                                      
                    </b-field>                     
                    <span v-if=" DataHoraConcluido!=null">                                    
                        <b-notification  type="is-success" has-icon icon-size="is-medium" :closable="false" role="alert" >
                            {{$t('MODALCADASTROASSISTENCIA.ASSISTENCIAFINALIZADA').toUpperCase()}}
                        </b-notification>                                          
                    </span>  
                    <span v-if=" DataHoraCancelado!=null">                                    
                        <b-notification  type="is-danger" has-icon icon-size="is-medium" :closable="false" role="alert" >
                            {{$t('MODALCADASTROASSISTENCIA.ASSISTENCIAEXCLUIDA').toUpperCase()}}
                        </b-notification>                                          
                    </span>                                                                                                                     
                </div> 
                <br>
                <br>
                <div class="columns" >                    
                  
                        <div class="column is-4 " style="margin: 5px" >
                            <div v-if="files" class="tags">
                                <span v-for="(file, index) in files"
                                    :key="index" class="tag is-primary" >
                                    {{file.name}}
                                    <button class="delete is-small" type="button" @click="deleteFile(index)">
                                    </button>
                                </span>                                            
                            </div>                              
                            <b-field>
                                <b-upload  
                                accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                multiple expanded drag-drop v-model="files" >
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-medium"> </b-icon>
                                            </p>
                                            <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>
                                            <p>({{$t('ANEXOS.APENASFOTOS')}})</p>
                                        </div>
                                    </section>
                                </b-upload>                                          
                            </b-field>
                            <table class="table is-striped">
                                <tbody>
                                    <tr v-for="(anexo, index) in anexos" :key="index">
                                        <td width="90%">                                        
                                            <p>
                                                <a @click="visualizarAnexo(anexo.id, anexo.fileName)" class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>
                                            </p>
                                        </td>
                                        
                                        <td v-if="anexo.id && isInRole('assistencia-anexoexcluir')  ">                                           
                                            <b-button icon-left="delete"
                                                    type="is-danger"
                                                    size="is-small"
                                                    :title="$t('ANEXOS.REMOVERANEXO')"
                                                    @click="removerAnexo(anexo.id)"></b-button>
                                        </td>
                                    </tr>
                                    <tr v-if="uploadingAnexo">
                                        <td colspan="2">
                                            <b-progress type="is-info" :value="uploadingAnexo" show-value></b-progress>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                            
                                        
                        </div> 
                        
                        <div class="column is-5"  style="margin: 5px">  
                      
                            <div >  
                                <b-field  class="wrap-mobile" :label="$t('SISTEMA.OBSERVACAO')" >
                                    <b-input expanded v-model="observacoes" type="text" ></b-input> 
                                    <b-button type="is-success"  icon-left="plus" @click="incluirObservacao()"> {{$t('SISTEMA.INCLUIR')}} </b-button>
                                </b-field>                                  
                            </div>                            

                            

                            <div v-if="id > 0">                      
                                <table class="table is-fullwidth">
                                    <tr v-for="(item, index) in historicoAlteracoes" :key="index">
                                        <td>
                                            <h4>
                                                {{ item.observacao }}                                         
                                            </h4>
                                        </td>
                                        <td>
                                            <span><b>{{ item.usuarioNome }}</b> ({{ item.usuarioId }})</span>
                                        </td>
                                        <td>
                                            <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                                        </td>
                                    </tr>
                                </table>                                    
                            </div>                               
                        </div>
                                                                                                                                                                                                                                                                                                                                                                           
                </div>                                    
                                         
            </div>
        </section>
        <footer class="modal-card-foot">

            <b-button  type="is-success"
                    :loading="salvandoDados"
                    @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>                
           
            <b-button @click="$emit('close'); $emit('carregarAssistencias');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState,mapGetters } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'        
    import modalListarAmbientes from '@/components/atendimento/modalListarAmbientes.vue' 
    import modalListarPecas from '@/components/posvenda/modalListarPecas.vue' 
    import modalListarMotivosAssistencia from '@/components/posvenda/modalListarMotivosAssistencia.vue' 
import Motivo from '@/views/cadastro/motivo.vue';
    
    
    export default {
        props: {
            id: Number,
            posvendaid: Number,                                                               
            clienteCodigoExterno: Number,
            funcionarioConferenteId: Number,
            guiaId: Number
        },
        data() {
            return {   
                titulo:null,             
                observacao: null,                
                custoId: null,
                salvandoDados :false,
                clienteCodigoExternoSelecionado:null,
                funcionarioConferenteIdSelecionado:null,
                apenasLeitura:false,
                concluirAssistencia:false,
                enviarfabrica: false,
                finalizarEfinance:false,
                finalizarFinanceiro:false,
                fecharAssistencia: false,
                voltarassistencia:false,
                listaCustos:[],
                files:[],
                anexos:[],
                uploadingAnexo: null,
                DataHoraAssistenciaTecnica: null,
                DataHoraEnviarFabrica: null,
                DataHoraFinalizarEfinance: null,
                DataHoraFinalizarFinanceiro: null,
                DataHoraConcluido: null,
                DataHoraCancelado: null,
                DataHoraPrazo: null,
                DataHoraDevolucaoSupervisor:null,
                DataHoraLiberacaoEntrega:null,
                DataHoraAgendamento: null,
                DataHoraAprovacaoFabrica:null,
                custoNome:null,
                codigoLote: 0,
                eList:null,
                motivo:null,
                letra:null,
                devolverSupervisor:false,
                resolverPendencia:false,
                historicoAlteracoes:[],
                observacoes:null,
                faturado: false,
            }
        },
        components: {                     
            searchIdName          
        },        
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
            ...mapGetters([
                'isInRole'
            ]),              
        },        

        mounted() {

            this.loadData();
            this.loadHistoricoAlteracoes();
            this.loadCustos();
        },
        methods: {

            listarMotivos(custoId){


                if(this.custoId ==null ){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.MENSAGEMCUSTOOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }
                               
                    
                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarMotivosAssistencia,
                    props: {
                        custoId: this.custoId,                                     
                    },                   
                    events: {
                        preencherMotivo: this.preencherMotivo                        
                    },                     
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: false
                })
            },            

            listarPecas(apenasEmAbertos){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarPecas,
                    props: {
                        guiaId: this.guiaId,
                        clienteCodigoExterno: this.clienteCodigoExterno,
                        apenasEmAbertos:apenasEmAbertos                                       
                    },                   
                    events: {
                        preencherTitulo: this.preencherTitulo                        
                    },                     
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },
            preencherTitulo(titulo){
                this.titulo = titulo;
            },
            preencherMotivo(motivo){                
                this.motivo = motivo;
            },            
            removerConcluirAssistencia(){
                this.concluirAssistencia=false;
            },
            preencherElist(ambientesSelecionados){                
                this.eList = ambientesSelecionados.map(x => x.elist).join(',');
                this.letra = ambientesSelecionados.map(x => x.opcao).join(',');

                setTimeout(() => {                                        
                    if(this.concluirAssistencia)
                        this.confirmar();
                },500);

            },
           async verificaCodigoCustoAvaria() {

                let codigoCliente = 0;
                const params = [];                    
                params.push(`custoId=${this.custoId}`);                                
                params.push(`codigoCliente=${this.clienteCodigoExterno}`);                                
                params.push(`unidadeId=${this.unidadeId}`);                                
                        
                await this.$http.get(`/api/manutencao/VerificaCodigoCustoAvaria?${params.join('&')}`)
                    .then(res => {
                        codigoCliente = res.data
                    });
                  
                return codigoCliente;
            },              
            async atualizarListarAmbientes(trazerApenasNaoEnviados,apenasEmAbertos){

                const clienteCodigoExterno = await this.verificaCodigoCustoAvaria();

                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarAmbientes,
                    props: {
                        guia: { id: this.guiaId, clienteCodigoExterno: clienteCodigoExterno  },
                        assistencia: trazerApenasNaoEnviados,
                        apenasEmAbertos:apenasEmAbertos                      
                    },                   
                    events: {
                        preencherElist: this.preencherElist,  
                        removerConcluirAssistencia: this.removerConcluirAssistencia                      
                    },                     
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })

            },            
            loadData(){
                setTimeout(() => {

                    if(this.clienteCodigoExterno > 0)                
                        this.clienteCodigoExternoSelecionado = this.clienteCodigoExterno; this.apenasLeitura=true;



                    if(this.id){
                        this.$http.get('/api/assistencia/retornarAssistencia?id=' + this.id)
                        .then(res => res.data)
                        .then(data => {
                            this.titulo = data.titulo;
                            this.eList = data.eList;
                            this.motivo = data.motivo;
                            this.faturado = data.faturado;
                            this.letra = data.letra;                            
                            this.observacao = data.observacao;
                            this.custoId = data.custoId;        
                            this.custoNome = data.custoNome;         
                            this.funcionarioConferenteIdSelecionado = data.funcionarioConferenteId;               
                            this.DataHoraAssistenciaTecnica =  (data.dataHoraAssistenciaTecnica) ?  this.$moment(data.dataHoraAssistenciaTecnica).toDate() : null ;
                            this.DataHoraEnviarFabrica =  (data.dataHoraEnviarFabrica) ?  this.$moment(data.dataHoraEnviarFabrica).toDate() : null ;
                            this.DataHoraFinalizarEfinance =  (data.dataHoraFinalizarEfinance) ?  this.$moment(data.dataHoraFinalizarEfinance).toDate() : null ;
                            this.DataHoraFinalizarFinanceiro =  (data.dataHoraFinalizarFinanceiro) ?  this.$moment(data.dataHoraFinalizarFinanceiro).toDate() : null ;                        
                            this.DataHoraConcluido =  (data.dataHoraConcluido) ?  this.$moment(data.dataHoraConcluido).toDate() : null ;                        
                            this.DataHoraCancelado =  (data.dataHoraCancelado) ?  this.$moment(data.dataHoraCancelado).toDate() : null ; 
                            this.DataHoraPrazo =  (data.dataHoraPrazo) ?  this.$moment(data.dataHoraPrazo).toDate() : null ;                         
                            this.DataHoraDevolucaoSupervisor =  (data.dataHoraDevolucaoSupervisor) ?  this.$moment(data.dataHoraDevolucaoSupervisor).toDate() : null ;                         
                            this.DataHoraLiberacaoEntrega =  (data.dataHoraLiberacaoEntrega) ?  this.$moment(data.dataHoraLiberacaoEntrega).toDate() : null ;                         
                            this.DataHoraAgendamento =  (data.dataHoraAgendamento) ?  this.$moment(data.dataHoraAgendamento).toDate() : null ;   
                            this.DataHoraAprovacaoFabrica =  (data.dataHoraAprovacaoFabrica) ?  this.$moment(data.dataHoraAprovacaoFabrica).toDate() : null ;   
                            this.codigoLote = data.codigoLote;                               
                        });
                        this.loadAnexos();                    
                    }else{

                        if(this.funcionarioConferenteIdSelecionado == null)
                            this.funcionarioConferenteIdSelecionado = this.funcionarioConferenteId
                        
                    }
                    
                },500);
            },
            loadHistoricoAlteracoes() {
                this.$http.get('/api/assistencia/AssistenciaObservacoes?id=' + this.id)
                    .then(res => {
                        this.historicoAlteracoes = res.data
                    })
            },  

            async incluirObservacao(){

                this.salvandoDados=true;
                

                if (this.observacoes== null  || this.observacoes == '' || this.observacoes == undefined) {
                    this.salvandoDados = false;
                    return;
                }      
                
                const assistenciaObservacao = {
                    assistenciaId: this.id,
                    observacao : this.observacoes
                }                
                this.isLoading = true;  
                
                try {
                    this.$http.post('/api/assistencia/assistenciaObservacoes', assistenciaObservacao)
                        .then(res => {
                            this.salvandoDados=false;
                            return true;

                        }).finally(() => {                                
                            this.salvandoDados=false;    
                            this.observacoes = null;

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('GUIAATENDIMENTO.MENSAGEMOBSERVACAOSALVA'),
                                type: 'is-success'
                            }); 
                            
                            this.loadHistoricoAlteracoes();
                                                                                                             
                        }); 
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                    
            },   

            voltarAssistencia(){

                this.$buefy.dialog.confirm({
                    message: this.$t('MODALCADASTROASSISTENCIA.MENSAGEMVOLTARASSISTENCIA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        

                        this.salvandoDados=true;    
                        const params = [];                    
                        params.push(`id=${this.id}`);                         
                                                                                                        
                        try {
                            this.$http.get(`/api/assistencia/VoltarAssistencia?${params.join('&')}`)
                                    .then(res => res.data)
                                    .then(data => {
                                        return true;
                                    }).finally(() => {    
                                        this.salvandoDados=false; 
                                        this.$emit('carregarAssistencias')                            
                                        this.$emit('close');  
                                    });                              
                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }                        
                        
                        
                    },
                    onCancel: () => {                        
                        this.voltarassistencia = false;
                    },
                });                   


            },                  
                             
            devolucaoSupervisor(){



                this.$buefy.dialog.prompt({
                    message: this.$t('MODALCADASTROASSISTENCIA.MENSAGEMDEVOLVERSUPERVISOR'),                 
                    inputAttrs: {
                        type: 'text',                        
                        placeholder: 'Descrever Motivo',
                        value:null
                    }, 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (r) => {
                        
                        this.salvandoDados=true;    
                        const params = [];                    
                        params.push(`id=${this.id}`); 
                        params.push(`observacoes=${r}`); 
                                                                                                        
                        try {
                            this.$http.get(`/api/assistencia/DevolucaoSupervisor?${params.join('&')}`)
                                    .then(res => res.data)
                                    .then(data => {
                                        return true;
                                    }).finally(() => {    
                                        this.salvandoDados=false; 
                                        this.loadData();
                                        this.loadHistoricoAlteracoes();
                                    });                              
                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }

                    },
                    onCancel:() =>{
                        this.devolverSupervisor=false;
                    }
                });    


            },  
            
            resolucaoPendencia(){

                this.$buefy.dialog.confirm({
                    message: this.$t('MODALCADASTROASSISTENCIA.MENSAGEMINFORMACOESPENDENTESRESOLVIDAS'),                 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (r) => {
                        
                        this.salvandoDados=true;    
                        const params = [];                    
                        params.push(`id=${this.id}`); 
                        params.push(`observacoes=${r}`); 
                                                                                                        
                        try {
                            this.$http.get(`/api/assistencia/ResolverPendencia?${params.join('&')}`)
                                    .then(res => res.data)
                                    .then(data => {
                                        return true;
                                    }).finally(() => {    
                                        this.salvandoDados=false; 
                                        this.loadData();
                                        this.loadHistoricoAlteracoes();
                                    });                              
                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }

                    },
                    onCancel:() =>{
                        this.resolverPendencia=false;
                    }
                });    


            },             
            loadCustos(){
                this.$http.get('/api/manutencao/Custos?unidadeId='+this.unidadeId)
                .then(res => {
                    if (res.data != null) {
                        this.listaCustos = res.data.lista
                    }                    
                });
                
            },            
            visualizarAnexo(anexoId, fileName) {                
                window.open('/api/assistencia/anexoPdf?id=' + this.id + '&anexoId=' + anexoId + '&fileName=' + fileName);
            },
            loadAnexos() {
                this.uploadingAnexo = null
                this.anexos = null
                this.$http.get('/api/assistencia/anexos?id=' + this.id)
                    .then(res => {
                        if(res.data){
                            this.anexos = res.data  
                        }

                    })
            },  
            removerAnexo(id) {
                const anexo = this.anexos.filter(x => x.id == id)[0]
                this.$buefy.dialog.confirm({
                    title: this.$t('ANEXOS.EXCLUIRANEXO'),
                    message: this.$t('ANEXOS.CONFIRMAEXCLUSAO') + anexo.fileName.replace(/^.*[\\/]/, '') + '</b> ?',
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),                    
                    cancelText: this.$t('SISTEMA.CANCELAR'),                      
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        const removerAnexo = {
                            tipo: "guia",
                            anexoId: id
                        }

                        this.isLoading = true;    
                        this.$http.post('/api/assistencia/excluirAnexoGuia', removerAnexo)
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadAnexos()
                                }
                            }).finally(() => {                                
                                this.isLoading = false;    
                            });
                            
                    }
                })
            },                            
            incluirAnexo(fileList,id) {
                const formData = new FormData()
                formData.append('id', id);
                formData.append('type', 'guia');

                Array
                    .from(Array(fileList.length).keys())
                    .map(x => {                        
                        formData.append('anexo', fileList[x], fileList[x].name);
                    });

                this.uploadingAnexo = 0;
                this.isLoading = true;                    
                this.$http.post('/api/assistencia/AnexoAssistencia', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    })
                    this.loadAnexos();
                    this.isLoading = false;                    
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
            },            
            deleteFile(index){                
                this.files.splice(index, 1)            
            },

            async confirmar() {

                if(this.clienteCodigoExternoSelecionado>0 && this.custoId !=null && this.titulo !=null  
                && this.observacao !=null && this.funcionarioConferenteIdSelecionado>0 ){
                    this.salvandoDados=true;
                    const params = [];         
                    
                    if (this.id) params.push(`id=${this.id}`);                                                            
                    params.push(`posvendaid=${this.posvendaid}`);                                                            
                    params.push(`custoId=${this.custoId}`);                                        
                    params.push(`titulo=${this.titulo}`);     
                    params.push(`clienteId=${this.clienteCodigoExternoSelecionado}`);     
                    params.push(`funcionarioConferenteId=${this.funcionarioConferenteIdSelecionado}`); 
                    params.push(`observacao=${this.observacao}`);
                    params.push(`faturado=${this.faturado}`);
                    

                    // Momento que seta DataHoraAssistenciaTecnica como concluido
                    if(this.DataHoraAssistenciaTecnica == null && this.concluirAssistencia)
                        this.DataHoraAssistenciaTecnica = new Date();   

                    // Momento que seta DataHoraEnviarFabrica como concluido
                    if(this.DataHoraEnviarFabrica == null && this.enviarfabrica)
                        this.DataHoraEnviarFabrica = new Date();   
                    
                    // Momento que seta DataHoraFinalizarEfinance como concluido
                    if(this.DataHoraFinalizarEfinance == null && this.finalizarEfinance)
                        this.DataHoraFinalizarEfinance = new Date();      
                    
                    // Momento que seta DataHoraFinalizarFinanceiro como concluido
                    if(this.DataHoraFinalizarFinanceiro == null && this.finalizarFinanceiro)
                        this.DataHoraFinalizarFinanceiro = new Date();                          

                    // Momento que seta DataHoraConcluido como concluido
                    if(this.DataHoraConcluido == null && this.fecharAssistencia)
                        this.DataHoraConcluido = new Date();                       
                    
                    if(this.eList)  params.push(`eList=${this.eList}`);
                    if(this.motivo)  params.push(`motivo=${this.motivo}`);
                    if(this.letra)  params.push(`Letra=${this.letra}`);
                    if(this.DataHoraAssistenciaTecnica) params.push(`DataHoraAssistenciaTecnica=${this.$moment(this.DataHoraAssistenciaTecnica).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.DataHoraEnviarFabrica) params.push(`DataHoraEnviarFabrica=${this.$moment(this.DataHoraEnviarFabrica).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.DataHoraFinalizarEfinance) params.push(`DataHoraFinalizarEfinance=${this.$moment(this.DataHoraFinalizarEfinance).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.DataHoraFinalizarFinanceiro) params.push(`DataHoraFinalizarFinanceiro=${this.$moment(this.DataHoraFinalizarFinanceiro).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.DataHoraConcluido) params.push(`DataHoraConcluido=${this.$moment(this.DataHoraConcluido).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.DataHoraPrazo) params.push(`DataHoraPrazo=${this.$moment(this.DataHoraPrazo).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.DataHoraDevolucaoSupervisor) params.push(`DataHoraDevolucaoSupervisor=${this.$moment(this.DataHoraDevolucaoSupervisor).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.DataHoraLiberacaoEntrega) params.push(`DataHoraLiberacaoEntrega=${this.$moment(this.DataHoraLiberacaoEntrega).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.DataHoraAgendamento) params.push(`DataHoraAgendamento=${this.$moment(this.DataHoraAgendamento).format('YYYY-MM-DDTHH:mm:ss')}`);   
                    if(this.DataHoraAprovacaoFabrica) params.push(`DataHoraAprovacaoFabrica=${this.$moment(this.DataHoraAprovacaoFabrica).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    if(this.codigoLote)  params.push(`codigoLote=${this.codigoLote}`);
                                                                                                            
                    try {                            
                        this.$http.get(`/api/assistencia/incluirAsssitencia?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            this.salvandoDados=false;
                            this.incluirAnexo(this.files,data); 
                        }).finally(() => {                                
                            this.isLoading = false;    
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t("SISTEMA.SALVOSUCESSO"),
                                type: 'is-success'
                            });                                                       
                            this.$emit('carregarAssistencias')                            
                            this.$emit('close');                                                                
                        });                             
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
                }

            },
            

        }
    }
</script>