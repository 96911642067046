<template>
    <div class="modal-card" style="height:400px">
        <div id="otimizar_imagem" style="display: none;"></div>
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{titleName}}</p>
        </header>
        <section class="modal-card-body is-paddingless" style="overflow-x: hidden;">
            <div class="columns">
                 <div class="column"> 
                    <b-field>
                        <b-upload  
                                    accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                    v-model="fileType"
                                    @input="incluirAnexo"
                                    multiple
                                    drag-drop>
                            <section class="section">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon icon="upload"
                                                size="is-medium">
                                        </b-icon>
                                    </p>
                                    <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>                                    
                                </div>
                            </section>
                        </b-upload>
                    </b-field>
                </div>
                <div class="column"> 
                    <table class="table is-striped">
                        <tbody>
                            <tr v-for="(anexo, index) in anexos" :key="index">
                                <td width="90%">
                                    <p v-if="anexo.name">
                                        <span>{{ anexo.name }}</span><br>
                                        <span class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></span>
                                    </p>
                                    <p v-else>
                                        <span v-if="anexo.anexo">{{ anexo.anexo.replace(/^.*[\\\/]/, '') }}</span>
                                        <span v-else>{{$t('ANEXOS.ANEXO')}} {{ index + 1 }}</span><br>
                                        <a @click="visualizarAnexo(anexo.id, anexo.fileName)" class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>
                                    </p>
                                </td>
                                <td v-if="anexo.id">
                                    <b-tooltip  size="is-small" v-if="anexo.fileName.includes('.pdf') && showButtonExibirLaudo" position="is-left" label="Exibir no laudo">
                                        <b-switch  size="is-small" v-model="anexo.exibirNoLaudo" @input="exibeNoLaudo(anexo)"></b-switch>
                                    </b-tooltip>
                                    <b-button 
                                                icon-left="delete"
                                                type="is-danger"
                                                size="is-small"
                                                :title="$t('ANEXOS.REMOVERANEXO')"
                                                @click="removerAnexo(anexo.id)"></b-button>
                                </td>
                            </tr>
                            <tr v-if="uploadingAnexo">
                                <td colspan="2">
                                    <b-progress type="is-info" :value="uploadingAnexo" show-value></b-progress>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>       
        </section>
        <footer class="modal-card-foot">
            <b-button @click="$emit('close')">Fechar</b-button>
        </footer>
    </div>
</template>

<script>
    import { mapGetters, mapState} from 'vuex'
    import utilFile from '../assets/js/views/atendimento/utilFile.js';    

    export default {
        props: {
            id: Number,
            guiaId: Number,
            titleName: String,
            controller: Object,
            tipoParam: Object,
            aliasName: String,             
        },
        data() {
            return {
                anexos: null,
                uploadingAnexo: null,
                fileType: null,
                exibirNoLaudo: false,
                showButtonExibirLaudo: true
            }
        },
        computed: {
            ...mapState([
                'config'
            ]), 
            ...mapGetters([
                'isInRole'
            ]),
        },
        created(){
            this.loadAnexos();
        },
        methods: {
            exibeNoLaudo(item){
                 const anexo = {
                    id: item.id,
                    exibirNoLaudo: item.exibirNoLaudo
                };

                this.$http.post(`/api/${this.controller.mainController}/anexos`, anexo);
            },
            loadAnexos() {
                this.uploadingAnexo = null
                this.anexos = null
                this.$http.get(`/api/${this.controller.mainController}/anexos?${this.tipoParam.firstType}=${this.id}&aliasName=${this.aliasName}`)
                    .then(res => {
                        this.anexos = res.data
                        this.showButtonExibirLaudo = this.tipoParam != null && this.tipoParam?.firstType === 'agendaId';
                    })
            },
            visualizarAnexo(anexoId, fileName) {                
                window.open(`/api/${this.controller.mainController}/anexoPdf?id=` + this.id + '&anexoId=' + anexoId + '&fileName=' + fileName);
            },
            removerAnexo(id) {
                const anexo = this.anexos.filter(x => x.id == id)[0]
                
                this.$buefy.dialog.confirm({
                    title: this.$t('ANEXOS.EXCLUIRANEXO'),
                    message: this.$t('ANEXOS.CONFIRMAEXCLUSAO') + anexo.anexo.replace(/^.*[\\/]/, '') + '</b> ?',
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),                    
                    cancelText: this.$t('SISTEMA.CANCELAR'),                      
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        
                        const removerAnexo =  {
                            tipo: this.tipoParam.firstType,
                            anexoId: id
                        }

                        this.$http.post(`/api/${this.controller.mainController}/${this.controller.methodRemoverController}?anexoId=${id}`, removerAnexo)
                        .then((res) => {
                            if (res.body.erro) {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.body.erro,
                                    type: 'is-danger'
                                })
                            } else {
                                this.loadAnexos()
                            }
                        })
                    }
                })
            },
            async incluirAnexo(fileList) {
                const formData = new FormData()
                const setType = this.tipoParam.firstType == 'agendaId' 
                                    ? this.tipoParam.firstType   
                                    : this.tipoParam.secondType;

                formData.append(this.tipoParam.firstType, this.id);
                formData.append('type', setType);
                if(this.guiaId > 0){
                    formData.append(this.tipoParam.secondType, this.guiaId);
                }

                for (const file of fileList) {
                    if (utilFile.isFileImage(file)) {
                        const newBlob = await utilFile.createFileReader(file);
                        const newFile = await utilFile.resizeImage(newBlob, file.type, file.name, parseFloat(this.config.percentualOtimizacaoImagem));
                        formData.append('anexo', newFile, newFile.name);
                    } else {
                        formData.append('anexo', file, file.name);
                    }
                }

                this.uploadingAnexo = 0
                this.$http.post(`/api/${this.controller.mainController}/${this.controller.methodAnexoController}`, formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    })
                    this.loadAnexos()
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                })
            },
        }
    }
</script>