<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALISTARPOSICS.LISTARPOSICSASSISTENCIA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">


         
                <div class="panel-block">                     
                    <div class="column is-12"> 

                        <b-button  type="button" class="is-info is-pulled-right ml-1" 
                        @click="confirmarAtualizarPosics()" icon-left="refresh" title="Atualizar Posics da Assistência"></b-button>                                                                        

                    </div>           
                </div>   
                <div class="panel-block">                     
                    <table  v-if="posics !=null && posics.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>                                
                                <th>EList</th>
                                <th>Nota Fiscal</th> 
                                <th>Prazo</th>                                                                                                     
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(p, index) in posics" :key="index">                       
                                <td>{{ p.elist  }}</td>
                                <td>{{ p.notaFiscal  }}</td>
                                <td>{{ $moment(p.dataHoraPrevisao).format("DD/MM/YYYY")  }}</td>                                                                                                                                                              
                            </tr>
                        </tbody>                        
                    </table>  
                </div>                     
                            

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button @click="$emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            assistencia: Object   
        },
        data() {
            return {                                              
                salvandoDados :false,                
                posics:[],                 
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.loadPosics();
        },
        methods: {

            atualizarPosics(){


                this.salvandoDados=true;
                const params = [];                                                                                                                      
                params.push(`assistenciaId=${this.assistencia.id}`);  
                params.push(`unidadeId=${this.unidadeId}`);  
                                                                        
                try {                            
                    this.$http.get(`/api/integracao/AtualizarPrazoAssistenciaEList?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {                                                
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;    
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: "Posics atualizados com sucesso",
                            type: 'is-success',
                            queue: false
                        });                                                                                                 
                        this.loadPosics();  
                        this.$emit('loadData');                                                                                                                          
                    }).catch((error) => {    

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: "Ocorreu um erro na integração com Elist!",
                            type: 'is-danger',
                            queue: false
                        });                                                    
                        throw error;

                    });                               
                    
                } catch (e) {
                    this.salvandoDados=false;                        
                    console.error(e);
                }                    

            },
            confirmarAtualizarPosics(){

                this.$buefy.dialog.confirm({
                        title: 'Atualizar posics',
                        message: 'Deseja importar os posics referentes a assistência?',
                        type: 'is-info',
                        hasIcon: true,
                        cancelText: 'Não',
                        confirmText: 'Sim',
                        onConfirm: () => {

                            this.atualizarPosics();
                        
                        }
                });                
                        


            },
             
            loadPosics() {    
                this.salvandoDados = true;
                
                const params = [];                                                                                                                      
                params.push(`id=${this.assistencia.id}`);                  

                this.$http.get(`/api/assistencia/ListarPosicsAssistencia?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.posics = data;                       
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                  
                    });               
                                                                                         
            },                        

        }
    }
</script>