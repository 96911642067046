<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALPREENCHERTIPOANEXO.AVISO')}}</p>
        </header>        
        <section class="modal-card-body is-paddingless">
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.TIPO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded v-model="tipoAnexoId">
                        <option v-for="(tipo,index) in listaTiposAnexo" :key="index"
                                :value="tipo.id">
                            {{ tipo.nome }}
                        </option>
                    </b-select>                                         
                    
                </div>
                                         
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); $emit('loadData');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        data() {
            return {                
                salvandoDados :false,
                listaTiposAnexo : [],
                file: null,
                tipoAnexoId:null
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            this.loadListaTiposAnexos()
        },
        methods: {
            loadListaTiposAnexos() {

                const params = [
                    `unidadeId=${this.unidadeId}`,                                        
                ].join('&');
            
                this.$http.get(`/api/search/ListarTiposAnexos?${params}`)
                    .then(m => {
                        this.listaTiposAnexo = m.data
                    })
            },
            async confirmar() {


                if(this.tipoAnexoId ==null){
                    
                    this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('SISTEMA.CAMPOSOBRIGATORIOS'),
                    type: 'is-warning',
                    hasIcon: true
                    });                        
                    return true;
                }


                this.$emit('inlcuirAnexo',this.tipoAnexoId)                            
                this.$emit('close'); 


            },
            

        }
    }
</script>