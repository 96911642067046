
<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-two-thirds">
                        <div class="box whitebox">
                            <div class="columns is-vcentered">
                                <div class="column is-7 is-spaced">
                                    <img v-if="config.logoBase64" :src="'data:image/png;base64, ' + config.logoBase64" />                                                                        
                                </div>
                                <div class="column is-spaced">
                                    <div v-if="isServerOut" class="notification is-warning">
                                        Sistema em manutenção.<br/>
                                        Tente novamente mais tarde.
                                    </div>
                                    <div v-if="erros.length > 0" class="notification is-danger">
                                        <ul>
                                            <li v-for="(erro, index) in erros" v-bind:key="index">
                                                {{ erro }}
                                            </li>
                                        </ul>                                        
                                    </div>
                                    <form  @submit.prevent="onSubmit" method="post" role="form">
                                        <div v-if="!lostingPassword && !ReturnToken">
                                            <b-field label="Usuário">
                                                <b-input icon="account" required v-model="UserName"></b-input>
                                            </b-field>
                                            <b-field label="Senha">
                                                <b-input type="password" icon="lock" required v-model="Password"></b-input>
                                            </b-field>
                                            <button type="submit" class="button is-primary is-fullwidth">Entrar</button>
                                            <br />
                                            <p class="has-text-centered is-size-7">
                                                <a @click="lostPassword()">Esqueceu a senha?</a>
                                            </p>                                            
                                        </div>
                                        <div v-else-if="!ReturnToken">

                                            <b-field label="E-mail">
                                                <b-input icon="account" required v-model="Email"></b-input>
                                            </b-field>
                                            <button type="submit" class="button is-primary is-fullwidth">Enviar</button>
                                            <button type="button" @click="cancelLostPassword()" class="button is-fullwidth">Voltar</button>
                                                                                                              
                                        </div>
                                        <div v-else>
                                            <b-field label="Nova Senha">
                                                <b-input type="password" icon="lock" required v-model="Password"></b-input>
                                            </b-field>
                                            <b-field label="Confirmar Senha">
                                                <b-input type="password" icon="lock" required v-model="ConfirmPassword"></b-input>
                                            </b-field>        
                                            <button type="submit" class="button is-primary is-fullwidth">Confirmar</button>                                                                                
                                        </div>

                                    </form>

                                                                      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="has-text-centered is-italic">desenvolvido por <a target="blank" href="https://site.myclient.com.br/">MYCLIENT SISTEMAS</a></p>
            </div>            
        </div>
    </section>
</template>

<style scoped>
.is-spaced {
    padding: 1.5em;
}
</style>

<script>
    import { mapActions, mapState } from 'vuex'
    import jwt from 'jsonwebtoken'

    export default {
        data() {
            return {
                UserName: '',
                Password: '',
                Email: '',
                lostingPassword: false,
                ConfirmPassword: '',
                ReturnToken: null,                
                Token: null,
                GuiaId:null,
                UnidadeId:null
            }
        },
        computed: {
            ...mapState([
                'config',
                'erros',
                'usuario',
                'isServerOut'
            ])
        },
        mounted() { 
            setTimeout(() => {
                if (this.$route.query.email) this.Email = this.$route.query.email;
                if (this.$route.query.rt) this.ReturnToken = this.$route.query.rt;                                
                if (this.$route.query.user) this.UserName = this.$route.query.user;
                if (this.$route.query.pass) this.Password = this.$route.query.pass;

                /*
                if(this.UserName!=null && this.Password!=null 
                && this.UserName!='' && this.Password!='' 
                && this.UserName.length>1 && this.Password.length>1  ){
                    this.Password = atob(this.Password);
                    this.onSubmit();
                }
                */

                if (this.$route.query.token) {                    
                    this.Token = this.$route.query.token;
                    const json = jwt.decode(this.Token);
                    this.UserName = json.Nome;
                    this.GuiaId = json.GuiaId;
                    this.UnidadeId = json.UnidadeId;
                    this.Password = "vazio";
                    this.onSubmit();
                }
                
            

            },500);                     

        },
        
        methods: {
            ...mapActions([
                'login',
                'commit'
            ]),
            lostPassword(){
                this.lostingPassword= true;
            },
            cancelLostPassword(){
                this.lostingPassword= false;
            },            
            onSubmit() {
                // se estiver num portal, sai
                if (this.$route.path.startsWith('/portal/')) {
                    this.$router.push('/')
                }


                if(this.ReturnToken){
                    this.$http.post(`/api/auth/ResetPassword`, 
                    {
                        email : this.Email, 
                        returnToken: this.ReturnToken,
                        Password: this.Password,
                        ConfirmPassword: this.ConfirmPassword
                    }).then(res => res.data)
                      .then(data => {    
                            if(!data.mensagem){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: "Nova senha foi definida com sucesso!",
                                    type: 'is-success',
                                    queue: false
                                });            
                                this.lostingPassword= false;
                                this.Email='';
                                this.UserName='';
                                this.Password='';
                                this.ConfirmPassword='';
                                this.ReturnToken=null;  

                                this.$router.replace('/');
                            }else{
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: data.mensagem,
                                    type: 'is-danger',
                                    queue: false
                                });                                            
                            }
                
                            
                        })
                        .catch((error) => {    

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Ocorreu um erro!",
                                type: 'is-danger',
                                queue: false
                            });                                                    
                            throw error;

                        });                       

                } else if(!this.lostingPassword){


                    this.login({ authMethod: this.authMode, UserName: this.UserName, 
                        Password: this.Password, Token:this.Token, GuiaId:this.GuiaId, UnidadeId: this.UnidadeId })
                        .then(() => {   
                            this.$router.replace('/');    
                            return true;                                     
                        });                    
                    
                }else{

                    this.$http.post(`/api/auth/LostPassword`, {email : this.Email})
                        .then(res => res.data)
                        .then(data => {    
                            if(!data.mensagem){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: "Email de Redefinição de senha foi enviado com sucesso!",
                                    type: 'is-success',
                                    queue: false
                                });            
                                this.lostingPassword= false;
                                this.Email='';
                            }else{
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: data.mensagem,
                                    type: 'is-danger',
                                    queue: false
                                });                                            
                            }
                
                            
                        })
                        .catch((error) => {    

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Ocorreu um erro!",
                                type: 'is-danger',
                                queue: false
                            });                                                    
                            throw error;

                        });                    

                }

            }
        }
    }
</script>