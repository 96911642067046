<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALISTARPOSICS.LISTARPOSICS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">


         
                <div class="panel-block">                     
                    <div class="column is-12"> 

                        <b-button  type="button" class="is-info is-pulled-right ml-1" 
                        @click="atualizarPosics()" icon-left="refresh" title="Atualizar Posics do Atendimento"></b-button>                                                                        

                        <span class="is-pulled-right  ml-1">
                            <b-button v-if="!habilitaSelecionar"  class="is-info"  
                            @click="selecionarPosics()">{{$t('MODALISTARPOSICS.FATURARPARCIAL')}}</b-button>      
                            
                            <b-button v-if="habilitaSelecionar" :title="$t('POSVENDAS.CONFIRMARLIBERACAO')"  class="is-success" 
                              icon-left="check"
                            @click="confirmarPosics()"></b-button>  

                            <b-button v-if="habilitaSelecionar" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            icon-left="close"
                            @click="cancelarSelecionarPosics()"></b-button>                                                                           
                        </span>


                    </div>           
                </div>   
                <div class="panel-block">                     
                    <table  v-if="posics !=null && posics.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th v-if="habilitaSelecionar"></th>                                
                                <th>EList</th>
                                <th>Nota Fiscal</th> 
                                <th>Prazo</th>                                                                                                     
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(p, index) in posics" :key="index">
                                            
                                <td v-if="habilitaSelecionar">
                                    <b-checkbox  @input="changed(p)"
                                    size="is-small" type="is-info"  v-model="p.liberarParcial"></b-checkbox >                                    
                                </td>                                
                                <td>{{ p.elist  }}</td>
                                <td>{{ p.notaFiscal  }}</td>
                                <td>{{ $moment(p.dataHoraPrevisao).format("DD/MM/YYYY")  }}</td>                                                                                                                                                              
                            </tr>
                        </tbody>                        
                    </table>  
                </div>                     
                            

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button @click="$emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            posvenda: Object   
        },
        data() {
            return {                                              
                salvandoDados :false,                
                posics:[], 
                posicsSelecionados:[],
                habilitaSelecionar:false,                                       
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.loadPosics();
        },
        methods: {

            confirmarPosics(){

                if(this.posicsSelecionados.length<=0){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALISTARPOSICS.MENSAGEMPOSICSOBRIGATORIOS'),
                        type: 'is-warning',
                        hasIcon: true                                          
                    });  
                    
                    return false;
                }

                this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('MODALISTARPOSICS.MENSAGEMCONFIRMARPOSICS'),
                type: 'is-warning',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {                                               
                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.posvenda.id}`);                                                                                    
                
                    this.$http.post(`/api/posvenda/FaturamentoParcial?${params.join('&')}`,this.posicsSelecionados)
                    .then((res) => {
                        this.salvandoDados=false;
                    }).finally(() => {             
                        this.atualizarPosics();                   
                        this.salvandoDados = false;                         
                        this.$emit('loadData');                                                          
                        this.$emit('close');                                                          
                    });  
                    }                           
                });

                
            },                 

            changed(posic){

                if(posic.notaFiscal ==null || posic.notaFiscal ==0 || posic.notaFiscal =='0'){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALISTARPOSICS.MENSAGEMAPENASMBIENTESFATURADOS'),
                        type: 'is-warning',
                        hasIcon: true                                          
                    });  
                    setTimeout(() => { posic.liberarParcial = false;},500);                   
                    return;                           
                }

                if(posic.liberarParcial){
                    this.posicsSelecionados.push(posic);
                }else{
                    this.posicsSelecionados = this.posicsSelecionados.filter(x=>x.elist != posic.elist);
                }
                    
            },           

            selecionarPosics(){
                this.habilitaSelecionar = true;
                this.posicsSelecionados = []
            },            
            cancelarSelecionarPosics(){
                this.habilitaSelecionar = false;
                this.posicsSelecionados = [];
                this.posics.forEach((item) => item.liberarParcial =false );
            },            

            atualizarPosics(){


                this.salvandoDados=true;
                const params = [];                                                                                                                      
                params.push(`guiaId=${this.posvenda.guiaId}`);  
                params.push(`unidadeId=${this.unidadeId}`);  
                                                                        
                try {                            
                    this.$http.get(`/api/integracao/AtualizarPrazoAmbienteEList?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {                                                
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;    
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: "Posics atualizados com sucesso",
                            type: 'is-success',
                            queue: false
                        });                                                                                                 
                        this.loadPosics();  
                        this.$emit('loadData');                                                                                                                          
                    }).catch((error) => {    

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: "Ocorreu um erro na integração com Elist!",
                            type: 'is-danger',
                            queue: false
                        });                                                    
                        throw error;

                    });                               
                    
                } catch (e) {
                    this.salvandoDados=false;                        
                    console.error(e);
                }                    

            },
            confirmarAtualizarPosics(){

                this.$buefy.dialog.confirm({
                        title: 'Atualizar posics',
                        message: 'Deseja importar os posics referentes ao atendimento?',
                        type: 'is-info',
                        hasIcon: true,
                        cancelText: 'Não',
                        confirmText: 'Sim',
                        onConfirm: () => {

                            this.atualizarPosics();
                        
                        }
                });                
                        


            },
             
            loadPosics() {    
                this.salvandoDados = true;
                
                const params = [];                                                                                                                      
                params.push(`guiaId=${this.posvenda.guiaId}`);                  

                this.$http.get(`/api/posvenda/ListarPosics?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.posics = data;                       
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                  
                    });               
                                    
                
                 
                    
            },                        

        }
    }
</script>