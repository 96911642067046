<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">            
            <p  class="modal-card-title">{{$t('MODALALTERACAOPROJETO.ALTERACAOPROJETO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                                
                <p >{{$t('MODALALTERACAOPROJETO.AVISO')}}</p>
            </b-notification>            
            <div class="panel">

                <p class="panel-block">
                    <b-field :label="$t('GUIAATENDIMENTO.TEMPERATURA')">
                    </b-field>
                </p>        
                <div class="panel-block" style="width: 20%;">
                    <b-select :placeholder="$t('GUIAATENDIMENTO.TEMPERATURA')" expanded v-model="temperatura">
                        <option value="1">FRIO</option>
                        <option value="2">MORNO</option>
                        <option value="3">QUENTE</option>
                    </b-select>           
                </div>          
                <p class="panel-block">
                    <b-field :label="$t('GUIAATENDIMENTO.PRAZOCONCLUSAOVENDEDOR')">
                    </b-field>
                </p>     
                <div class="panel-block " style="width: 20%;">
                    <b-input  type="number" max="100"  min="0" v-model="prazoVendedorEmDias"></b-input>
                </div>                                                                                   
                                                        
                
                <p class="panel-block">
                    <b-field :label="$t('GUIAATENDIMENTO.AMBIENTES')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <table  v-if="ambientes !=null && ambientes.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Opção</th>
                                <th>Ambiente</th> 
                                <th>Tipo</th>
                                <th>Situação</th>
                                <th>Valor</th>         
                                <th>Alterações</th>                                             
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(ambiente, index) in ambientes" :key="index">
                                
                                <td>
                                        <b-checkbox  @input="changed(ambiente)"
                                        size="is-small" type="is-info"  v-model="ambiente.selecionado"></b-checkbox >                                    
                                </td>                                
                                <td>{{ ambiente.opcao  }}</td>
                                <td>{{ ambiente.descricao }}</td>                                                    
                                <td>{{ ambiente.tipoDescricao }}</td>                            
                                <td>{{ ambiente.tipoSituacao }}</td>  
                                <td>R$ {{ ambiente.valor.toString().replace('.',',') }}</td>                                                                                                                                                             
                                <td>
                                    <b-input  expanded v-model="ambiente.alteracao"></b-input>                                                
                                </td>  
                            </tr>
                        </tbody>                        
                    </table>  
                </div>

                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';    
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number,             
            alteracaoProjeto: Boolean                                                                                       
        },
        data() {
            return {                              
                observacoes: null,
                salvandoDados :false,
                ambientes:[],
                ambientesSelecionados:[], 
                temperatura:null,    
                prazoVendedorEmDias: null                         
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            this.loadAmbientes();
        },
        methods: {

            
            changed(ambiente){

                if(ambiente.selecionado){
                    this.ambientesSelecionados.push(ambiente);
                }else{
                    this.ambientesSelecionados = this.ambientesSelecionados.filter(x=>x.id != ambiente.id);
                }
                    
            },      
            loadAmbientes() {              
                this.fileList = [];                  
                this.anexos = null
                this.$http.get('/api/atendimento/ambientes?id=' + this.id)
                    .then(res => res.data)
                    .then(data => {
                        this.ambientes = data;

                    });

                    
            },              
            async confirmar() {
                


                
                if(this.temperatura == null){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALALTERACAOPROJETO.CAMPOPRIORIDADEOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    }); 
                    return false;
                }       
                
                if(this.prazoVendedorEmDias ==0 || this.prazoVendedorEmDias == null ){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALALTERACAOPROJETO.CAMPOPRAZOOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    }); 
                    return false;
                }     

                if(this.ambientesSelecionados.length<=0){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALALTERACAOPROJETO.MENSAGEMSELECIONE'),
                        type: 'is-warning',
                        hasIcon: true
                    }); 
                    return false;
                }                    
                
                if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }                


                this.salvandoDados=true;
                const params = [];                    
                params.push(`id=${this.id}`);                 
                params.push(`temperatura=${this.temperatura}`); 
                params.push(`prazoVendedorEmDias=${this.prazoVendedorEmDias}`); 
                params.push(`observacoes=${this.observacoes}`);
                params.push(`alteracaoProjeto=${this.alteracaoProjeto}`); 
                
                                                                
                try {
                    this.$http.post(`/api/atendimento/alteracaoProjetoGuia?${params.join('&')}`,this.ambientesSelecionados)
                            .then(res => res.data)
                            .then(data => {
                                return true;
                            }).finally(() => {      
                                this.salvandoDados=false;                                                                                
                                this.$emit('close'); 
                                this.$router.back()                                                                   
                            });                              
                    
                } catch (e) {
                    this.salvandoDados=false;                        
                    console.error(e);
                }                

                return true;
                                     

            }
            

        }
    }
</script>