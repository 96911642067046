<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <filtros :tipo="'inatividade'" @filtrar="filtrar($event)" @exportar="exportar($event)"  ></filtros>
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>     

            <b-table v-if="lista.length>0" :data="lista"
                    :loading="isLoading"                    
                    striped
                    hoverable                                                            
                    backend-sorting
                    sort-icon-size="is-small">                    

                <template >
                    <b-table-column v-slot="props" field="Código" :label="$t('SISTEMA.CODIGO')" >
                        {{ props.row.codigo }}   
                        <b-icon v-if="props.row.desativado"
                                        icon="close-circle"
                                        size="is-small"                                    
                                        :title="$t('FUNCIONARIOLISTA.DESATIVADO')"
                                        type="is-danger"></b-icon>                                                             
                    </b-table-column>

                    <b-table-column v-slot="props" field="Nome" :label="$t('SISTEMA.NOME')" >
                        {{ props.row.nome }}
                    </b-table-column>

      
                    <b-table-column v-slot="props" field="Unidade" :label="$t('SISTEMA.UNIDADE')" >
                        {{ props.row.unidadeNome }}
                    </b-table-column>         
                    
                    <b-table-column v-slot="props" field="Nivel" :label="$t('FUNCIONARIOLISTA.NIVELACESSO')" >
                        {{ props.row.nivel }}
                    </b-table-column>                            
                    
                    <b-table-column v-slot="props" field="Dias" :label="$t('FUNCIONARIOLISTA.DIASINATIVO')" >
                        <span v-if="props.row.dias==99">
                            Sem registros
                        </span>
                        <span v-else>
                            Inativo a {{ props.row.dias }} dia(s)
                        </span>                        
                        
                    </b-table-column>                             


                    
                </template>
            </b-table>              
          
        </div>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import filtros from '@/components/filtrosRelatorio.vue';

    export default {
        data() {
            return {
                isLoading: false ,
                lista:[]
            }
        },
        components: {
            titlebar,
            filtros
        },
        computed: {
            titleStack() {
                return [
                    'Relatório',
                    'Manutenção',
                    'Inatividade'
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'unidadeId',
                'config',                                             
                'usuario', 
            ]),             
        },        
        methods: {

            filtrar(filtros){

                    const params = {                        
                        nome: (filtros.nome !=null) ? filtros.nome : null,                                                
                        status: (filtros.status !=null) ? filtros.status : null,
                        funcionarioLogadoId: this.usuario.funcionarioId,  
                        funcionarioId: (filtros.funcionario?.id != null) ? filtros.funcionario?.id : null ,      
                    }

                    if (filtros.unidade?.id != null){                         
                        params.unidadeId = filtros.unidade?.id;
                    }else if (filtros.unidade?.id == null && !this.usuario.administrador){ 
                        params.unidadeId = this.unidadeId;
                    }                       

                this.carregarLista(params);  

            },

            exportar(filtros){

                    const params = [];

                    if (filtros.nome != null) params.push(`nome=${filtros.nome}`);  
                    if (filtros.status != null) params.push(`status=${filtros.status}`);                  

                    if (filtros.unidade?.id != null){ 
                        params.push(`unidadeId=${filtros.unidade?.id}`);   
                    }else if (filtros.unidade?.id == null && !this.usuario.administrador){ 
                        params.push(`unidadeId=${this.unidadeId}`);   
                    }
                                                    
                    params.push(`funcionarioLogadoId=${this.usuario.funcionarioId}`);                     
                    
                    window.open(`/api/relatorio/InatividadeXLS?${params.join('&')}`,'_blank');
                
            },                              
            carregarLista(params){                                   
                    this.isLoading = true;
                    this.$http.post('/api/relatorio/Inatividade', params)
                    .then(res => res.data)
                    .then(data => {                         
                            this.lista = data;                           
                    }).finally(() => {
                        this.isLoading = false;
                    })
                    
            },
 
            
        }
    }
</script>