<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">            
            <p class="modal-card-title">Vistoria do ambiente: <b>{{ambiente.descricao}}</b></p>            
        </header>
        <section class="modal-card-body is-paddingless" style="overflow-x: hidden;">
     
  
            <div class="panel">           
                
                <p  class="panel-block">
                    <b-field label="Status da Vistoria?">
                    </b-field>
                </p>                
                
                <div   class="panel-block">                    
                    <b-radio v-model="status"
                        name="aprovacao"                                    
                        native-value="false">
                        Pendente
                    </b-radio>                    
                    <b-radio v-model="status"
                        name="aprovacao"                                    
                        native-value="true">
                        Concluído
                    </b-radio>                    
                </div>           
                
                <br>
                <div class="" >                    
                  
                    <div class="column is-1">  </div>

                  <div class="column is-11">  

                    <div > 
   
                        <b-field  class="wrap-mobile" :label="$t('GUIAATENDIMENTO.PENDENCIAS')" >
                            <b-input expanded v-model="pendencias" type="text" ></b-input> 
                            <b-button type="is-success"  icon-left="plus" @click="incluirPendencia()"> {{$t('SISTEMA.INCLUIR')}} </b-button>
                        </b-field>                                              

                    </div>
                      <div  class="b-table">
                        <div class="table-wrapper has-mobile-cards">                      
                          <table class="table is-fullwidth">
                              <tr v-for="(item, index) in historicoPendencias" :key="index">
                                <td>
                                      <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                                  </td>
                                <td>
                                      <h4>
                                          {{ item.observacao }}                                         
                                      </h4>
                                  </td>
                                  <td>
                                      <span><b>{{ item.usuarioNome }}</b> ({{ item.usuarioId }})</span>
                                  </td>

                                  <td>                                    
                                        <b-tag v-for="(anexo, index) in item.anexos" :key="index"  size="is-small"> 

                                            <a @click="visualizarAnexo(anexo.id, anexo.fileName)" 
                                            class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>

                                        </b-tag>                                         
                                    
                                  </td>    
                                  <td>
                                    <b-switch v-if="item.concluido" size="is-small" v-model="item.concluido" @input="ConfirmarPendenciaNaoResolvida(item)"> Resolvido? </b-switch>                                                                       
                                    <b-switch v-else size="is-small" v-model="item.concluido" @input="ConfirmarPendenciaResolvida(item)"> Resolvido? </b-switch>   
                                    <span  class="is-size-7" v-if="item.dataHoraConcluido">                                                                                                            
                                        <br>
                                        <span>{{ $moment(item.dataHoraConcluido).format("DD/MM/YYYY") }}</span>
                                        <br>
                                        <b>{{ item.usuarioNomeConcluido }}</b> 
                                                                                            
                                    </span> 
                                    
                                 </td>
                                 <td>
                                    <b-button v-if="isInRole('ambientes-vistoria-excluir')" :title="$t('SISTEMA.REMOVER')" class="ml-1 is-small is-danger" @click="excluirPendencia(item.id)" icon-left="delete"></b-button> 
                                </td>
                              </tr>
                          </table>                                    
                        </div>  
                        </div>                             
                  </div>
              
                                                                                                                                                                                                                                                                                                                                      
                 </div>                     

                 <hr class="m-0">
                 <br>
                <div class="" >   
                    
                    <div class="column is-1">  </div>
                  
                  <div class="column is-11">  

                      
                         <div >  
                            <b-field  class="wrap-mobile" :label="$t('SISTEMA.OBSERVACAO')" >
                                <b-input expanded v-model="observacoes" type="text" ></b-input> 
                                <b-button type="is-success"  icon-left="plus" @click="incluirObservacao()"> {{$t('SISTEMA.INCLUIR')}} </b-button>
                            </b-field>                                  
                          </div>
                                                                                                                                                                
                      
                      <div  class="b-table">
                        <div class="table-wrapper has-mobile-cards">                     
                          <table class="table is-fullwidth">
                              <tr v-for="(item, index) in historicoAlteracoes" :key="index">
                                <td>
                                      <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                                  </td>                                  
                                <td>
                                      <h4>
                                          {{ item.observacao }}                                         
                                      </h4>
                                  </td>
                                  <td>
                                      <span><b>{{ item.usuarioNome }}</b> ({{ item.usuarioId }})</span>
                                  </td>
                              </tr>
                          </table>                                    
                            </div> 
                        </div>                               
                  </div>
                                                                                                                                                                                                                                                                                                                                                                     
                 </div>            

                       <br><br><br><br><br>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.SALVAR')}}</b-button>
            <b-button @click="$emit('close');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>



<script>
    import moment from 'moment';
    import { mapState,mapGetters } from 'vuex'  
    import modalIncluirPendenciaVistoriaPosVenda from '@/components/posvenda/modalIncluirPendenciaVistoriaPosVenda.vue'    
    
    export default {
        props: { 
            ambiente:Object           

        },
        data() {
            return {                                               
                salvandoDados :false,                
                observacoes:null, 
                pendencias:null,
                anexoPendencia:[],
                apenasLayout: [],
                historicoAlteracoes: [],  
                status:false,
                historicoPendencias: []                
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
            ...mapGetters([
                'isInRole'
            ]),                
        },        

        mounted() {
            setTimeout(() => {this.status = (this.ambiente.vistoriador != null) ;},500);            
            this.loadHistoricoAlteracoes();
            this.loadHistoricoPendencias();
                     

        },
        methods: {
            visualizarAnexo(anexoId, fileName) {                
                window.open('/api/atendimento/AnexoVistoriaPdf?id=' + this.ambiente.id + '&anexoId=' + anexoId + '&fileName=' + fileName);
            },            

            excluirPendencia(pendenciaId){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMCONFIRMAEXCLUSAOPENDENCIA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        try {
                                
                            this.$http.get(`/api/atendimento/excluirPendencia?id=${pendenciaId}`)
                            .then((res) => {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('SISTEMA.SALVOSUCESSO'),
                                    type: 'is-success'
                                })
                            }).finally(() => {                                
                                this.isLoading = false;    
                                this.loadHistoricoAlteracoes();
                                this.loadHistoricoPendencias();                                                                                                 
                            });                             
                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }                        
                    },
                    onCancel: () => {                        
                        return true;
                    },
                })
            },            

            ConfirmarPendenciaNaoResolvida(guiaAmbientePendencia){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.REMOVERCONCLUIRPENDENCIA')  ,
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                                                
                        const params = [];                    
                        params.push(`pendenciaId=${guiaAmbientePendencia.id}`);    
                        params.push(`concluido=${guiaAmbientePendencia.concluido}`);                                                                                    
                    
                        this.$http.get(`/api/atendimento/ConfirmarPendenciaResolvida?${params.join('&')}`)
                        .then((res) => {
                            return true;
                        }).finally(() => {                                                            
                            this.loadHistoricoPendencias();

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('GUIAATENDIMENTO.MENSAGEMPENDENCIASALVA'),
                                type: 'is-success'
                            });                             
                        });                         
                        
                        
                    },
                    onCancel: () => {                        
                        this.loadHistoricoPendencias(); 
                    },
                });
            },                  
            ConfirmarPendenciaResolvida(guiaAmbientePendencia){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.CONCLUIRPENDENCIA') ,
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                                                
                        const params = [];                    
                        params.push(`pendenciaId=${guiaAmbientePendencia.id}`);    
                        params.push(`concluido=${guiaAmbientePendencia.concluido}`);                                                                                    
                    
                        this.$http.get(`/api/atendimento/ConfirmarPendenciaResolvida?${params.join('&')}`)
                        .then((res) => {
                            return true;
                        }).finally(() => {                                                            
                            this.loadHistoricoPendencias();
                            this.loadHistoricoAlteracoes();

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('GUIAATENDIMENTO.MENSAGEMPENDENCIASALVA'),
                                type: 'is-success'
                            });                             
                        });                         
                        
                        
                    },
                    onCancel: () => {            
                        this.loadHistoricoAlteracoes();            
                        this.loadHistoricoPendencias(); 
                    },
                });
            },             
            
            async incluirPendencia(){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalIncluirPendenciaVistoriaPosVenda,
                    props: {
                        pendencias:this.pendencias,
                        guiaAmbienteCodigo: this.ambiente.id                  
                    }, 
                    events: {
                        loadHistoricoPendencias: this.loadHistoricoPendencias                        
                    },                                 
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:false                   
                });
                this.pendencias = null;                                
            },              
            async incluirObservacao(){

                this.salvandoDados=true;
                

                if (this.observacoes== null  || this.observacoes == '' || this.observacoes == undefined) {
                    this.salvandoDados = false;
                    return;
                }      
                
                const guiaAmbienteObservacoes = {
                    guiaAmbienteCodigo: this.ambiente.id,
                    observacao : this.observacoes
                }                                
                
                try {
                    this.$http.post('/api/atendimento/GuiaAmbienteObservacoes', guiaAmbienteObservacoes)
                        .then(res => {
                            this.salvandoDados=false;
                            return true;

                        }).finally(() => {                                
                            this.salvandoDados=false;    
                            this.observacoes = null;

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('GUIAATENDIMENTO.MENSAGEMOBSERVACAOSALVA'),
                                type: 'is-success'
                            }); 
                            
                            this.loadHistoricoAlteracoes();
                                                                                                             
                        }); 
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                    
            },               
            loadHistoricoAlteracoes() {
                this.$http.get('/api/atendimento/GuiaAmbienteObservacoes?id=' + this.ambiente.id)
                    .then(res => {
                        this.historicoAlteracoes = res.data
                    })
            },   
            loadHistoricoPendencias() {
                this.$http.get('/api/atendimento/GuiaAmbientePendencias?id=' + this.ambiente.id)
                    .then(res => {
                        this.historicoPendencias = res.data
                    })
            },                     
                       
            async confirmar() {

                    if(this.historicoPendencias.filter(x=>!x.concluido).length>0) {
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMPENDENCIARESOLVER'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }                

                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.ambiente.id}`);
                    params.push(`status=${this.status}`);
                      
                                        
                    try {
                                   
                        this.$http.get(`/api/atendimento/SalvarVistoriaAmbiente?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.salvandoDados = false;                                                            
                            this.$emit('close'); 
                            this.$emit('loadAmbientes');                                                                 
                        });                             
                        
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                

              
                
            
            },
            

        }
    }
</script>