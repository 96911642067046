<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALINCLUIRPENDENCIAVISTORIAPOSVENDA.INCLUIRPENDENCIA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless" style="overflow-x: hidden;" >
          
            <div class="panel">

                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                    

                <p class="panel-block">
                    <b-field :label="$t('MODALINCLUIRPENDENCIAVISTORIAPOSVENDA.MENSAGEMANEXOS')">
                    </b-field>
                </p>                
                <div class="columns" >
                    <div class="column " >
                    <article class="panel">
                        <div class="column" >
                            <b-field>
                                <b-upload 
                                accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                multiple expanded drag-drop v-model="files" >
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-medium"> </b-icon>
                                            </p>
                                            <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>
                                            <p>({{$t('ANEXOS.APENASPDF')}})</p>
                                        </div>
                                    </section>
                                </b-upload>                                          
                            </b-field>
                            <div v-if="files" class="tags">
                                <span v-for="(file, index) in files"
                                    :key="index" class="tag is-primary" >
                                    {{file.name}}
                                    <button class="delete is-small" type="button" @click="deleteFile(index)">
                                    </button>
                                </span>                                            
                            </div>                                          
                        </div>                                                                                   
                    </article>                                     
                    </div>                                        
                </div>                   
            

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');  $emit('removerobranaoconformevendedor');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            guiaAmbienteCodigo: Number,  
            pendencias: String,                                                             
        },
        data() {
            return {                          
                observacoes: null,
                salvandoDados :false,
                files: [],                  
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            this.observacoes = this.pendencias;
            return true;
        },
        methods: {

            deleteFile(index){                
                this.files.splice(index, 1)            
            },                

            async incluirPendencia(){

                this.salvandoDados=true;
                

                if (this.observacoes== null  || this.observacoes == '' || this.observacoes == undefined) {
                    this.salvandoDados = false;
                    return;
                }      
                
                const giaAmbientePendencias = {
                    guiaAmbienteCodigo:  this.guiaAmbienteCodigo,
                    observacao : this.observacoes
                }                                
                
                try {
                    this.$http.post('/api/atendimento/GuiaAmbientePendencias', giaAmbientePendencias)
                        .then(res => res.data)
                        .then(data => {
                            this.salvandoDados=false;
                            this.incluirAnexo(this.files,data);

                        }).finally(() => {                                
                            this.salvandoDados=false;    
                            

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('GUIAATENDIMENTO.MENSAGEMPENDENCIASALVA'),
                                type: 'is-success'
                            }); 
                            
                            setTimeout(() => {
                                
                                this.$emit('loadHistoricoPendencias')                            
                                this.$emit('close'); 
                            },1000);

                                                                                                             
                        }); 
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                    
            },
            
            async confirmar() {

                    
                    this.incluirPendencia();                               

            },

            incluirAnexo(fileList,id) {
                const formData = new FormData()
                formData.append('id', id);
                formData.append('type', 'guia');                

                Array
                    .from(Array(fileList.length).keys())
                    .map(x => {                        
                        formData.append('anexo', fileList[x], fileList[x].name);
                    });

                this.uploadingAnexo = 0;
                this.salvandoDados = true;                    
                this.$http.post('/api/atendimento/anexoVistoria', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    });                    
                    this.salvandoDados = false;                              
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
            },                    
            

        }
    }
</script>