<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.DEVOLUCAO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.AVISOCANCELAMENTO')}}</p>
            </b-notification>
            <div class="panel">
                 
                <div class="panel-block">
                    <div class="columns">                                                                                                    
                        <div class="column is-4">
                                <label class="label">{{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.DEVOLUCAOPARAETAPA')}}</label>
                                <b-radio v-model="devolverEtapa"  @input="mudouDevolverEtapa"
                                    name="devolverEtapa"                                    
                                    native-value="assinatura">
                                    {{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.ETAPAASSINATURA')}}
                                </b-radio>
                            </div>
                            <div class="column is-4">
                                <label class="label" style="visibility: hidden;">{{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.DEVOLUCAOPARAETAPA')}}</label>
                                <b-radio v-model="devolverEtapa" @input="mudouDevolverEtapa"
                                    name="devolverEtapa"                                    
                                    native-value="medicao">
                                    {{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.ETAPAMEDICAO')}}
                                </b-radio>
                            </div>        
                            <div class="column is-4">
                                <label class="label" style="visibility: hidden;">{{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.DEVOLUCAOPARAETAPA')}}</label>
                                <b-radio v-model="devolverEtapa" @input="mudouDevolverEtapa"
                                    name="devolverEtapa"                                    
                                    native-value="producao">
                                    {{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.ETAPAPRODUCAO')}}
                                </b-radio>
                            </div>                                      
                    
                    </div>                      
                </div>

                <p v-if="devolverEtapa!=null && devolverEtapa!='producao'  " class="panel-block">
                    <b-field :label="$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.MOTIVOS')">
                    </b-field>
                </p>

                <div v-if="devolverEtapa!=null && devolverEtapa!='producao' " class="panel-block" style="width: 30%;" >
                    <b-select expanded v-model="motivoDevolucaSelecionado">
                        <option v-for="(motivo,index) in listaMotivosDevolucao" :key="index"
                                :value="motivo.descricao">
                            {{ motivo.descricao }}
                        </option>
                    </b-select>                    
                </div>

                <p class="panel-block">
                    <b-field :label="$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.AMBIENTES')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <table  v-if="ambientes !=null && ambientes.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Opção</th>
                                <th>Ambiente</th> 
                                <th>Tipo</th>
                                <th>Situação</th>                                                   
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(ambiente, index) in ambientes" :key="index">
                                
                                <td>
                                        <b-checkbox  @input="changed(ambiente)"
                                        size="is-small" type="is-info"  v-model="ambiente.selecionado"></b-checkbox >                                    
                                </td>                                
                                <td>{{ ambiente.opcao  }}</td>
                                <td>{{ ambiente.descricao }}</td>                                                    
                                <td>{{ ambiente.tipoDescricao }}</td>                            
                                <td>{{ ambiente.tipoSituacao }}</td>                                                                                                          
    
                            </tr>
                        </tbody>                        
                    </table>  
                </div>  
                
                <p class="panel-block">
                    <b-field :label="$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.MENSAGEMCONFERENCIACONCLUIDA')">
                    </b-field>
                </p>                
                <div class="columns" >
                    <div class="column " >
                    <article class="panel">
                        <div class="column" >
                            <b-field>
                                <b-upload 
                                accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                                multiple expanded drag-drop v-model="files" :disabled="files && files.length==1">
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-medium"> </b-icon>
                                            </p>
                                            <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>
                                            <p>({{$t('ANEXOS.APENASPDF')}})</p>
                                        </div>
                                    </section>
                                </b-upload>                                          
                            </b-field>
                            <div v-if="files" class="tags">
                                <span v-for="(file, index) in files"
                                    :key="index" class="tag is-primary" >
                                    {{file.name}}
                                    <button class="delete is-small" type="button" @click="deleteFile(index)">
                                    </button>
                                </span>                                            
                            </div>                                          
                        </div>                                                                                   
                    </article>                                     
                    </div>                                        
                </div>   

                <p class="panel-block">
                    <b-field :label="$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.RESERVA')">
                    </b-field>
                </p>
                <div class="panel-block" style="width: 30%;">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                     :loading="salvandoDados"
                      @click="confirmarMedicaoParcial">{{$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.CONFIRMARDEVOLUCAO')}}</b-button>
            <b-button @click=" $emit('close'); $emit('removerConferenciaConcluidaParcialVenda'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number,
            guiaId: Number,                                                                   
        },
        data() {
            return {                
                listaMotivosDevolucao: [],
                motivoDevolucaSelecionado: null,                
                observacoes: null,
                salvandoDados :false,
                devolverEtapa: null,
                ambientes:[],
                ambientesSelecionados:[],  
                files: [],               
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {            
            this.loadAmbientes();
        },
        methods: {
            deleteFile(index){                
                this.files.splice(index, 1)            
            },            

            changed(ambiente){

                if(ambiente.selecionado){
                    this.ambientesSelecionados.push(ambiente);
                }else{
                    this.ambientesSelecionados = this.ambientesSelecionados.filter(x=>x.id != ambiente.id);
                }
                    
            },               
            loadAmbientes() {              
                this.$http.get('/api/atendimento/ambientes?id=' + this.guiaId)
                    .then(res => res.data)
                    .then(data => {
                        this.ambientes = data;

                    });

                    
            },               
            mudouDevolverEtapa(){
              if(this.devolverEtapa == "assinatura" ){
                this.loadlistaMotivosDevolucaoAssinatura();
              } else if(this.devolverEtapa == "medicao" ){
                this.loadlistaMotivosDevolucaoMedicao();
              }
            },
            loadlistaMotivosDevolucaoAssinatura() {

                const params = [
                    `unidadeId=${this.unidadeId}`,                                        
                ].join('&');
            
                this.$http.get(`/api/search/MotivosDevolucaoAssinaturaVenda?${params}`)
                    .then(m => {
                        this.listaMotivosDevolucao = m.data
                    })
            },
            loadlistaMotivosDevolucaoMedicao() {

                const params = [
                    `unidadeId=${this.unidadeId}`,                                        
                ].join('&');
            
                this.$http.get(`/api/search/MotivosDevolucaoMedidorVenda?${params}`)
                    .then(m => {
                        this.listaMotivosDevolucao = m.data
                    })
            },            

            medicaoParcialConcluida(){

                this.salvandoDados=true;
                    const params = [];                    
                    if (this.id) params.push(`id=${this.id}`);
                    
                    if(this.motivoDevolucaSelecionado)                                                            
                        params.push(`motivo=${this.motivoDevolucaSelecionado}`);                                        
                    
                    if(this.observacoes) params.push(`observacoes=${this.observacoes}`);

                    if(this.devolverEtapa) params.push(`devolverEtapa=${this.devolverEtapa}`);
                                        
                    try {
                                   
                        this.$http.post(`/api/venda/ConferenciaConcluidaParcialVenda?${params.join('&')}`,this.ambientesSelecionados)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;    
                            this.$emit('loadData')                            
                            this.$emit('close'); 
                            this.$router.back();                                 
                        });                             
                        
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                

            },
            async confirmarMedicaoParcial() {

                if(this.devolverEtapa!=null){
                                        
                    if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }

                    if(this.ambientesSelecionados.length<=0){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALCONFERENCIACONCLUIDAPARCIALVENDA.MENSAGEMSELECIONEAMBIENTESCONFERIDOS'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;
                    }               
                    
                                              
                    if(this.files == null || this.files.length<1){

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('MODALCONFERENCIACONCLUIDAVENDA.MENSAGEMCONFERENCIACONCLUIDA'),
                            type: 'is-danger',
                            queue: false
                        });          
                        return false;          
                    }  

                    this.incluirAnexo(this.files);
                                                            
                }

            },

            incluirAnexo(fileList) {
                const formData = new FormData()
                formData.append('id', this.guiaId);
                formData.append('type', 'guia');
                formData.append('etapa', 'conferenciaconcluida');

                Array
                    .from(Array(fileList.length).keys())
                    .map(x => {                        
                        formData.append('anexo', fileList[x], fileList[x].name);
                    });

                this.uploadingAnexo = 0;
                this.isLoading = true;                    
                this.$http.post('/api/atendimento/anexoGuia', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    });                    
                    this.isLoading = false; 
                    this.medicaoParcialConcluida();            
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
            },               
            

        }
    }
</script>