<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <filtros :tipo="'notificacao'" @filtrar="filtrar($event)" @exportar="exportar($event)" :listaStatus="getStatus()"></filtros>
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>     

            <b-table v-if="lista.length>0" :data="lista"
                    :loading="isLoading"                    
                    striped
                    hoverable                                                            
                    backend-sorting
                    sort-icon-size="is-small">                    

                <template >
                    <b-table-column v-slot="props" field="tipo" :label="$t('RELATORIO.TIPO')" >
                        {{ (props.row.tipo == 0) ? 'Aviso' :  (props.row.tipo == 1) ? 'Atraso' : 'Mensagem' }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="Nome" :label="$t('SISTEMA.NOME')" >
                        {{ props.row.descricao }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="Data" :label="$t('SISTEMA.DATA')" >
                        {{ $moment(props.row.dataHora).format("DD/MM/YYYY HH:mm") }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="Funcionario" :label="$t('SISTEMA.FUNCIONARIO')" >
                        {{ props.row.funcionarioNome }}
                    </b-table-column>     

                    <b-table-column v-slot="props" field="Atendimento" :label="$t('SISTEMA.ATENDIMENTO')" >
                        {{ props.row.guiaId }}
                    </b-table-column>      

                    <b-table-column v-slot="props" field="Guia" :label="$t('SISTEMA.UNIDADE')" >
                        {{ props.row.unidadeNome }}
                    </b-table-column>                                                                                

                </template>
            </b-table>              
          
        </div>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import filtros from '@/components/filtrosRelatorio.vue';

    export default {
        data() {
            return {
                isLoading: false ,
                lista:[]
            }
        },
        components: {
            titlebar,
            filtros
        },
        computed: {
            titleStack() {
                return [
                    'Relatório',                                        
                    'Sistema',
                    'Notificação'
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'unidadeId',
                'config',                                             
                'usuario', 
            ]),            
        },        
        methods: {
            getStatus(){

                 const listaStatus = [
                                    {id:"todos", descricao:"Todos"},
                                    {id:"desativados", descricao:"Desativados"},
                                    {id:"ativos", descricao:"Ativos"},
                                ];


                return listaStatus;

            },
            filtrar(filtros){

                    const params = {                        
                        dataFiltro: (filtros.dataFiltro !=null) ? filtros.dataFiltro : null,
                        status: (filtros.status !=null) ? filtros.status : null,
                        dataInicial: (filtros.datas[0] !=null)  ? this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') : null,
                        dataFinal:  (filtros.datas[1] !=null) ?  this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss') : null,
                        funcionarioId: (filtros.funcionario?.id != null) ? filtros.funcionario?.id : null ,                                                
                        funcionarioLogadoId: this.usuario.funcionarioId,                        
                    }


                    if (filtros.unidade?.id != null){                         
                        params.unidadeId = filtros.unidade?.id;
                    }else if (filtros.unidade?.id == null && !this.usuario.administrador){ 
                        params.unidadeId = this.unidadeId;
                    }                    

                this.carregarLista(params);  

            },

            exportar(filtros){

                    const params = [];

                    if (filtros.dataFiltro != null) params.push(`dataFiltro=${filtros.dataFiltro}`);
                    if (filtros.status != null) params.push(`status=${filtros.status}`);
                    if (filtros.datas[0] != null) params.push(`dataInicial=${this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss')}`);
                    if (filtros.datas[1] != null) params.push(`dataFinal=${this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`);
                    if (filtros.funcionario?.id!= null) params.push(`funcionarioId=${filtros.funcionario?.id}`);
                    
                    
                    if (filtros.unidade?.id != null){ 
                        params.push(`unidadeId=${filtros.unidade?.id}`);   
                    }else if (filtros.unidade?.id == null && !this.usuario.administrador){ 
                        params.push(`unidadeId=${this.unidadeId}`);   
                    }
                    
                    params.push(`funcionarioLogadoId=${this.usuario.funcionarioId}`);                     
                                                                                                                                                                                                                                                            
                    window.open(`/api/relatorio/NotificacaoXLS?${params.join('&')}`,'_blank');
                
            },                              
            carregarLista(params){   
                
                    this.isLoading = true;
                    this.$http.post('/api/relatorio/Notificacao', params)
                    .then(res => res.data)
                    .then(data => {                         
                            this.lista = data;                           
                    }).finally(() => {
                        this.isLoading = false;
                    })
                    
            },
 
            
        }
    }
</script>