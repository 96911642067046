<template>
  <section>
    <b-loading :active.sync="isLoading"></b-loading>
    <titlebar :title-stack="titleStack"></titlebar>
    <div class="columns box">
      <div class="column is-3">
        <b-field>
          <b-input
            :placeholder="$t('FUNCIONARIOLISTA.PROCURARNOME')"
            v-model="procurar"
            icon="magnify"
            @input="loadAsyncData"
          ></b-input>
        </b-field>
      </div>

      <div class="column is-3">          
          <b-select :placeholder="$t('FUNCIONARIOCADASTRO.NIVEL')" @input="loadAsyncData()" v-model="nivelId" expanded >
              <option ></option>            
              <option v-for="option in niveis"
                      :value="option.id"
                      :key="option.id">
                  {{ option.nome }}
              </option>
          </b-select>
      </div>

      <div class="column is-3">
          <b-field>
              <b-select v-model="statusFiltro" :placeholder="$t('SISTEMA.STATUS')"  @input="loadAsyncData()" expanded>
                  <option value="Ativo">Ativo</option>
                  <option value="Inativo">Inativo</option>
              </b-select>
          </b-field>
      </div>         
      <div class="column">
        <div class="is-flex is-justified-between">
          <router-link
            v-if="isInRole('Administrador')"
            :to="{ name: 'funcionario' }"
            class="button is-primary">
            {{$t('SISTEMA.INCLUIR')}}
          </router-link>     
          
          <b-button  type="button"  @click="atualizarListarFuncionarios()" class="is-info is-pulled-right ml-1"  icon-left="account-edit" title="Selecionar e atualizar funcionários do EList"></b-button>                         
          <b-button v-if="false" type="button" class="is-info is-pulled-right ml-1" @click="atualizarFuncionarios()" 
          icon-left="account-arrow-left" title="Atualizar funcionários do EList"></b-button>
          
        </div>        
      </div>

    </div>
    <b-table
      :data="model.lista"
      :loading="loading"
      paginated
      striped
      hoverable
      backend-pagination
      :total="total"
      @page-change="onPageChange"
      :aria-next-label="$t('SISTEMA.PROXPAGINA')"
      :aria-previous-label="$t('SISTEMA.PAGANTERIOR')"
      :aria-page-label="$t('SISTEMA.PAGINA')"
      :aria-current-label="$t('SISTEMA.PAGATUAL')"
      backend-sorting
      :default-sort-direction="defaultOrdenar"
      :default-sort="[ordenarPor, ordenar]"
      sort-icon-size="is-small"
      @sort="onSort"
    >
      <template  >
        <b-table-column v-slot="props" field="id" :label="$t('SISTEMA.ID')">
          {{ props.row.id }}
            <b-icon v-if="props.row.desativado"
                            icon="close-circle"
                            size="is-small"                                    
                            :title="$t('FUNCIONARIOLISTA.DESATIVADO')"
                            type="is-danger"></b-icon>                
        </b-table-column>

        <b-table-column v-slot="props" field="Nome" :label="$t('SISTEMA.NOME')">
          {{ props.row.nome }}
        </b-table-column>

        <b-table-column v-slot="props" field="NivelAcesso" :label="$t('FUNCIONARIOLISTA.NIVELACESSO')">
          {{ props.row.nivelAcesso }}
        </b-table-column>       

        <b-table-column v-slot="props" field="Email" :label="$t('FUNCIONARIOLISTA.EMAIL')">
          {{ props.row.email }}
        </b-table-column>                

        <b-table-column v-slot="props" custom-key="actions" numeric>
          <router-link
            :to="{ name: 'funcionario', params: { id: props.row.id } }"
            class="button is-small is-primary"
            :title="$t('SISTEMA.EDITAR')"
          >
            <b-icon icon="pencil" size="is-small"></b-icon>
          </router-link>
        </b-table-column>
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapGetters,mapState } from "vuex";
import titlebar from "@/components/titlebar.vue";
import modalListarFuncionarios from '@/components/manutencao/modalListarFuncionarios.vue'

export default {
  data() {
    return {
      model: [],
      total: 0,
      loading: false,
      isLoading:false,
      procurar: this.$route.query.procurar ?? "",
      ordenarPor: "Nome",
      ordenar: "asc",
      defaultOrdenar: "asc",
      page: 1,
      perPage: 20,
      selecionado: null,
      id: null,
      statusFiltro: "Ativo",
      niveis: [],
      messages:[]
    };
  },
  components: {
    titlebar,
  },
  computed: {
      ...mapState([                                
          'unidadeId',                
      ]),     
    titleStack() {
      return [this.$t('SISTEMA.MANUTENCAO'), 
      this.$t('FUNCIONARIOLISTA.FUNCIONARIO')];
    },
    ...mapGetters(["isInRole"]),
  },
  watch: {            
      unidadeId: function(novaUnidade) {                 
          this.loadAsyncData();
          this.loadNiveis();                                                
      },
  },     
  methods: {
    atualizarListarFuncionarios(){
          this.$buefy.modal.open({
              parent: this,
              component: modalListarFuncionarios,
              events: {
                  loadData: this.loadAsyncData,                        
              },                    
              hasModalCard: true,
              trapFocus: true,
              fullScreen: true
          })

    },
    atualizarFuncionarios(){
            this.$buefy.dialog.confirm({
                title: 'Atualizar funcionários do EList',
                message: 'Deseja importar o cadastro do funcionários do EList',
                type: 'is-info',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {
                
                    this.isLoading=true;
                    const params = [];                                        
                    params.push(`unidadeId=${this.unidadeId}`);                                        
                                                                            
                    try {                            
                        this.$http.get(`/api/integracao/AtualizarFuncionariosEList?${params.join('&')}`)                        
                        .then(res => res.data)
                        .then(data => {     
                            this.messages = data;                       
                            this.isLoading=false;
                        }).finally(() => {                                
                            this.isLoading = false; 
                            if(this.messages !=null && this.messages.length>0){
                                this.messages.forEach((m) => {

                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: m,
                                    type: 'is-success',
                                    queue: false
                                })                                    

                                });                  
                            }
                                                                                               
                        }).catch((error) => {    

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Ocorreu um erro na integração com Elist!",
                                type: 'is-danger',
                                queue: false
                            });                                                    
                            throw error;

                        });                               
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
                }
            });

    },
    loadNiveis() {      
        const params = [                    
            `unidadeId=${this.unidadeId}`                                                              
        ];                   
      this.$http.get(`/api/search/ListarNiveisAcesso?${params.join('&')}`)                
            .then(res => {
                this.niveis = res.data
            });
    },    
    loadAsyncData() {
      const params = [
        `procurar=${this.procurar}`,
        `ordem=${this.ordenarPor}.${this.ordenar}`,
        `page=${this.page}`,
        `unidadeId=${this.unidadeId}`,
        `statusFiltro=${this.statusFiltro}`,                
      ];

      if(this.nivelId && this.nivelId>0)
        params.push(`nivelId=${this.nivelId}`);

      this.loading = true;
      this.$http
        .get(`/api/manutencao/funcionarios?${params.join("&")}`)
        .then(({ data }) => {
          this.model = data;
          if (data.lista.length == 0 && this.page > 1) {
            this.page = 1;
            this.loadAsyncData();
          }
          this.total = this.perPage * data.pageCount;
          this.loading = false;
          this.$router.replace({
            query: { procurar: this.procurar, page: this.page },
          });
        })
        .catch((error) => {
          this.model = [];
          this.total = 0;
          this.loading = false;
          throw error;
        });
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    onSort(field, order) {
      this.ordenarPor = field;
      this.ordenar = order;
      this.loadAsyncData();
    },

  },
  mounted() {
    this.loadAsyncData();
    this.loadNiveis();
  },
};
</script>
