<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALDEVOLUCAOPLANTASIMPRESSAOVENDA.DEVOLUCAO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALDEVOLUCAOPLANTASIMPRESSAOVENDA.AVISOCANCELAMENTO')}}</p>
            </b-notification>

            <p class="panel-block">
                    <b-field :label="$t('MODALDEVOLUCAOPLANTASIMPRESSAOVENDA.ALTERACAOPLANTAINSTALACAO')">
                    </b-field>
                </p>       
                <p class="panel-block">

                    <b-field class="file is-primary" :class="{'has-name': !!alteracaoPlantaInstalacao}">
                        <b-upload
                        accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                            v-model="alteracaoPlantaInstalacao" class="file-label">
                            
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>                                                                                        
                                    <span class="file-label">Carregar Aquivo</span>
                                </span>                                            
                            <span class="file-name" v-if="alteracaoPlantaInstalacao">
                                {{ alteracaoPlantaInstalacao.name }}
                            </span>
                        </b-upload>
                    </b-field>                 

                </p>
                
                <p class="panel-block">
                    <b-field :label="$t('MODALDEVOLUCAOPLANTASIMPRESSAOVENDA.ALTERACAOPLANTATECNICA')">
                    </b-field>
                </p>       
                <p class="panel-block">

                    <b-field class="file is-primary" :class="{'has-name': !!alteracaoPlantaTecnica}">
                        <b-upload
                        accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                            v-model="alteracaoPlantaTecnica" class="file-label">
                            
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>                                                                                        
                                    <span class="file-label">Carregar Aquivo</span>
                                </span>                                            
                            <span class="file-name" v-if="alteracaoPlantaTecnica">
                                {{ alteracaoPlantaTecnica.name }}
                            </span>
                        </b-upload>
                    </b-field>                 

                </p>                
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('MODALDEVOLUCAOPLANTASIMPRESSAOVENDA.CONFIRMARDEVOLUCAO')}}</b-button>
            <b-button @click=" $emit('close'); $emit('removermodalDevolucaoPlantasImpressaoVenda'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number,
            posvendaId: Number                                                                  
        },
        data() {
            return {                                                   
                observacoes: null,
                salvandoDados :false,
                alteracaoPlantaTecnica:null, 
                alteracaoPlantaInstalacao:null, 
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        methods: {

            incluirAnexo(file,etapa) {
                const formData = new FormData()
                formData.append('id', this.id);
                formData.append('type', 'guia');
                formData.append('etapa',etapa);                      
                formData.append('anexo', file, file.name);
                  

                this.uploadingAnexo = 0;
                this.isLoading = true;                    
                this.$http.post('/api/atendimento/anexoGuia', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    });                    
                    this.isLoading = false; 
                                                  
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
            },                

            async confirmarDevolucao() {
                

                if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                    
                    this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                    type: 'is-warning',
                    hasIcon: true
                    });                        
                    return true;
                }

                                                
                this.salvandoDados=true;
                const params = [];                    
                if (this.posvendaId) params.push(`id=${this.posvendaId}`);                                                                                                                  
                if(this.observacoes) params.push(`observacoes=${this.observacoes}`);
                                    
                try {
                                
                    this.$http.get(`/api/posvenda/DevolucaoPlantasImpressaoVenda?${params.join('&')}`)
                    .then((res) => {
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.isLoading = false;    
                        this.$emit('loadData')                            
                        this.$emit('close'); 
                        this.$router.back();                                 
                    });                             
                    
                    
                } catch (e) {
                    this.salvandoDados=false;                        
                    console.error(e);
                }                

            },

            async confirmar() {


                if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                    
                    this.$buefy.dialog.alert({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                    type: 'is-warning',
                    hasIcon: true
                    });                        
                    return true;
                }                

                if(this.alteracaoPlantaTecnica != null ){

                    this.incluirAnexo(this.alteracaoPlantaTecnica,"alteracaoPlantaTecnica");         
                }  
                
                if(this.alteracaoPlantaInstalacao != null ){

                    this.incluirAnexo(this.alteracaoPlantaInstalacao,"alteracaoPlantaInstalacao");        
                }      
                
                setTimeout(() => {this.confirmarDevolucao();},500);

                
                                            
            },            
            

        }
    }
</script>