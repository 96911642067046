<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('SETORESCADASTRO.SALVARSETOR')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SISTEMA.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="50" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column ">
                                    <ValidationProvider rules="required" name="Departamento" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SETORESCADASTRO.DEPTO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('SETORESCADASTRO.SELEDEPTO')" class="is-fullwidth" v-model="model.departamento.id">
                                                <option v-for="(item, index) in departamentos" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                                
                            </div>
          
                        </article>
                    </div>  
                         
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('setor-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'    
    import Vue from 'vue'

    export default {
        components: {
            titlebar            
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    departamento: null,
                },                                
                departamentos: [],
                salvandoDados: false                               
            }
        },
        created() {            
            this.$http.get('/api/manutencao/setor' + ( this.$route.params.id ? '?id=' + this.$route.params.id : ''))
                .then(res => {
                    this.model = res.data;                    
                });

            this.$http.get('/api/search/departamentos?unidadeId='+this.unidadeId)
                .then(res => {
                    this.departamentos = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('SETORESLISTAR.SETORES'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([                                
                'unidadeId',                
            ]),              
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;

                this.model.UnidadeDeCadastro = { id : this.unidadeId};

                this.$http.post(`/api/manutencao/setor`, this.model)
                    .then((res) => {
                        this.model.id = res.data;
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            }
        }
    }
</script>