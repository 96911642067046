
export default {
    LANGUAGE:{
        NAME:"Portugues"
    },
    SISTEMA:{
        CODIGOCONTRATO: "Contrato",
        OK: "Ok",
        CATEGORIA: "Categoria",
        ETAPA: "Etapa",
        CLIENTENAOENCONTRADO: "Cliente não encontrado !",
        INSIDE: "Inside?",
        VOLTAR: "Voltar",
        APELIDO: "Apelido",
        SALVOSUCESSO:"Salvo com sucesso",
        ENVIAR: "Enviar",
        EDITAR: "Editar",
        INCLUIR: "Incluir",
        LISTAR: "Listar",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        CONFIRMAR: "Confirmar",   
        CODIGO: "Código",     
        CLIENTE: "Cliente",
        UNIDADE: "Unidade",
        FUNCIONARIO: "Funcionário",        
        SETOR: "Setor",        
        FORNECEDOR: "Fornecedor",        
        SOMENTELEITURA: "Somente leitura",
        NOVO: "Novo",        
        GUIA: "Guia", 
        SELECIONEDATAHORA: "Selecione a data e hora",        
        ATENCAO: "Atenção!",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual", 
        PROCURAR: "Procurar",
        ALTERAR: "Alterar",            
        ERROSALVAR: "Erro ao salvar: ",
        SELECMODEL: "Selecione o Modelo",
        LISTARTODOS: "Listar todos", 
        LISTARATRASADOS: "Listar atrasados",
        FECHAR: "Fechar",        
        SAIR: "Sair",                                       
        JANEIRO: "Janeiro",
        FEVEREIRO: "Fevereiro",
        MARCO: "Março",
        ABRIL: "Abril",
        MAIO: "Maio",
        JUNHO: "Junho",
        JULHO: "Julho",
        AGOSTO: "Agosto",
        SETEMBRO: "Setembro",
        OUTUBRO: "Outubro",
        NOVEMBRO: "Novembro",
        DEZEMBRO: "Dezembro",
        NOME: "Nome",    
        ID: "Id",
        MANUTENCAO: "Cadastro",
        ATENDIMENTO: "Atendimento",
        VENDAS: "Vendas",
        POSVENDA: "Pós Venda",
        AGENDA: "Agenda",
        DESC: "Descrição",
        DATA: "Data",
        DESATIVADO: "Desativado",              
        CAMPOSOBRIGATORIOS: "Preencha todas os campos!",   
        NENHUM: "Nenhum",
        OBSERVACAO: "Observação",             
        HISTORICO: "Histórico",   
        STATUS: "Status",
        RELATORIO: "Relatórios",
        SIM: "Sim",
        NAO: "Não",
        VALOR: "Valor",
        VALORPREVISTO: "Expectativa",
        PERCENTUAL : "%",
        VALORPEFINANCE: "Efinance",
        VALORELISTS: "Valor Elists",
        PDF: "PDF",
        EXPORTAR: "Exportar",
        IMPORTAR: "Importar",
        TIPO: "Tipo",
        EXCLUIR: "Excluir",
        REMOVER: "Remover",
        MOTIVO: "Motivo",
        VENDEDOR: "Vendedor",
        OBSERVACAOOBRIGATORIA: "Campo observação deve ter no mínimo 20 caracteres",
        DATAINICIAL: "Data Inicial",
        DATAFINAL: "Data Final",
    },
    RELATORIO:{
        SISTEMA: "Sistema",
        TIPO: "Tipo",
        USUARIO: "Usuário",
        MANUTENCAO: "Manutenção",
        ATENDIMENTO: "Atendimento",
    },

    FILTROS:{
        FILTRO: "Filtros",        
        PERIODO: "Período",
        FILTRAR: "Filtrar por",
        DATACRIACAO: "Data criação",
        STATUS: "Status",  
        NOME: "Nome",      
        CODIGO: "Código",
    },    
    
    SOBRE: {
        APRESENTACAO: "Sistema de gerenciamento de móveis planejados",
        LICENCIADOPARA: "Este sistema está licenciado para:",
        VERSAO: "versão" 
     },
     HISTORICO: {
        FECHAR: "Fechar",
        TITULO: "Histórico de alterações",
        INFORMACOES: "Informações",
    },     
    MODELODOCUMENTO:{
        MODELODOCUMENTONOMe: "Modelo de Documento"
    } ,        
    MANUTENCAOLISTAR: {
        ARQUITETO: "Arquitetos",
        MODELODOCUMENTOS: "Modelos de Documentos",
        PESSOAS: "Pessoas",
        CLIENTES: "Clientes",             
        FUNCIONARIO: "Funcionários",
        NIVELACESSO: "Níveis de acesso",
        EMPRESA: "Empresas",
        FORNECEDOR: "Fornecedores",
        SETORES: "Setores",
        DEPARTAMENTOS: "Departamentos",
        MOTIVOS: "Motivos",
        OUTROS: "Outros",
        UNIDADES: "Unidades",
        TIPOAGENDAMENTO: "Agendas",
        FERIADO: "Feriados",
        CONDPAGAMENTO: "Condições de pagamento",
        TIPOSANEXOS: "Tipos de Anexos",
        ESPECIEPAGAMENTO: "Espécie de pagamento",
        PRAZOS: "Prazos",
        CUSTOS: "Custos",
    },  
    
    MODALUSUARIO: {
        TITULO: "Configuração do Usuário",        
        ALTERARSENHA: "Alterar senha",
        LINKSUTEIS: "Links úteis:",
        SUPORTEREMOTO: "Suporte remoto",
        VIDEOSTREINAMENTO: "Vídeos de Treinamento",
        AGENTEIMPRESSAO: "Agente de Impressão",
        ALTERARSENHAMSG: "Tem certeza que deseja alterar a senha do seu usuário?",        

    },

    FERIADOSLISTA: {                 
        FERIADOS: "Feriados",
        REMOVERFERIADO: "Remover feriado?",
        IMPORTARFERIADO: "Deseja importar feriados para ano de {0}"
    },
    ARQUITETOLISTA: {                 
        ARQUITETOS: "Arquitetos",
    },      
    FERIADOCADASTRO: {
        SALVANDOCADASTRO: "O feriado está sendo salvo, este processo pode levar alguns minutos.",                
        ANO: "Ano",
        FERIADOSNAC: "Importar Feriados Nacionais",        
        FERIADO: "Feriado",
        JAEXISTE: "Ja existe um feriado com a mesma data ja cadastrada",
    }, 
    ARQUITETOCADASTRO: {
        SALVANDOCADASTRO: "O arquiteto está sendo salvo, este processo pode levar alguns minutos.",                        
        ARQUITETO: "Arquiteto",
        TIPO: "Tipo",
        
    },       
    TIPOANEXOSLISTA: {                 
        TIPOANEXOS: "Tipo Anexos",
    },
    TIPOANEXO: {                        
        TIPOANEXO: "Tipo Anexo",        
    },  
         
    
    PRAZOCADASTRO: {                        
        PRAZO: "Prazo",       
        DIAS: "Dias" 
    },
    CUSTOCADASTRO: {                        
        CUSTO: "Custo",
        MOTIVOS: "Motivos"               
    },                 

    ESPECIEPAGAMENTOLISTA: {
        ESPECIEPAG: "Especie de pagamento",
    },    
    
    CONDICOESLISTA: {
        CONDPAG: "Condições de Pagamentos",
    },
    CONDICOESCADASTRO: {
        SALVANDOCADASTRO: "A condição de pagamento de exame está sendo salva, este processo pode levar alguns minutos.",        
        ESPECIEPAG: "Espécie de Pagamento",
        SELESPECIEPAG: "Selecione a Espécie",
        QTDEPARCEL: "Qtde. Parcelas",
        BANDEIRA: "Bandeira",
        SELBANDEIRA: "Selecione a Bandeira",
        VENCIMENTO: "Prazo de Vencimento",                              
        CONDPAG: "Condição de Pagamento",
        EMITENOTAFISCAL: "Gera Nota Fiscal",
        GERARBOLETO: "Gera Boleto",
    }, 
    TIPOAGENDAMENTOLISTA: {
        TIPOAGENDAMENTO: "Agendas",
    },
    TIPOAGENDAMENTOCADASTRO: {
        
        SALVANDOAGENDAMENTO: "O agenda está sendo salvo, este processo pode levar alguns minutos.",
        COR: "Cor",        
        TIPOAGENDAMENTO: "Agenda" ,
        VIGENCIA: "Vigência",        
        INTERVALO: "Intervalo Duração (em minutos)",        
        DIASREALIZACAO: "Dias de realização",           
        ESCALA: "Escalas",        
        DIADASEMANA: "Dia da Semana",        
        HORAINICIO: "Hora Inicio Expediente",
        HORAFIM: "Hora Fim Expediente",        
    },       

    UNIDADELISTA: {
        UNIDADES: "Unidades",
    },
    UNIDADECADASTRO: {
        SALVANDOUNIDADE: "A unidade está sendo salva, este processo pode levar alguns minutos.",        
        HORAPADRAO: "Hora Padrão de Retorno",
        FIMEXP: "Hora de Fim do Expediente",
        MODELORES: "Modelo de Resultado",
        MODELOSEL: "Selecione o Modelo",        
        CODINTEG: "Códigos integrações",                          
        UNIDADE: "Unidade",
        CONFIGNF: "Configurações",
        CAMPOSNF: "Campo",
        VALORESNF: "Valor",
        ADDCONFIG: "Adicionar Configuração"
    },   
    
    SETORESLISTAR: {
        SETORES: "Setores",
    },
    SETORESCADASTRO: {
        SALVARSETOR: "O setor está sendo salvo, este processo pode levar alguns minutos.",
        NOME: "Nome",
        AGRUPASETOR: "Agrupar com setor",
        SELECIONASETOR: "Selecione o Setor",
        DEPTO: "Departamento",
        SELEDEPTO: "Selecione o Departamento",
        MODELETQ: "Modelo de etiqueta",
        QTDEEXAME: "Quantidade de exames por amostra",
        COR: "Cor",
        INTEG: "Integradores",
        ID: "Id",
        SELECINTEG: "Selecione o integrador",
        ADDINTEG: "Adicionar integrador",
        SETOR: "Setor",
        NAOBANCADA: "Não emite bancada",
        COMRASCUNHO: "Emite bancada com rascunho",
        NAOCRIA: "Não cria amostras automaticamente na recepção",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Cadastro",
        SETORES: "Setor",
        EXAMESIMAGEM: "Exames de imagem",
        EXAMESNOTIFICAR: "Notificar exames em dias de realização",
        ALIQUOTAAUTOMATICA: "Aliquota Automática",
        CENTRORESULTADOS: "Centro de resultados"  
    },
    DEPTOLISTA: {
        DEPTOS: "Departamentos",
    },
    DEPTOCADASTRO: {        
        DEPTO: "Departamento",
    },  
    
    FORNECEDORES: {
        CONTATO: "Contato",
        TELEFONE: "Telefone",        
        FORNECEDOR: "Fornecedores",
    },
    FORNECEDORCADASTRO: {
        CONTATO: "Contato",
        TELEFONE: "Telefone",
        CNPJ: "CNPJ",
        IE: "Inscrição Estadual",
        IM: "Inscrição Municipal",
        EMAIL: "Email",
        OBS: "Observações",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MANUTENCAO: "Cadastro",
        FORNECEDOR: "Fornecedor",
        CAMPOOBRIGATORIO: "O campo cidade é obrigatório",
        ERROSALVAR: "Erro ao salvar fornecedor: ",
        DEPARACONTABIL: "De-para contábil",
    }, 
    BUSCACEP: {
        CEP: "CEP",
        CEPMESSAGEM: "CEP não encontrado",
        ENDERECO: "Endereço",
        BAIRRO: "Bairro",
        NUMERO: "Número",
        COMPLEMENTO: "Complemento"
    },
    BUSCACIDADE: {
        CIDADE: "Cidade",
        NENHUMACIDADE: "Nenhuma cidade selecionada",
    },        
    NIVELDEACESSO: {
        NIVEL: "Níveis de acesso",
    },
    NIVELDEACESSOCADASTRO: {
        SALVANDONIVEL: "O nível está sendo salvo, este processo pode levar alguns segundos.",                
        ADM: "Permitir ver outras unidades?",                
        REGRAS: "Regras",        
        NIVEL: "Nível de Acesso",
        UNIDADESDECADASTRO: "Unidades de cadastro",
    },   
    
    FUNCIONARIOLISTA: {
        PROCURARNOME: "Procurar por nome",
        NIVELACESSO: "Nível de Acesso",
        USUARIO: "Usuário",
        EMAIL: "E-mail",                
        FUNCIONARIO: "Funcionários",
        DESATIVADO: "Funcionário Desativado",
        DIASINATIVO: "Inatividade"
    },
    FUNCIONARIOCADASTRO: {
        CODIGOEXTERNO: "Código Externo",
        UNIDADE: "Unidade",
        USUARIO: "Usuário",
        ALTERAR: "Alterar",
        SENHA: "Senha",
        CONFIRMARSENHA: "Confirmação de Senha",
        CPF: "CPF",
        FONE: "Fone",
        CELULAR: "Celular",
        NIVEL: "Nível",
        EMAIL: "Email",        
        ASSINATURA: "Assinatura Texto",
        FOTO: "Foto",
        CAPTURAR: "Clique para capturar pela webcam",
        ARQUIVO: "Escolher arquivo...",
        DESATIVADO: "Desativado",        
        ESCOLHERARQ: "Escolher Arquivo",
        MUDARSENHA: "Redefinir Senha",        
        FUNCIONARIO: "Funcionário",
        SENHASIGUAIS: "As senhas devem ser iguais",
        USUARIOLOGADO: "Não é possível alterar o usuário atualmente logado!",
        ALTERARUSUARIO: "Tem certeza que deseja alterar o login desse funcionário?",        
        CODIGOOPERADORA: "Código na Operadora",
    },   
    CLIENTESLISTA: {                
        SEXO: "Sexo",
        NASCIMENTO: "Nascimento",        
        EMAIL: "E-mail",
        RESPONSAVEL: "Responsável",
        DESATIVADO: "Desativado",                
        ESPECIE: "Espécie",
        RACA: "Raça",
        ARQUITETO: "Arquiteto"
    },
    CLIENTECADASTRO: {              
        MSGERROCPF : "CPF inválido",
        ARQUIVO: "Escolher arquivo...",
        PENDENCIA: "Sem pendências financeiras",        
        NOMESOCIAL: "Nome Social",        
        FONE: "Fone",
        CELULAR: "Celular",
        ENVIAMSG: "Envia mensagens SMS/WhatsApp",
        SEXO: "Sexo",
        SELECIONESEXO: "Selecione o sexo",
        FEM: "Feminino",
        MASC: "Masculino",
        INDT: "Indeterminado",
        NASCIMENTO: "Nascimento",
        IDADE: "Idade",
        CALCIDADE: "Calcular Idade em",
        ANOS: "Anos",
        MES: "Meses",
        DIA: "Dias",
        PESO: "Peso (kg)",
        ALTURA: "Altura (cm)",
        EMAIL: "Email",
        RG: "RG",
        CPF: "CPF",        
        PASSAPORTE: "Passaporte",
        OBSERVACOES: "Observações",
        USUARIO: "Usuário",                
        DESATIVADO: "Desativado",        
        LISTAR: "Listar",
        LISTARMENSAGEM:"Listar atendimentos do cliente",
        CRIAR: "Criar",
        CRIARMENSAGEM:"Criar novo atendimento",
        DOC: "Documentos",
        DOCPESSOAIS: "Documentos Pessoais",
        NENHUMDOC: "Nenhum documento",        
        INDICACAO: "Indicação",                                           
        NASCIMENTOINVALIDO: "Valor informado para a data de nascimento inválido!",
        IDADEINVALIDA: "Valor informado para a idade inválido!",
        EMAILMSG: "O e-mail ",
        EMUSO: " já está sendo usado pelo usuario do ",        
        CPFOBRG: "CPF ou passaporte obrigatório.",
        ENDOBRG: "Endereço obrigatório.",
        EMAILOBRG: "Email obrigatório.",
        CELOBRG: "Celular obrigatório.",        
        CPFUSO: "Este CPF já está em uso.",
        CODIGOEXTERNOEMUSO: "Este código externo ja está em uso",
        ERROSALVARCLIENTE: "Erro ao salvar cliente, verificando cpf: ",
        ERROCLIENTE: "Erro ao salvar cliente: ",
        ERROHIST: "Erro ao carregar histórico: ",  
        AGENDAMENTOS: "Próximos Agendamentos",
        ARQUITETO: "Arquiteto",
        
    },    
    
    ANEXOS:{
        ARRASTEARQUIVOS: "Arraste os arquivos aqui ou clique para selecioná-los",
        REMOVERANEXO: "Remover anexo",
        ANEXO: "Anexo",
        EXCLUIRANEXO: "Exclusão de anexo",
        CONFIRMAEXCLUSAO: "Confirma a exclusão do anexo: <b>",
        APENASPDF: "Limite de até 50MB",
        APENASFOTOS: "Apenas arquivos de imagem",
    },
    MODALSMS: {
        ENVIARMENSAGEM: "Enviar mensagem SMS",
        NUMERO: "Número",
        MENSAGEM: "Mensagem",
    },    
    VENDAS: { 
        REVISAO: "Revisão Efetivação",
        VALIDAR: "Validar Efetivação",
        FINANCEIRO: "Financeiro",
        ASSINATURA: "Assinatura de Projetos",
        ELETROS: "Eletros",
        MEDIDOR: "Medidor",
        CONFERENCIAESPERA: "Conferência Em Espera ",
        CONFERENCIAEXECUCAO: "Conferência Em Execução",
        ENVIOPRODUCAO: "Envio Produção",
        FINALIZAREFINANCEVENDA: "Finalizar Efinance",
        PLANTAS: "Plantas",
        ENTREGAFUTURA: "Entrega Futura",
        ENTREGAFUTURALISTAR: "Entregas Futura",
        CONFERENCIASEXECUCAO: "Listar Conferências",
        MENSAGEMAGUARDANDOALTERACAO:"Para avançar o processo aguarde a finalização da alteração do projeto. Verique no fluxo de atendimento",        
        ASSISTENCIA: "Assistência",
        MOSTRUARIO: "Mostruário",
        AGENDAMENTOENTREGAREALIZADO: "Agendamento Realizado",
        
    },
    POSVENDAS: {     
        ASSISTENCIAATRASADA: "Assistências Atrasadas",    
        VISTORIA: "Vistorias", 
        PLANTASINSTALACAO: "Obra não Conforme", 
        INSTALACAOFUTURAS: "Instalações Futuras", 
        OBRASPLANTAS: "Plantas Impressão",
        EMVISTORIA: "Em Vistoria",
        ENTREGA: "Entrega",
        PARAINSTALACAO: "Para Instalação",
        EMINSTALACAO: "Em Instalação",
        ASSISTENCIATECNICA: "Assistências Técnicas",
        DEVOLUCAOSUPERVISOR: "Devolução Supervisor: Informações pendentes",
        AGUARDANDOFABRICAAPROVAR: "Aguardando Aprovação Fábrica",
        AGUARDANDOSUPERVISORRESOLVER: "Aguardando supervisor resolver pendência",
        CONFIRMARFABRICAAPROVAR: "Confirmar Aprovação Fábrica?",
        ENVIARASTECFABRICA: "Enviar ASTEC Fábrica",
        FINALIZAREFINANCE: "Finalizar eFinance",        
        FINALIZARFINANCEIRO: "Finalizar Financeiro",     
        INSTALACAOINCOMPLETA:"Instalação Incompleta",
        MENSAGEMAGUARDANDOVISTORIA:"Para avançar o processo aguarde a finalização da vistoria. Verique a etapa anterior",        
        MENSAGEMLIBERARENTREGASELECIONE:"Selecione ao menos uma entrega",
        MENSAGEMUNIFICARATENDIMENTOS:"Seleciona ao menos dois atendimentos para serem unificados",
        MENSAGEMCONFIRMARUNIFICARATENDIMENTOS: "Tem certeza que deseja unificar os atendimentos?",
        LIBERARENTREGA: "Liberar",
        UNIFICARATENDIMENTO: "Unificar Atendimentos",
        CANCELARCONFIRMARLIBERACAO: "Cancelar Liberação",
        NOVOCLIENTE: "Novo Cliente",
        CONFIRMARLIBERACAO: "Confirmar Liberação",
        CONFIRMARUNIFICACAO: "Confirmar anificação dos atendimentos",        
        AGENDAMENTOENTREGAOBRIGATORIO: "É necessário fazer o agendamento da entrega, antes de realizar a liberação",
        ATENDIMENTOSMESMOCONTRATO: "Os atendimentos devem ser do mesmo contrato",        
    },    
    ATENDIMENTO: { 

        TODASINFORMACOES: "Ver Todas Informações",
        ULTIMASINFORMACOES: "Últimas Informações",
        CLIENTESAGENDADOS: "Clientes agendados para hoje",
        NENHUMCLIENTEAGENDADO: "Nenhum agendamento para hoje",        
        ABRIRCALENDARIO: "Calendário",
        AGENDAS: "Agendamentos",
        LISTARTODOSAGENDAMENTOS: "Listar todos os agendamentos",
        AGENDAMENTOSHOJE: "Agendamentos para hoje",   
        INCLUIRGUIA: "Iniciar Atendimento",
        NOMECLIENTE: "Digite o nome do cliente",
        PROCURARCLIENTE: "Procurar cliente por nome/CPF",    
        LISTARATENDIMENTOS: "Listar Atendimentos",         
        DEFINIRPRIORIDADE: "Prioridade a Definir",
        VENDASPARAEFETIVAR: "Em Negociação",
        ASSINATURADIGITAL: "Assinatura Digital",
        ASSINATURADIGITALCONCLUIDO: "Finalizar Etapa?",
        ASSINATURADIGITALLINK: "Gerar Link de Assinatura",
        ASSINATURADIGITALATUALIZAR: "Atualizar assinaturas?",
        AUTORIZARPROJETO: "Projetos em Espera",
        PROJETOANDAMENTO: "Projetos em Andamento",
        AGENDAMENTOCLIENTE: "Agendar Visita",
        AGENDAMENTOVISITA: "Agendar Vistoria",
        ULTIMASVISTORIAS: "Últimas Vistorias",
        AGENDAMENTOINSTALACAO: "Instalação",
        AGENDAMENTOMEDIDOR: "Agendar Medição",
        AGENDAMENTOENTREGA: "Agendas",
        MENSAGEMSEMGUIAATENDIMENTO: "Este agendamento não possui guia de atendimento",
        MENSAGEMCONFIRMAVENDA: "Confirmar efetivação da venda ?",
        MENSAGEMCONFIRMACHECKLIST: "Confirmar finalização do checklist ?",
       
        MENSAGEMCONFIRMARCONCLUSAOASSIANTURA: "Finalizar Etapa?",
        MENSAGEMATUALIZARASSIANTURADIGITAL: "Deseja atualizar situação das assinaturas?",
        MENSAGEMCONFIRMARFATURAMENTOASSISTENCIA: "Confirma faturamento da assistência técnica? Com o faturamento concluído será gerado uma tarefa para Entrega",
        MENSAGEMCONFIRMARFECHARASSISTENCIA: "Confirma fechamento da assistência técnica?  Após o procedimento conferir se no atendimento principal a assistência tambem foi finalizada",
        MENSAGEMCONFIRMAALTERACAOPROJETO: "Confirma alteração no projeto?",        
        VENDA: "Venda",
        ALTERACAO: "Alteração",
        DESISTENCIA: "Desistência",
        EMESPERA: "Em Espera",
        PRIORIDADEBAIXA: "Prioridade Baixa",
        PRIORIDADEMEDIA: "Prioridade Média",
        PRIORIDADEALTA: "Prioridade Alta",
        PRAZOVENDEDOROK: "Projeto Confirmado",
        PRAZOVENDEDORPENDENTE: "Projeto em Andamento",
                
    },   
    
    AGENDA: {

        MENSAGEMREAGENDAMENTOBOTAO: "Cancelar Reagendamento?",
        MENSAGEMREAGENDAMENTO: "Você esta realizando um agendamento",
        MENSAGEMREAGENDAMENTOEVENTO: "Agendamento para cliente {0}",
        PROXIMODIA: "Próximo dia desta agenda",
        DIAANTERIOR: "Dia anterior desta agenda",         
        AGENDA: "Agenda",
        MENSAL: "Mensal",
        DIARIO: "Diário",
        SEMANAL: "Semanal",
        INCLUIRCLIENTE: "Incluir Cliente",
        BLOQUEARHORARIO: "Bloquear Horário",
        DESBLOQUEARHORARIO: "Desbloquear Horário",        
        HORARIOLIVRE: "Horário Livre",        
        HORARIOBLOQUEADO: "Horário Bloqueado",   
        CONFIRMACANCELAMENTO: "Confirma o cancelamento desse agendamento?",  
        CONFIRMACLIENTE: "Confirmar o agendamento?",         
        ERROAOSALVAR: "Erro ao salvar agendamento, por favor, verifique o horário e tente novamente",  
        VERGUIACLIENTE: "Ver atendimentos do cliente",        
        VINCULARATENDIMENTO: "Vincular a um cliente", 
        CONFIRMAVINCULARATENDIMENTO: "Confirmar vinculo para cliente?", 
        ADICIONAREVENTO: "Adicionar Evento",
        ESCALASNAOCADASTRADAS: "Não existem horários livres para este dia!",
        CLIENTENAOCONFIRMADO: "Cliente Não Confirmado!",
        CLIENTECONFIRMADO: "Cliente Confirmado!",
        CLIENTEENCAIXE: "Encaixe!",
        ERROSALVAR: "Erro ao salvar",
        NAOAGENDADO: "Não Agendado",
        HORARIORESERVADO: "Horário Reservado", 
        NAOEXISTEHORARIOLIVRE: "Não existem horário livres para realizar agendamentos",        
        OBSERVACOESGUIA: "Observações da guia",
        ENCAIXE:"Encaixe",
    },    
    MOTIVOSLISTA: {
        DESCRICAO: "Descrição",        
        MOTIVOS: "Motivos",
    },
    MOTIVOCADASTRO: {
        DESCRICAO: "Descrição Interna",        
        DESTINO: "Destinos",
        ENVIAEMAIL: "Envia E-mail",        
        MOTIVOS: "Motivos",
        ERROSALVAR: "Erro ao salvar motivo: ",
    },   
    AGENDAMENTO: {    
        INTEGRARAGENDA: "Integrar Agenda?",
        MENSAGEMFECHAR: "Fechar mensagem",
        DATAHORA: "Data e hora",
        SELECIONARHORA: "Selecione o horário",        
        TIPOAGENDAMENTO: "Agenda",        
        FUNCIONARIORESPONSAVEL: "Responsável",
        TIPOAGENDA: "Tipo de agendamento",
        MENSAGEMTIPOAGENDA: "Tipo de agendamento precisa ser informado",
        MENSAGEMCLIENTE: "O Cliente precisa ser informado",
        MENSAGEMTIPOAGENDAMENTO: "A agenda precisa ser informado",
        MENSAGEMDATAHORA: "A data/hora precisa ser informado",                                 
        MENSAGEMHORA: "A hora precisa ser informada", 
        MENSAGEMRESPONSAVEL: "O responsável precisa ser informado", 
        MENSAGEMCAMPOSOBRIGATORIOVISTTORIA: "Atenção! Para realizar a próxima vistoria é necessário anexar o documento de 'Não Conformidades' ",
        MENSAGEMCONFIRMAFOTOSREDE : "Fotos estão na rede ?",                                
        MENSAGEMOBRIGATORIOANEXO : "Favor anexar o documento de 'Não Conformidades'",
        MENSAGEMOBRIGATORIOCONFIRMAFOTOSREDE : "Confirme se as fotos estão na rede",
        SEMRELATORIO: "Sem relatório?",
        SEMRELATORIOMOTIVO: "Preencha o motivo"
    }, 
    MODALALTERACAOAGENDA: {
        ALTERARAGENDA: "Alterar agendamento",        
        AVISOALTERACAO: "Este procedimento irá alterar a data do agendamento",        
        MOTIVOS: "Motivos",
        CONFIRMARALTERACAO: "Confirmar alteração",        
    },
    MODALOBRANAOCONFORMEPOSVENDA: {
        OBRANAOCONFORME: "Obra em Não Conformidade",    
        AVISO: "Este procedimento irá encaminhar o projeto para etapa de 'Obra em Não Conformidade'",                                         
    },      
    MODALISTARDETALHESMENSAGEM:{
        DETALHES: "Detalhes da Mensagem"
    },
    MODALOBRANAOCONFORMEVENDEDORPOSVENDA: {
        OBRANAOCONFORME: "Alteração Executivo",    
        MENSAGEMANEXOS : "Anexar Documentos - Opcional",
        AVISO: "Este procedimento irá encaminhar o projeto para etapa de 'Obra em Não Conformidade'",                                         
    },  
    MODALINCLUIRPENDENCIAVISTORIAPOSVENDA: {
        INCLUIRPENDENCIA: "Incluir Pendência",    
        MENSAGEMANEXOS : "Anexar Fotos",                                      
    },               
    MODALCANCELANDOAGENDA: {
        CANCELARAGENDA: "Cancelar agendamento",        
        AVISOCANCELAMENTO: "Este procedimento irá cancelar o agendamento",        
        MOTIVOS: "Motivos",
        CONFIRMARCANCELAMENTO: "Confirmar cancelamento",                
    }, 
    MODALCANCELANDOGUIA: {
        CANCELARGUIA: "Cancelar Cliente",        
        AVISOCANCELAMENTO: "Este procedimento irá cancelar TODO o processo do cliente",        
        MOTIVOS: "Motivos",
        CONFIRMARCANCELAMENTO: "Confirmar cancelamento",                
    },  
    MODALTROCARVENDEDORGUIA: {
        NOVOVENDEDOR: "Novo Vendedor",
        TROCARVENDEDORGUIA: "Troca de Vendedor ",        
        AVISOTROCAVENDEDOR: "Este procedimento irá alterar o vendedor do atendimento",        
        MOTIVOS: "Motivos",                      
    },      
    MODALURLEXTERNAANEXO: {        
        URLEXTERNA:"Adicionar URL Externa",   
        URLEXTERNAEDITAR:"Editar URL Externa",   
        URL: "Url"                            
    },      
    MODALOBSERVACAOGUIA: {
        OBSERVACAO: "Incluir Observação",             
        NOTIFICARENVOLVIDOS: "Notificar todos envolvidos?",                   
    },  
    MODALREATIVARGUIA: {        
        MENSAGEMCONFIRMAREATIVAMENTO: "Confirma reativação do atendimento?",                               
    }, 
    MODALREMOVECONFIRMARPRAZOVENDEDOR: {        
        MENSAGEM: "Remover confirmação do projeto do vendedor?",                               
    },     
      
    MODALCONCLUIRVISTORIAMEDICAO: {        
        AVISO: "Concluir Vistoria?",                               
    }, 
    MODALSOLICITARVISTORIAMEDICAO: {                
        AVISO: "Solicitar Vistoria?", 
    },   
    MODALCONCLUIRPLANTASENTREGAFUTURA: {                
        AVISO: "Plantas Concluída?", 
    },    
    MODALTRANSFORMAENTREGAFUTURA: {                
        AVISO: "Entrega Futura?", 
    },   
    MODALTRANSFORMAENTREGAFUTURAPLANTAS: {                
        AVISO: "Entrega Futura com Plantas?", 
    },                
    MODALDEVOLVERCONFERENTEVISTORIAMEDICAO: {        
        AVISO: "Devolver para Conferente?",                                                             
    },                          
    MODALEMESPERA: {
        EMESPERAGUIA: "Atendimento em Espera",        
        AVISOEMESPERA: "Este procedimento irá colocar o atendimento em espera",        
        MOTIVOS: "Motivos",
        PROXIMOCONTATO: "Próximo Contato"        
    }, 
    MODALPRAZOENTREGAELETRO: {
        PRAZOENTREGAELETRO: "Definir prazo de entrega eletro",                
        MOTIVOS: "Motivos",
        NOVOPRAZO: "Novo Prazo"        
    },   
    MODALEDITARANEXO: {
        PRAZOENTREGAELETRO: "Definir prazo de entrega eletro",  
        AVISO: "Alterar tipo do anexo"                    
    },    
    MODALPREENCHERTIPOANEXO: {        
        AVISO: "Tipo do Documento é obrigatório"                    
    },          
    MODALMEDICAOEMESPERA: {
        MEDICAOEMESPERA: "Definir prazo de medição",    
        AVISO: "Este procedimento colocar a venda em aguardando medição",                    
        MOTIVOS: "Motivos",
        NOVOPRAZO: "Aguardar Até?"        
    },    
    MODALPRAZOASSINATURA: {
        PRAZOASSINATURA: "Definir prazo para assinatura",    
        AVISO: "Este procedimento colocar a venda em aguardando assinatura",                    
        MOTIVOS: "Motivos",
        NOVOPRAZO: "Prazo Até?"        
    },     
    MODALINTEGRACAOELIST: {
        ATUALIZARCLIENTES: "Atualizar clientes do EList",    
        AVISO: "Este procedimento realiza consulta de novos clientes no Efinance e realiza importação",                            
        DATACADASTRO: "Data de Cadastro (no Efinance)",
        MENSAGEMSELECIONECLIENTES: "Selecione ao menos um cliente para ser importar",          
        MENSAGEMPERIODOPERMITIDO: "Selecione um período de até 7 dias",  
    },        
    MODALPRAZOINSTALACAO: {
        PRAZO: "Definir prazo para instalação",    
        AVISO: "Este procedimento vai colocar prazo para ser concluído a instalação",                            
        NOVOPRAZO: "Data prevista de término"        
    },  
    MODALINSTALACAOPAUSADA: {
        PAUSA: "Pausar instalação",
        DATAPREVISTA: "Data prevista",    
        LIBERAR: "Liberar instalação",
        AVISO: "Este procedimento vai colocar a instalação em pause",                                    
        AVISOLIBERAR: "Este procedimento vai liberar a instalação",                                    
    },   
    MODALCONFIRMARPROJETOVENDEDOR:{
        CONFIRMARPROJETO: "Confirmar projeto?",
        AVISO: "Vendedor deve confirmar que finalizou o projeto do cliente e definir um prazo para apresentação",                                    
        DATAPREVISTA: "Previsão de apresentação",    
    },  
    MODALALTERARPRAZOAPRESENTACAO:{
        PRAZOAPRESENTACAO: "Alterar prazo apresentação",
        AVISO: "Este procedimento vai alterar o prazo de apresentação do projeto para o cliente",                                    
        DATAPREVISTA: "Previsão de apresentação",    
    },     
    MODALALTERARPRAZOOBRANAOCONFORME:{
        NOTIFICARENVOLVIDOS: "Notificar todos envolvidos?",
        PRAZOAPRESENTACAO: "Alterar prazo",
        AVISO: "Este procedimento vai alterar o prazo da obra em não conformidade",                                    
        DATAPREVISTA: "Novo prazo?",    
    },     
    MODALALTERARPRAZOCONFERENTE:{
        NOTIFICARENVOLVIDOS: "Notificar todos envolvidos?",
        PRAZOAPRESENTACAO: "Alterar prazo conferente",
        AVISO: "Este procedimento vai alterar o prazo da conferência",                                    
        DATAPREVISTA: "Novo prazo?",    
    },         
    MODALALTERARPRAZOFLOWALL:{        
        PRAZOAPRESENTACAO: "Alterar prazo flowall",
        AVISO: "Este procedimento vai alterar o prazo",                                    
        DATAPREVISTA: "Novo prazo?",    
    },    
    MODALALTERACAOPROJETO:{
        AMBIENTES: "Ambientes medidos",
        ALTERACAOPROJETO: "Alteração de projeto",
        AVISO: "Este procedimento vai definir o cliente como alteração de projeto",                                            
        MENSAGEMSELECIONE:"Seleciona ao menos um ambiente para ser alterado",
        CAMPOPRIORIDADEOBRIGATORIO: "Preencha o campo temperatura",
        CAMPOPRAZOOBRIGATORIO: "Preencha o campo prazo do vendedor"
    },         
    MODALPRAZOENTREGAFUTURA: {
        PRAZOASSINATURA: "Definir novo prazo ",    
        AVISO: "Este procedimento definirá um novo prazo para entrega futura ",                    
        MOTIVOS: "Motivos",
        NOVOPRAZO: "Prazo Até?"        
    },    
    MODALPRIMEIROCONTATOPOSVENDA: {
        PRIMEIROCONTATO: "Definir data para primeiro contato ",    
        AVISO: "Este procedimento definirá um prazo para o primeiro contato com cliente ",                            
        PRAZOPRIMEIROCONTATO: "Prazo?"        
    }, 
    MODALPREVISAOINSTALACAOPOSVENDA: {
        PRIMEIROCONTATO: "Alterar previsão para instalação",    
        AVISO: "Este procedimento definirá um data de previsão para instalação",                            
        PRAZOPRIMEIROCONTATO: "Prazo?"        
    },   
    MODALALTERARVALORPREVISTO: {
        ALTERARVALORPREVISTO: "Alterar valor previsto",    
        AVISO: "Este procedimento definirá um valor previsto para projeto",                            
        VALORPREVISTO: "Valor previsto?"        
    },  
    MODALALTERARTEMPERATURA: {
        AVISO: "Alterar temperatura",                              
        ALTERARTEMPERATURA: "Novo valor"        
    },  
    MODALVERSTATUSATUALGUIA:{
        VERSTATUS: "Ver status atual",
    },        
    MODALPREVISAOENTREGAOPOSVENDA: {
        PRIMEIROCONTATO: "Alterar previsão para entrega",    
        AVISO: "Este procedimento definirá um data de previsão para entrega",                            
        PRAZOPRIMEIROCONTATO: "Prazo?"        
    },              
    MODALCADASTROASSISTENCIA: {
        CADASTROASSISTENCIA: "Cadastro de Assistências",                                      
        PRAZO: "Prazo",        
        CONCLUIDO: "Assistência Concluída?",
        VOLTARASSISTENCIA: "Devolver ao Início?",
        MENSAGEMVOLTARASSISTENCIA: "Deseja voltar a assistência para o início do processo?",
        ENVIARFABRICA: "Enviar Fabrica Concluído?",
        FINALIZAREFINANCE: "Efinance Concluído?",
        FINALIZARFINANCEIRO: "Finalizar Financeiro?",   
        FECHARASSISTENCIA: "Fechar Assistência?",
        TITULO: "Título",        
        DESCRICAO: "Descrição",
        CUSTO:"Custo de",    
        MOTIVO:"Motivo",            
        CONFERENTE: "Conferente",
        ASSISTENCIAFINALIZADA: "Assistência Finalizada",
        ASSISTENCIAEXCLUIDA: "Assistência Excluída",
        DEVOLVERSUPERVISOR: "Devolver Supervisor?",
        INFORMACOESPENDENTESRESOLVIDAS: "Informações Pendentes Resolvidas?",
        MENSAGEMDEVOLVERSUPERVISOR: "Deseja enviar uma notificação de email para o supervisor revisar os dados da assistência?",
        MENSAGEMINFORMACOESPENDENTESRESOLVIDAS: "As informações pendentes foram  resolvidas?",
        MENSAGEMCUSTOOBRIGATORIO: "Para listar motivos, seleciona primeiro custo",
        
        
    },   
    MODALFINALIZARINSTALACAO: {
        FINALIZARINSTALACAO: "Documento Obrigatórios",  
        MENSAGEMFINALIZARINSTALACAOANEXO : "Favor anexar o 'Certificado de Garantia'",                                                                            
        MENSAGEMCAMPOSOBRIGATORIOFINALIZARINSTALACAO: "Atenção! Favor anexar o 'Certificado de Garantia'",
    },  
    MODALCONFERENCIACONCLUIDAVENDA: {
        CONFERENCIACONCLUIDA: "Documentos Obrigatórios",  
        MENSAGEMCONFERENCIACONCLUIDA : "Favor anexar a 'Ata da reunião'",
        RESERVA: "Reserva?",
        CAMPORESERVAOBRIGATORIO: "Favor preencher o campo 'Reserva'"                                                                                   
    },  
    MODALCONFERENCIACONCLUIDATIPOASSISTENCIAVENDA: {
        PLANTAS: "Possui plantas de instalação?",
        CONFERENCIACONCLUIDA: "Conferência Concluída - Tipo Assistência",  
        MENSAGEMCONFERENCIACONCLUIDA : "Favor indicar se a assistência possui plantas de instalação ou não",                                                                                        
    },      
    MODALCONCLUIRELETROANEXOVENDA: {
        ELETROSCONCLUIDO: "Documentos Obrigatórios",  
        ELETROS: "Anexar Eletros",        
        MENSAGEMELETROSCONCLUIDA : "Favor anexar o documento de 'Eletros'"                                                                               
    },       
    MODALCONCLUIRASSINATURAVENDA: {
        CONFERENCIACONCLUIDA: "Documentos Obrigatórios",  
        PROJETOASSINADO: "Anexar Projeto Assinado",
        PROJETOAPROVADO: "Anexar Documento de Aprovação",
        MENSAGEMCONFERENCIACONCLUIDA : "Favor anexar os documentos obrigatórios",                                                                               
    },   
    MODALANEXARPROJETOVENDA: {
        CONFERENCIACONCLUIDA: "Documentos Obrigatórios",  
        ANEXARPROJETO: "Projeto para aprovação do gerente ",
        MENSAGEMCONFERENCIACONCLUIDA : "Favor anexar os documentos obrigatórios",                                                                               
    },       
    
    MODALCONCLUIRASSINATURAGERENTEVENDA: {
        CONFERENCIACONCLUIDA: "Aprovação Gerente Comercial",  
        AVISO: "Este procedimento vai aprovar e liberar para o vendedor seguir com a assinatura",                     
        CONCLUIRASSINATURA: "Aprovação para concluir Assinatura do Descritivo", 
        APROVARCONTRATO: "Aprovação do Contrato?"                                                                              
    },      
    MODALCONCLUIRASSINATURADIGITALVENDA: {
        CONFERENCIACONCLUIDA: "Assinatura Digital",  
        PROJETOASSINADO: "Anexar Documento para Assinar ",        
        MENSAGEMCONFERENCIACONCLUIDA : "O arquivo que será enviado para assinatura digital, esta em anexos com: tipo 'PROJETO'",
        SIGNATARIOS: "Signatários",
        EMAIL: "Email",
        TIPO: "Tipo",
        ASSINATURACOMFOTO: "Assinar com Foto?",
        ADICIONARSIGNATARIO: "Adicionar",       
        MENSAGEMCADASTRESIGNATARIO: "Cadastre ao menos um signatário",   
    },             
    MODALIBERARENTREGAS: {
        LIBERARENTREGAS: "Liberar Entregas",  
        MENSAGEMCONFERIRENTREGAS : "Favor conferir a liberação das entregas, após confirmar os atendimentos serão atualizados para etapa 'Para Instalação'",                                                                            
        MENSAGEMCONFIRMARENTREGAS: "Confirmar Entregas?" 
        
    },                           
    MODALMEDICAOPARCIAL: {
        MEDICAOEMESPERA: "Concluir medição parcial",   
        AVISO: "Este procedimento será necessário escolher os ambiente que já tiveram medição concluída",                     
        MOTIVOS: "Motivos",
        NOVOPRAZO: "Aguardar Até?",        
        MENSAGEMSELECIONEAMBIENTESMEDIDOS: "Selecione ao menos um ambiente que a medição foi concluída",        
    },              
    MODALALTERACAOPRAZOVENDEDOR: {
        ALTERACAOPRAZO: "Alterar Prazo do Vendedor",        
        AVISOALTERACAOPRAZO: "Este procedimento irá alterar o prazo do vendedor, é necessário inserir um motivo",        
        MOTIVOS: "Motivos",
        PRAZO: "Novo Prazo",
        MENSAGEMPERIODOPERMITIDO: "Selecione um período de até 20 dias",          
    },  
    MODALJUSTIFICARPRAZOAGENDA: {
        JUSTIFICAR: "Justificar Agendamento",        
        JUSTIFICARMENSAGEM: "Este procedimento realiza justificativa da não conclusão do agendamento ao cliente",        
        MOTIVOS: "Motivos",      
        PROXIMOCONTATO: "Próximo Contato"       
    },       
    MODALALTERACAOPRAZOPROJETISTA: {
        ALTERACAOPRAZO: "Alterar Prazo do Projetista",        
        AVISOALTERACAOPRAZO: "Este procedimento irá alterar o prazo do projetista, é necessário inserir um motivo",        
        MOTIVOS: "Motivos",
        PRAZO: "Novo Prazo"        
    },  
    MODALDOCUMENTOVENDA: {
        MENSAGEMCAMPOSOBRIGATORIOSALTERACAOPROJETO: "Informe a prioridade e prazo do vendedor",
        MENSAGEMSELECIONEAMBIENTESALTERADOS: "Selecione ao menos um ambiente que foi alterado",
        MENSAGEMSELECIONEAMBIENTES: "Selecione ao menos um ambiente que foi vendido",
        PRAZOLIBERACAO: "Data Prevista Liberação",
        PRAZOENTREGA: "Prazo de Entrega",  
        ALTERACAOFINANCEIRA: "Alteração Financeira",
        PLANTAS: "Plantas",
        COBRANCA: "Cobrança",
        VALORELIST: "Valor Elist",      
        VALORPRAZO: "Valor Prazo",
        VALORVISTA: "Valor a Vista",
        VALORVISTASEM: "Valor a Vista Sem Indic",
        VALORPERCENTUAL:"Especificador",
        VALORREAL:"Especificador",
        ASSOCIACAOPERCENTUAL:"Associação",
        ASSOCIACAOREAL:"Associação",
        MENSAGEMDATAENTREGA: "Prazo de Entrega obrigatorio",
        MENSAGEMPOSSUIALTERACAO: "Especifique se o projeto possui alteração ou não",
        MENSAGEMDATAPRAZOLIBERACAO: "Prazo de Liberação obrigatório",
        MENSAGEMVALORELIST: "Valor Elist obrigatorio",
        MENSAGEMVALORVENDA: "Valor de Venda obrigatorio",
        MENSAGEMPRAZOVENDA: "Prazo é obrigatorio",
        CAPTADOR: "Captador",
        ENTREGAFUTURA: "Entrega",
        MENSAGEMCAPTADOR: "Possui Captador?",
        MENSAGEMENTREGAFUTURA: "Entrega Futura?",
        POSSUIALTERACAO: "Projeto com alteração?"

    },  
    MODALLISTARCONTRATOS:{
        LISTARCONTRATOS: "Listar Contratos",
        CONTRATOS: "Selecione o Contrato",                      
        MENSAGEMSELECIONECONTRATO: "Selecione ao menos um contrato",  
        AVISO: "Selecione o contrato para auto preencher a afetivação da venda"  
    },
    MODALLISTARARQUITETOS:{
        LISTARINDICADORES: "Listar Arquitetos",
        ARQUITETOS: "Selecione o Arquiteto",                      
        MENSAGEMSELECIONECONTRATO: "Selecione ao menos um arquiteto",  
        AVISO: "Selecione o indicador referente ao arquiteto"  
    },    
    MODALLISTARASSISTENCIAS:{
        ASSISTENCIAS: "Assistências",
        AVISO: "Seleciona quais assistências deseja agendar",
        MENSAGEMSELECIONEASSISTENCIA: "Selecione ao menos uma assistência",     
        AGENDAMENTO: "Agendamento"    
    },   
    MODALLISTARPECAS:{
        AMBIENTES: "Selecione o Ambiente",
        PECAS: "Seleciona a Peça",
        LISTARAMBIENTES:  "Selecionar Peça",        
        MENSAGEMSELECIONEPECA: "Selecione ao menos uma peça",  
        AVISO: "Primeiro selecione o ambiente e depois a peça"        
    }, 
    MODALLISTARMOTIVOS:{
        MOTIVOS: "Selecione o Motivo",
        MENSAGEMSELECIONEMOTIVO: "Selecione ao menos um motivo", 
        CUSTOSEMNOTIVOS: "Não existem motivos cadastrados para este custo!"         
    },          
    MODALLISTARAMBIENTES:{
        AMBIENTES: "Ambientes",
        LISTARAMBIENTES:  "Todos ambientes do Elist",
        AVISO: "Selecione manualmente os ambientes para vincular no atendimento do cliente",
        MENSAGEMSELECIONEAMBIENTES: "Selecione ao menos um ambiente",  
        FUNCIONARIOS: "Funcionários"                    
    },
    MODALLISTARFUNCIONARIOS:{
        LISTARFUNCIONARIOS:  "Todos funcionários desta unidade no Elist",
        AVISO: "Selecione manualmente os funcionários para importar o cadastro",
        MENSAGEMSELECIONEFUNCIOANRIOS: "Selecione ao menos um funcionario",                      
    },    
    MODALISTARPOSICS:{
        FATURARPARCIAL:"Faturar Parcial",
        LISTARPOSICS:  "Posics do Atendimento", 
        LISTARPOSICSASSISTENCIA:  "Posics da Assistência", 
        MENSAGEMPOSICSOBRIGATORIOS: "Selecione ao menos um ambiente",
        MENSAGEMCONFIRMARPOSICS: "Confirma liberação parcial?",
        MENSAGEMAPENASMBIENTESFATURADOS: "Seleciona apenas ambientes faturados"

    },    
    MODALCHECKLISTVENDA: {
        CHECKPLANTASTECNICAS: "Plantas técnicas",
        CHECKPLANTASINSTALACAOCLIENTE: "Plantas de instalação para cliente",
        CHECKPLANTASINSTALACAOIMPRESSAO: "Plantas de instalação para impressão",
        CHECKCONSENSO: "Consenso (caso precisar)",
        CHECKARQUIVOSREDE: "Arquivos na rede",
        PRAZOCONSENSO: "Prazo consenso"
    },             
    MODALDEVOLUCAOPROJETISTAGUIA: {
        CANCELARGUIA: "Devolução do Atendimento para",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver o atendimento para o gerente comercial",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    },  
    MODALDEVOLUCAOEFETIVACAOVENDA: {
        DEVOLUCAO: "Devolução da Efetivação",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver a efetivação para o vendedor",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    },
    MODALDEVOLUCAOFINANCEIROVENDA: {
        DEVOLUCAO: "Devolução da Efetivação para Financeiro",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver a efetivação para o gerente",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    },
    MODALDEVOLUCAOASSINATURAVENDA: {
        DEVOLUCAO: "Devolução da Efetivação para Assinatura",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver a efetivação para a etapa de assinatura",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    }, 
    MODALCONFERENCIACONCLUIDAPARCIALVENDA: {
        MENSAGEMCONFERENCIACONCLUIDA : "Favor anexar a 'Ata da reunião'",
        RESERVA: "Reserva/Observação",
        DEVOLUCAO: "Conferência Concluída Parcialmente",        
        AVISOCANCELAMENTO: "Este procedimento irá concluir a conferência parcialmente e devolver atendimento princpal para etapa anterior",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar",  
        DEVOLUCAOPARAETAPA: "Enviar atendimento para qual etapa?",
        ETAPAASSINATURA: "Assinatura",
        ETAPAMEDICAO: "Medição",
        ETAPAPRODUCAO: "Enviar Produção",
        MENSAGEMSELECIONEAMBIENTESCONFERIDOS: "Selecione ao menos um ambiente que a conferência foi concluída",  
        AMBIENTES: "Selecionar Ambientes Já Conferidos"                    
    },  
    
    MODALALTERARSTATUSGUIA: {
        ALTERACAOSTATUS: "Alterar Status",        
        AVISO: "Este procedimento alterar a etapa atual do atendimento",                
        CONFIRMARDEVOLUCAO: "Confirmar",     
        FLUXO: "Fluxo", 
        ETAPA: "Etapa",
        MENSAGEMETAPAOBRIGATORIA: "Seleciona a etapa"        
    },    
    MODALUNIFICARGUIA: {
        ATENDIMENTOPRINCIPAL: "Atendimento Principal",
        ATENDIMENTOVINCULADO: "Atendimento Vinculado(s)",
        UNIFICARATENDIMENTO: "Unificar Atendimentos",        
        AVISO: "Este procedimento realiza a unificação dos atendimentos vinculados ao principal",                
        CONFIRMARDEVOLUCAO: "Confirmar",
        MENSAGEMUNIFICARATENDIMENTOS:"Selecione ao menos um atendimento para ser unificado",
    },          
    MODALFATURAMENTOPOSVENDA: {
        FATURAMENTO: "Faturamento",        
        AVISO: "O faturamento poderá ser escolhido a opção 'Total' ou a opção 'Parcial', na opção 'Parcial' deve-se atualizar os elists ",                        
        FATURAMENTOTIPO: "Tipo de faturamento?",
        FATURAMENTOTOTAL: "Total",
        FATURAMENTOPARCIAL: "Parcial",
        ELISTATENDIMENTOPRINCIPAL: "Preencher Elists NÃO faturados",
        ELISTATENDIMENTONOVO: "Preencher Elists faturados",
        ELISTNOVOOBRIGATORIO: "Necessário preencher os elist do faturamento parcial",
        MENSAGEMSELECIONEAMBIENTESFATURADOS: "Selecione ao menos um ambiente que o faturamento foi concluída",                      

    },  
    
    MODALVISTORIAPARCIALPOSVENDA: {
        VISTORIAPARCIAL: "Vistoria parcial",        
        AVISO: "A Vistoria Parcial deve-se informar os ambientes que ja foram vistoriados e liberados",                        
        AMBIENTESLIBERADOS: "Ambientes Liberados",
        AMBIENTESNAOLIBERADOS: "Ambientes NÃO Liberados",
        CAMPOSOBRIGATORIOS: "Necessário preencher todos os campos",
        MENSAGEMSELECIONEAMBIENTESVISTORIADOS: "Selecione ao menos um ambiente que a vistoria foi concluída",                                             
    },     
    MODALENTREGAPARCIALPOSVENDA: {
        ENTREGAPARCIAL: "Entrega parcial",        
        AVISO: "A Entrega Parcial deve-se informar os ambientes Liberados para entrega ",                        
        AMBIENTESLIBERADOS: "Ambientes Liberados",
        AMBIENTESNAOLIBERADOS: "Ambientes NÃO Liberados",
        CAMPOSOBRIGATORIOS: "Necessário preencher todos os campos",
        MENSAGEMSELECIONEAMBIENTESLIBERADOS: "Selecione ao menos um ambiente que deve ser liberado para entrega",                                                                    
    },         
    MODALDEVOLUCAOELETROSVENDA: {
        DEVOLUCAO: "Devolução da Efetivação para Eletros",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver a efetivação para a etapa de eletros",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    },    
    MODALDEVOLUCAOGERENTECONFERENCIAVENDA: {
        DEVOLUCAO: "Devolução da Efetivação para Gerente de Conferência",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver a efetivação para a etapa de conferência em espera",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    }, 
    MODALDEVOLUCAOMEDIDORVENDA: {
        DEVOLUCAO: "Devolução da Efetivação para o Medidor",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver a efetivação para a etapa de medição",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    },   
    MODALDEVOLUCAOPLANTASVENDA: {
        DEVOLUCAO: "Devolução para Etapa de Plantas ",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver o atendimento para etapa anterior em Plantas",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    }, 
    MODALDEVOLUCAOPLANTASIMPRESSAOVENDA: {
        ALTERACAOPLANTAINSTALACAO: "Alteração Planta de Instalação",
        ALTERACAOPLANTATECNICA: "Alteração Planta Técnica",
        DEVOLUCAO: "Devolução para Etapa de Plantas Impressão",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver o atendimento para etapa anterior em Plantas Impressão",                
        CONFIRMARDEVOLUCAO: "Confirmar devolução",           
        MENSAGEMALTERACAOPLANTAINSTALACAO : "Favor anexar o documento de 'Planta de Instalação'",                                                                               
        MENSAGEMALTERACAOPLANTATECNICA : "Favor anexar o documento de 'Planta Técnica'"                                                                               
    },     
    MODALINSERIRCLIENTENOVOETAPA: {
        NOVOCLIENTE: "Novo cliente",
        AVISO: "Inserir Novo Cliente",        
        AVISOETAPAINSTALACAOFUTURA: "Este procedimento irá criar um novo atendimento, e será iniciado na etapa de Instalação Futura",        
        AVISOETAPAINSTALACAOINCOMPLETA: "Este procedimento irá criar um novo atendimento, e será iniciado na etapa de Instalação Incompleta",        
    },     
    MODALDEVOLUCAOINSTALACAOFUTURAPOSVENDA: {
        DEVOLUCAO: "Devolução para Instalação Futura",        
        AVISOCANCELAMENTO: "Este procedimento irá devolver o atendimento para etapa anterior em  Instalação Futura",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",               
    },           
    MODALINSERIRENTREGAFUTURA: {
        PRAZO: "Prazo",
        ENTREGAFUTURA: "Inserir data futura de entrega",        
        AVISO: "Este procedimento irá inserir a venda na situação de data futura",        
        MOTIVOS: "Motivos",
        CONFIRMARDEVOLUCAO: "Confirmar devolução",                
    },                        
    MODALREMOVERDATAENTREGAFUTURAVENDA: {
        REMOVER: "Liberar para produção",        
        AVISO: "Este procedimento irá remover a venda da situação de data futura",        
        MOTIVOS: "Motivos",
        CONFIRMAR: "Confirmar",                
    },   
    MODALREMOVERDATAENTREGAFUTURAPLANTASVENDA: {
        REMOVER: "Plantas Técnicas",        
        AVISO: "Este procedimento irá remover a venda da situação de data futura",        
        MOTIVOS: "Motivos",
        CONFIRMAR: "Confirmar",                
    },                     
    MODALNOTIFICACAO: {
        TITULO: "Nenhuma Notificação | Você tem 1 Notificação | Você tem {n} Notificações",
        TITULOMENSAGEM: "Nenhuma Mensagem | Você tem 1 Mensagem | Você tem {n} Mensagens",
        FECHAR: "Fechar",
        APAGARTODAS: "Apagar Notificações",
        MARCARTODAS: "Marcar como Lidas",
        APAGAR: "Apagar esta notificação",
        MARCARLIDA: "Marcar como lida",
        MENSAGEMLEMBRETEDEVEMSERLIDAS: "Para CONTINUAR é necessário ler e confirmar a leitura das mensagens abaixo!"        
    },    
           
    AGENDARELACAO: {
        SELECIONEPERIODO: "Selecione o Período",
        FILTRAR: "Filtrar",
        FILTRARRESPONSAVEL: "Filtrar por funcionário",
        FILTRARAGENDAMENTO: "Filtrar por agenda",
        FILTRARSTATUS: "Filtrar por status",        
        TIPOAGENDAMENTO: "Agenda" ,        
        FONE: "Fone",        
        FUNCIONARIORESPONSAVEL: "Funcionário Responsável",        
        RELACAOAGENDAMENTO: "Relação de Agendamento",        
        BUSCACLIENTE: "Buscar Cliente",        
        ACOES: "Ações",
        AGENDAMENTOCANCELADO: "Agendamento Cancelado"
    },   
    ATENDENTE:{
        ENTRADA: "Data de Entrada",
        ATENDENTE: "Atendente",
        SUPERVISOR: "Supervisor",
        TROCARATENDENTE: "Trocar Atendente",
        VENDEDOR: "Vendedor",
        GERENTE: "Gerente"

    },    
    GUIAATENDIMENTO: {
        CONCLUIRPENDENCIA: "Pendência Resolvida?",
        CONCLUIRVISTORIAMBIENTES: "Concluir todos os ambientes?",
        MONTAGEMEXTRA: "Montador Extra?",
        REMOVERCONCLUIRPENDENCIA: "Pendência Não Resolvida?",
        EXCLUIRPENDENCIA: "Excluir Pendência?",
        MENSAGEMCONFIRMAEXCLUSAOPENDENCIA: "Confirma exclusão da Pendência?",
        MENSAGEMCONFIRMACONCLUSAOAMBIENTES: "Confirma conclusão da vistoria em todos ambientes?",
        MENSAGEMCONFIRMAMONTADOREXTRA: "Finalizar montagem com montadador extra?",
        PENDENCIAS: "Pendências de Vistoria",
        DETALHESVISTORIA: "Detalhes da Vistoria",
        CONTRATO: "Contrato",
        ATUALIZARCONTRATO: "Atualizar Código do Contrato",
        TEMPERATURA: "Temperatura",
        OBRANAOCONFORME: "Obra em Não Conformidade?",
        CLIENTES: "Clientes",
        AMBIENTES: "Ambientes",
        PRAZOMENSAGEM:"Definir prazo",
        AGENDAMENTOMENSAGEM:"Definir agendamento",
        AGENDAMENTOMENSAGEMTODOS:"Definir agendamento para todos",
        MENSAGEMCONFIRMAEXCLUSAOASSISTENCIA: "Confirma exclusão da assistência?",
        MENSAGEMCONFIRMAEXCLUSAOAMBIENTE: "Confirma exclusão do ambiente?",
        TROCARVENDEDOR: "Trocar vendedor?",
        INCLUIROBSERVACAO: "Incluir Observação",
        ELIST: "Elists",
        LETRA: "Letra",
        ELISTBRIGATORIO:"Informar Elist obrigatório",
        MENSAGEMELISTBRIGATORIOO: "É necessários informar o elist",  
        IDENTIFICACAO: "Identificação",
        EMITIDA: "Criado em",
        ARQUITETO: "Arquiteto",
        INFOCLIENTE: "Abrir as informações do cliente",
        BUSCARARQUITETOELIST: "Atualizar Arquiteto do Elist",
        ANEXOS:"Anexos", 
        URLEXTERNA:"Adicionar URL Externa", 
        VENDA:"Venda",
        AGENDAR:"Agendar",
        PRIORIDADE: "Prioridade",
        SELECIONEPRIORIDADE: "Selecione a prioridade",
        CLIENTEDESATIVADO: "Não é possível criar uma guia para um cliente desativado.",
        VENDEDOR: "Vendedor",
        PROJETISTA: "Projetista",
        CONFERENTE: "Conferente",
        VENDEDOROBRIGATORIO:"Vendedor obrigatório",
        MENSAGEMVENDEDOROBRIGATORIO: "Especifique um vendedor para continuar.",
        VENDEDORCAMPOSOBRIGATORIO:"Preencha os campos obrigatórios",
        MENSAGEMVENDEDORCAMPOSOBRIGATORIO: "Preencha os campos: temperatura, prazo em dias do vendedor (max 20 dias) e arquiteto.",        
        NENHUMAINFORMACAO: "Nenhuma informação encontrada",
        OBSERVACAOOBRIGATORIO:"Mensagem de Observação obrigatório",
        MENSAGEMOBSERVACAOOBRIGATORIO: "Especifique uma mensagem de observação para continuar.",  
        PROJETOASSINADOBRIGATORIO:"Anexo obrigatório",
        MENSAGEMPROJETOASSINADO: "É necessários anexar o projeto assinado",          
        MENSAGEMFATURADOVISTORIA: "Para concluir é necessário concluir a Vistoria",    
        MENSAGEMFATURADO: "Para liberar é necessário concluir o Faturamento",    
        MENSAGEMDOCUMENTOATA: "É necessários anexar a ata da reunião",          
        MENSAGEMOBSERVACAOSALVA: "Observação salva com sucesso",
        MENSAGEMPENDENCIASALVA: "Pendência salva com sucesso",
        MENSAGEMPENDENCIARESOLVER: "É necessário concluir as pendências de vistoria!",
        AUTORIZACAO: "Autorizar Projeto",
        CONFIRMARAUTORIZACAO: "Autorizar execução do projeto?" ,    
        CONFIRMARSEMAGENDAMENTO: "Confirma atendimento sem o agendamento?" ,  
        CONFIRMARPROJETOOK: "Confirmar Projeto?" ,  
        CONCLUSAOPROJETO: "Concluir Projeto",
        DEVOLVERPROJETO: "Devolver Projeto",
        SEMAGENDAMENTO: "Sem Agendamento",
        MENSAGEMCONFIRMARPROJETO: "Conclusão da execução do projeto?",   
        MENSAGEMDEVOLVERPROJETO: "Deseja devolver projeto para gerente?",   
        GUIACANCELADA: "Atendimento Cancelado" ,
        GUIAVINCULADA: "Vinculado a atendimento principal " ,
        AGENDAMENTOPARAINSTALACAOREALIZADO: "Agendamento para instalação realizado, aguarde até a data" ,
        NOTIFICARGUIA: "Notificar Guia por Email",
        CANCELARATENDIMENTO: "Cancelar Cliente",
        REATIVARATENDIMENTO: "Reativar Atendimento",
        PRAZOCONCLUSAOPROJETO: "Prazo do projeto",
        PRAZOCONCLUSAOCONFERENCIA: "Prazo da conferência",
        PRAZOCONCLUSAOVENDEDOR: "Prazo do vendedor",
        PRAZOPARACONCLUSAOOBRIGATORIO:"Prazo de conclusão do projeto obrigatório",
        MENSAGEMPRAZOPARACONCLUSAOOBRIGATORIO: "Especifique um prazo em dias de conclusão do projeto",        
        PROJETOCONFIRMADOOBRIGATORIO:"Confirmação do projeto",
        MENSAGEMPROJETOCONFIRMADOOBRIGATORIO: "Para dar andamento no processo o vendedor deve primeiro confirmar o projeto",                
        PRAZOPARACONCLUSAOCONFERENCIAOBRIGATORIO:"Prazo de conclusão da conferência obrigatório",
        MENSAGEMPRAZOPARACONCLUSAOCONFERENCIAOBRIGATORIO: "Especifique um prazo em dias de conclusão da conferência",                
        NUMEROAMBIENTES: "Ambientes",   
        DOCUMENTOS: "Documentos",     
        LIBERACAOENTREGA: "Liberação de Entrega",
        ASSISTENCIASPENDENCIA: "Acompanhamento Assistências",
        RESUMOVENDA: "Resumo de Venda",
        CHECKLISTVENDA: "Checklist de Venda",
        VALIDACAOVENDA: "Validar Efetivação",
        FINANCEIROVENDA: "Aprovar Financeiro",
        ELETROSVENDA: "Eletros Concluído?",
        ELETROSVENDAANEXO: "Eletros Concluído com Anexo?",
        PRAZOENTREGAELETRO: "Prazo de entrega?",
        ASSINADOVENDA: "Assinado?",
        ASSINADODIGITALVENDA: "Assinar Digitalmente?",
        ASSINADOGERENTEVENDA: "Aprovação Gerente?",
        UPLOADPROJETO: "Anexar Projeto para Aprovação?",
        PRAZOASSINATURA: "Prazo de Assinatura?",
        MEDIDORTOTALVENDA: "Medição Total Concluída?",
        MEDIDORPARCIALVENDA: "Medição Parcial Concluída?",
        MEDIDORAGUARDAR: "Aguardar Medição",
        CONFERENCIAAUTORIZADAVENDA: "Autorizado?",
        CONFERENCIACONCLUIDAVENDA: "Concluído?",
        CONFERENCIACONCLUIDAPARCIALVENDA: "Concluído Parcial?",
        ENVIOPRODUCAOEVENDA: "Concluído?",
        DEVOLVERCONFERENCIA: "Devolver Conferência?",
        FINALIZAREFINANCEVENDA: "Concluído?",
        PLANTASVENDA: "Concluído?",
        REMOVERDATAENTREGAFUTURA: "Liberar para produção",
        VISTORIAMEDICAOCONCLUIDA: "Vistoria Concluída?",
        DEVOLVERCONFERENTEVISTORIA: "Devolver para Conferente?",
        REMOVERDATAENTREGAFUTURAPLANTAS: "Plantas Técnicas",
        SOLICITARVISTORIA: "Solicitar Vistoria?",
        REVISAOVENDA: "Revisado",
        DEVOLVEREFETIVACAO: "Devolver para Vendedor",     
        ALTERACAOPROJETOVENDA: "Alteração de Projeto?",  
        DEVOLVERGERENTE: "Devolver para Gerente", 
        DEVOLVERASSINATURA: "Devolver para Assinatura", 
        TRANSFORMAENTREGAFUTURA: "Entrega Futura?", 
        TRANSFORMAENTREGAFUTURAPLANTAS: "Entrega Futura com Plantas?", 
        DEVOLVERELETROS: "Devolver para Eletros", 
        DEVOLVERMEDIDOR: "Devolver para Medidor", 
        DEVOLVERPLANTAS: "Devolver etapa Plantas", 
        DEVOLVERPLANTASIMPRESSAO: "Devolver Plantas Impressão?", 
        DEVOLVERGERENTECONFERENCIA: "Devolver para Gerente de Conferência", 
        MENSAGEMVALIDARVENDA: "Confirma validação da venda?",   
        MENSAGEMREVISAOVENDA: "Confirma revisão da venda?",
        MENSAGEMFINANCEIROVENDA: "Confirma aprovação do financeiro na venda?", 
        MENSAGEMELETROSVENDA: "Confirma conclusão dos eletros?",         
        MENSAGEMASSINADOVENDA: "Confirma assinatura do projeto?",
        MENSAGEMMEDIDORVENDA: "Confirma conclusão da medição?",
        MENSAGEMCONFERENCIAAUTORIZADAVENDA: "Confirma conferência autorizada?",
        MENSAGEMCONFERENCIACONCLUIDAVENDA: "Confirma conferência concluída?",
        MENSAGEMCONFERENCIACONCLUIDAPARCIALVENDA: "Confirma conferência parcialmente concluída?",
        MENSAGEMENVIOPRODUCAOEVENDA: "Confirma envio para produção?",
        MENSAGEMFINALIZAREFINANCEVENDA: "Confirma finalização do efinace?",
        MENSAGEMPLANTASVENDA: "Confirma checklist?",
        MENSAGEMDEVOLVEREFETIVACAO: "Deseja devolver a efetivação para o vendedor?",              
        MENSAGEMDEVOLVERFINANCEIRO: "Deseja devolver a efetivação para o gerente?", 
        MENSAGEMREMOVERDATAENTREGAFUTURA: "Deseja remover data de entrega futura?",              
        MENSAGEMDEVOLVERCONFERENCIA: "Deseja devolver a efetivação para a etapa de conferência?", 
        MENSAGEMDEVOLVERASSINATURA: "Deseja devolver a efetivação para a etapa de assinatura?", 
        MENSAGEMDEVOLVERELETROS: "Deseja devolver a efetivação para a etapa de eletros?", 
        MENSAGEMDEVOLVERMEDIDOR: "Deseja devolver a efetivação para a etapa de medição?", 
        MENSAGEMDEVOLVERPLANTAS: "Deseja devolver para etapa de plantas?", 
        MENSAGEMDEVOLVERGERENTECONFERENCIA: "Deseja devolver a efetivação para o gerente de conferência?",
        MENSAGEMCLIENTEDUPLICADO: "Este cliente ja possui uma negociação ativa, deseja duplicar o atendimento?", 
        CONCLUIROBRASPLANTAS: "Plantas impressa e conferido?",
        ALTERAREXECUTIVO: "Alterar Executivo?",
        CONCLUIRPLANTASTECNICAS: "Verifição Realizada?",
        MENSAGEMOCONCLUIRPLANTASTECNICAS: "Verifição Realizada?",
        FINALIZARENTREGA: "Finalizar Entrega?",
        FINALIZARINSTALACAO: "Instalação concluída com certificado?",
        FINALIZARINSTALACAOSEMCERTIFICADO: "Instalação sem certificado?",
        INSTALACAOFUTURADATAPREVISTA: "Data prevista termino?",
        INSTALACAOPAUSADA: "Pausar Instalação?",
        INSTALACAOLIBERADA: "Liberar Instalação?",
        FINALIZARENVIOFABRICA: "Finalizar Etapa?",
        FINALIZARINSTALACAOFUTURA: "Libera Entrega?",
        FINALIZARINSTALACAOFUTURAPARCIAL: "Libera Entrega Parcial?",
        FINALIZAEFINANCE: "Finalizar Efinance?",
        FINALIZARPOSVENDA: "Terminar Pós-Venda?",
        ANEXARCERTIFICADO: "Anexar Certificado?",
        PRIMEIRAVISTORIA: "Data para 1ª vistoria/contato",
        RETORNOVISTORIA: "Vistoria com retorno?",
        MENSAGEMFINALIZARENTREGA: "Confirma finalização da etapa de entrega?",
        MENSAGEMINSTALACAOSEMAGENDAMENTO: "Confirma que a instalação ja esta em andamento e deseja prosseguir sem agendamento?",
        MENSAGEMFINALIZARINSTALACAO: "Confirma finalização da etapa de instalação?",
        MENSAGEMFINALIZARINSTALACAOSEMCERTIFICADO: "Confirma finalização da etapa de instalação sem certificado?",
        MENSAGEMFINALIZARINSTALACAOCOMASSISTENCIAS: "Existem assistências abertas para o cliente, deseja finalizar a etapa de instalação?",
        MENSAGEMFINALIZARINSTALACAOVISTORIA: "Existem ambientes com vistoria/agenda montagem em abertas, deseja finalizar a etapa de instalação?",
        MENSAGEMFINALIZARENVIOFABRICA: "Confirma finalização da etapa de envio fábrica?",
        MENSAGEMFINALIZAREFINANCE: "Confirma finalização da etapa de efinance?",
        MENSAGEMFINALIZARPOSVENDA: "Confirma conclusão do processo de PÓS VENDAS?",
        ENTREGAFUTURAVISTORIAMEDICAO: "Entrega Futura?",
        ENTREGAFUTURAPLANTASVISTORIAMEDICAO: "Entrega Futura com plantas?",
        DEVOLVERINSTALACAOFUTURA: "Devolver para Instalação Futura?",
        AGENDARENTREGA: "Agendar Entrega?",
        AGENDARINSTALACAO: "Agendar Instalação?",
        INSTALACAOEMANDAMENTO: "Instalação em andamento?",
        REDIRECIONAREFINANCE: "Abrir Efinance?",
        ATUALIZARAGENDAMONTADOR: "Atualizar agenda montadores?",
        CODIGOCONTRATOOBRIGATORIO: "Código do Contrato é obrigatório",
        CONCLUIDOVISTORIATOTAL: "Concluído Total?",
        CONCLUIDOVISTORIAPARCIAL: "Concluído Parcial?",
        PLANTASCONCLUIDO: "Plantas Concluído?",
        MENSAGEMOBRASPLANTASPOSVENDA: "Plantas Impressa e conferido?",
        MENSAGEMCONCLUIRIVISTORIA: "Deseja finalizar a etapa de vistoria?",
        MENSAGEMCONCLUIRINSTALACAOFUTURA: "Deseja finalizar a etapa de instalação futura?",
        MENSAGEMCONCLUIRCONFERENCIA: "Deseja Concluir a conferência?",
        MENSAGEMLIBERARENTREGA: "Deseja liberar entrega antes de finalizar etapa de vistoria?",
        CONCLUIRINSTALACAOFUTURA: "Concluir vistoria?",        
        LIBERARINSTALACAOFUTURA: "Liberar entrega?",      
        OBRASPLANTASBRIGATORIO:"Obrigatório",
        OBRIGATORIOCONCLUIRASSISTENCIAS: "Para encerrar o atendimento é necessário finalizar todas as assistências abertas",
        ANEXARCERTIFICADOSBRIGATORIO:"Obrigatório",
        MENSAGEMANEXARCERTIFICADOSBRIGATORIO: "Para encerrar o atendimento é necessário anexar 'Certificado de Garantia' e 'Pesquisa de Satisfação'",        
        MENSAGEMAGENDAMENTOENTREGA: "Apenas um único agendamento de entrega é permitido, realize o cancelamento do agendamento de entrega atual",        
        MENSAGEMAGENDAMENTOINSTALACAO: "Apenas um único agendamento de instalação é permitido, realize o cancelamento do agendamento da instalação atual",        
        MENSAGEMOBRASPLANTAS: "Obrigatório realizar agendamento da 1ª vistoria para concluir etapa", 
        NOVAASSISTENCIA: "Nova Assistência",          
        ASSISTENCIANAOCONCLUIDA: "Assistência não concluída",        
        ASSISTENCIACONCLUIDA: "Assistência concluída",        
        ASSISTENCIAEXCLUIDA: "Assistência excluída",  
      
    },    

    GUIALIST: {   
        FRIO: "Frio",
        MORNO: "Morno",
        QUENTE: "Quente",
        ARQUITETO: "Arquiteto",
        DATAPRIMEIROCONTATO: "Contato",
        DATAPREVISAOINSTALACAO: "Previsão Instalação",
        DATAPRAZOCONTRATO: "Prazo Contrato",
        DATAPREVISAOENTREGA: "Previsão Entrega",
        DATAPROXIMAVISTORIA: "Próxima Vistoria",
        VISUALIZARPOSICS: "Visualizar Posics",
        DOCUMENTOENTREGA: "Ver Documento de Entrega",
        ATUALIZARPOSICS: "Atualizar Posics",
        CONFIRMARCANCELAMENTOFATURAMENTO: "Confirma o cancelamento do faturamento?",
        CONFIRMARATUALIZARVALOREFINANCE: "Confirma atualização do valor efinance?",
        ALTERARSTATUS: "Alterar Status",
        UNIFICARATENDIMENTOS: "Unificar Atendimentos",
        PRIMEIROCONTATOVISTORIA: "Contato",
        BUSCARVENDEDOR: "Nome do Vendedor",        
        REFERENCIA: "Referência",
        EMISSAO: "Data de Cadastro",
        DATAVENDA: "Data de Venda",
        DATAPOSVENDA: "Data de PósVenda",        
        ATENDIMENTO: "Atendimento",
        VENDEDOR: "Vendedor",
        TEMPERATURA: "Temperatura",
        TEMPERATURA2: "Temp.",
        CONFERENTE: "Conferente",
        BUSCARGUIASNOME: "Nome do Cliente",
        CODIGOGUIA: "Código da Guia",
        GUIA: "",
        EMNEGOCIACAO: "Em Negociação",
        ENTREGAFUTURA: "Entrega Futura",
        INSTALACAOFUTURA: "Instalação Futura",
        ATENDIMENTOCANCELADO: "Atendimento Cancelado",
        ATENDIMENTOVENDAEFETIVADO: "Venda Efetivada",
        ATENDIMENTOCOMPLETOFINALIZADO: "Atendimento completo finalizado",
        CLIENTEEMESPERA: "Em Espera",        
        POSVENDA: "Pós Venda",
        ATENDIMENTOVINCULADO: "Atendimento Vinculado",
        PRAZOS: "Prazos",
        PRAZOFABRICA: "Prazo da fábrica",             
        PRAZOLIBERACAO: "Prazo de liberação", 
        PRAZOFABRICAMENSAGEM:"Definir prazo da fábrica",
        PRAZOPRIMEIROCONTATOMENSAGEM:"Definir prazo para primeiro contato",
        VISTORIA: "Vistoria?",
        FATURADO: "Faturado?",
        MENSAGEMCONFIRMARVISTORIA: "Atualizar situação de vistoria?",
        MENSAGEMCONFIRMARFATURAMENTO: "Atualizar situação de faturamento?",
        VERSTATUS: "Ver status atual",
        PROXIMOPRAZO: "Prazo",
        PROXIMAVISTORIA: "Próxima Vistoria"          
    },

    NOTIFICAGUIA: {
        REPORTAR: "Notificar Atendimento por Email",        
        EMAIL: "Emails",
        EMAILCC: "Emails CC",
        INFO: "Observação Email",                
    },    
        
}