<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-info">
            <p v-if="editar" class="modal-card-title">{{$t('MODALURLEXTERNAANEXO.URLEXTERNAEDITAR')}}</p>
            <p v-else class="modal-card-title">{{$t('MODALURLEXTERNAANEXO.URLEXTERNA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.NOME')">
                    </b-field>
                </p>
                <div class="panel-block">                    
                    <b-input type="text" v-model="nome"> </b-input>                                   
                </div>    
                
                <p class="panel-block">
                    <b-field :label="$t('MODALURLEXTERNAANEXO.URL')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input type="text" v-model="url"> </b-input>                                 
                </div>                 

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.INCLUIR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            guia: Object,
            anexo: {
                type: Object,
                default: null
            },
            editar: {
                type: Boolean,
                default: false
            },            
                                                        
        },
        data() {
            return {                              
                nome: null,
                url: null,
                salvandoDados :false
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            if(this.anexo!=null){
                this.nome = this.anexo.fileName;
                this.url = this.anexo.anexo;
            }
            return true;
        },
        methods: {

            async confirmar(){

                this.salvandoDados=true;
                
                if (this.nome== null  || this.nome == '' || this.nome == undefined) {
                    this.salvandoDados = false;
                    return;
                }     

                if (this.url== null  || this.url == '' || this.url == undefined) {
                    this.salvandoDados = false;
                    return;
                }      
                


                this.isLoading = true;  
                
                
                let url = '';
                if(this.editar){                    
                    const params = [];                                                                                                                      
                    params.push(`anexoId=${this.anexo.id}`);  
                    params.push(`nome=${this.nome}`);  
                    params.push(`url=${this.url}`);                     
                    url = `/api/atendimento/EditarAnexoUrlExternaGuia?${params.join('&')}`;            
                }else{
                    const params = [];                                                                                                                      
                    params.push(`id=${this.guia.id}`);  
                    params.push(`nome=${this.nome}`);  
                    params.push(`url=${this.url}`); 
                    url = `/api/atendimento/AnexoUrlExternaGuia?${params.join('&')}`;                    

                }
                    

                try {
                    this.$http.get(url)
                        .then(res => {
                            this.salvandoDados=false;
                            return true;

                        }).finally(() => {                                
                            this.salvandoDados=false;    
                            this.observacoes = null;

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('GUIAATENDIMENTO.MENSAGEMOBSERVACAOSALVA'),
                                type: 'is-success'
                            }); 
                            
                            this.$emit('loadData')                                                            
                            this.$emit('close');                                                                                   
                        }); 
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                    
            },            
            
        }
    }
</script>