<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('ARQUITETOCADASTRO.SALVANDOCADASTRO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SISTEMA.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="150" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="tipo" v-slot="{ errors, valid }">                                
                                        <b-field :label="$t('SISTEMA.TIPO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select expanded v-model="model.tipo">                                            
                                                <template v-for="(item, index) in arquitetosTipos">
                                                    <option v-bind:key="index" :value="item.codigo">
                                                        {{item.descricao}}
                                                    </option>
                                                </template>
                                            </b-select>
                                        </b-field>                                
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>                                       
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('arquiteto-alterar')"
                                      :loading="salvandoDados"
                                      native-type="button" @click="salvar()"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    tipo: null
                },
                arquitetosTipos: [],                                
                salvandoDados: false
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/arquiteto?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;                        
                    });
            }

            this.$http.get('/api/search/ArquitetoTipo')
            .then(res => {
                if (res.data != null) {
                    this.arquitetosTipos = res.data                    
                }
            });             
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('ARQUITETOCADASTRO.ARQUITETO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([                                
                'unidadeId',                
            ]),                
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }
                
                this.model.UnidadeDeCadastro = { id : this.unidadeId};
                
                this.$http.post(`/api/manutencao/arquiteto`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            },
            salvar() {
                this.onSubmit();
            }
        }
    }
</script>