<template>
    <section>
        <titlebar :title-stack="titleStack"> </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('MOTIVOCADASTRO.DESCRICAO')">
                                        <b-input type="textarea"
                                                 v-model="model.descricao"
                                                 maxlength="2000"
                                                 :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            {{$t('MOTIVOCADASTRO.DESTINO')}}
                                        </div>
                                        <div class="panel-block">
                                            <b-list-group>
                                                <b-list-group-item v-for="motivo in motivos"
                                                                   :key="motivo.id">
                                                    <div class="field">
                                                        <b-switch v-model="destinosSelecionados[motivo.field]">
                                                            {{ motivo.label }}
                                                        </b-switch>
                                                    </div>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading">
                                    <div class="field">
                                        <b-switch v-model="model.enviaEmail">
                                            {{$t('MOTIVOCADASTRO.ENVIAEMAIL')}}
                                        </b-switch>
                                    </div>   
                                </div>
                            </article>
                            <article class="panel">
                                <historico-alteracoes :tabela="'Motivo'" :chave="model.id"></historico-alteracoes>
                            </article>
                        </article>


                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('motivo-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                 {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back()">
                                 {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from "vuex";    
    import titlebar from "@/components/titlebar.vue";    
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                },

                salvandoDados: false,
                motivos: [],
                destinosSelecionados: {                    
                    cancelarAgenda: false,
                    alteracaoAgenda: false,                    
                    bloqueioAgenda: false,
                    cancelarGuia:false,
                    devolucaoProjetistaGuia:false,
                    atendimentoEmEsperaGuia:false,
                    alteracaoPrazoVendedor:false,
                    alteracaoPrazoProjetista: false,
                    devolverEfetivacaoVendedor: false,
                    financeiroNaoAprovou: false,
                    removerDataFutura: false,
                    removerDataFuturaPlantas: false,
                    prazoEntregaEletros: false,
                    medicaoParcialVenda: false,
                    aguardarMedicaoVenda:false,
                    prazoAssinaturaVenda:false,                    
                    devolverAssinaturaVenda:false,
                    devolverEletrosVenda:false,
                    devolverGerenteConferenciaVenda: false,
                    inserirDataFutura: false,
                    prazoEntregaFuturaVenda:false,
                    devolverMedidorVenda:false,
                    justificarPrazoAgendaGuia:false,
                    devolverPlantasVenda: false
                },
            };
        },
        components: {
            titlebar,
            historicoAlteracoes
        },
        async created() {
            const promises = [this.$http.get(`/api/manutencao/destinoMotivos`)];
            if (this.$route.params.id) {
                promises.push(this.$http.get("/api/manutencao/motivo?id=" + this.$route.params.id));
            }
            const [motivos, resultado] = await Promise.all(promises);
            this.motivos = motivos.data;

            if (this.$route.params.id) {
                this.model = resultado.data?.motivo;

                // const selectedMotivo = this.motivos.find(m => m.id === this.model.destinoMotivo);
                if (resultado.data?.destinoMotivo) {
                    Object.keys(resultado.data.destinoMotivo).forEach(key => {
                        this.destinosSelecionados[key] = resultado.data.destinoMotivo[key];
                    })
                }
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'), 
                    this.$t('MOTIVOCADASTRO.MOTIVOS'), 
                    this.model.id ?? "novo"
                ];
            },
            ...mapState(["unidadeId", "config"]),
            ...mapGetters(["isInRole"]),
        },
        watch: {},
        methods: {
            onSubmit() {

                this.model.UnidadeDeCadastro = { id : this.unidadeId};
                
                const newItem = Object.assign({}, this.model);
                if (!this.$route.params.id) {
                    delete newItem.id;
                }
                
                const url = `/api/manutencao/motivo?${this.getDestinos()}`

                this.$http
                    .post(url, newItem)
                    .then(() => {
                        this.$router.back()
                    })
                    .catch((err) => {
                        console.error(err);
                        Object.keys(err.body.errors).forEach((x) => {
                            this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                        });
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('MOTIVOCADASTRO.ERROSALVAR') + err.body.title,
                            type: "is-danger",
                            queue: false,
                        });
                    });
            },
            getDestinos() {
                let url = '';
                Object.keys(this.destinosSelecionados).forEach(key => {
                    if (this.destinosSelecionados[key] != undefined) {
                        if (url.length) url += '&';
                        url += `${key}=${this.destinosSelecionados[key]}`;
                    }
                })

                return url;
            },
        },
    };
</script>
