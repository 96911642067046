<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALLISTARMOTIVOS.MOTIVOS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">       
            <div class="panel">
                 
                <p  class="panel-block">
                    <b-field :label="$t('MODALLISTARMOTIVOS.MOTIVOS')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <table  v-if="motivos !=null && motivos.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Código Motivo</th>
                                <th>Descrição Motivo</th> 
                                                                                                     
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(motivo, index) in motivos" :key="index">
                                
                                <td>
                                        <b-checkbox :disabled="motivoSelecionado!=null && !motivo.selecionado"  @input="changed(motivo)"
                                        size="is-small" type="is-info"  v-model="motivo.selecionado"></b-checkbox >                                    
                                </td>                                
                                <td>{{ motivo.codigo }}</td>
                                <td>{{ motivo.nome }}</td>                                                    
    
                            </tr>
                        </tbody>                        
                    </table> 
                    <b-notification v-else type="is-info">
                        <h5 class="is-size-5">{{$t('MODALLISTARMOTIVOS.CUSTOSEMNOTIVOS')}}</h5>                                
                    </b-notification>      
                </div> 

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            custoId: Number,   
              
        },
        data() {
            return {                                              
                salvandoDados :false,                
                motivos:[],
                motivoSelecionado:null,                             
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.loadMotivos();
        },   
        methods: {
                                              
            changed(motivo){
                if(motivo.selecionado){
                    this.motivoSelecionado = motivo;                    
                }else{
                    this.motivoSelecionado = null;                    
                }                                
                
            },               
            loadMotivos() {    
                this.salvandoDados = true;   
                
                const params = [];                                                                                                      
                params.push(`custoId=${this.custoId}`);                  
                params.push(`unidadeId=${this.unidadeId}`);                    

                this.$http.get(`/api/manutencao/MotivosCusto?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.motivos = data;                       
                        this.isLoading=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                                                                                  
                    });               
                    
            },                        
      
        
            async confirmar() {
                              
                if(this.motivoSelecionado==null){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALLISTARMOTIVOS.MENSAGEMSELECIONEMOTIVO'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;                              
                }else{                    
                    this.$emit('preencherMotivo', this.motivoSelecionado.codigo + " - " + this.motivoSelecionado.nome);
                    this.$emit('close');                                  
                }
                
      
                
            },
            

        }
    }
</script>