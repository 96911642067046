<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('SISTEMA.SALVANDOAGENDAMENTO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-half">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SISTEMA.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="100" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column column is-one-quarter">
                                    <ValidationProvider name="model.cor" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOAGENDAMENTOCADASTRO.COR')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="text" v-model="model.cor"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="columns is-one-quarter">
                                    <div class="column is-half">
                                        <br>
                                        <input type="color" :change="corSelecionada != null ? model.cor = corSelecionada.substring(1) : null" v-model="corSelecionada">
                                    </div>
                                    <div class="column is-half">
                                        <br>
                                        <b-switch v-model="model.desativado" type="is-danger">
                                            {{$t('SISTEMA.DESATIVADO')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </div>        
                            <div class="columns">

                                <div class="column">
                                    <b-field :label="$t('AGENDAMENTO.TIPOAGENDA')">
                                        <b-select expanded v-model="model.tipo">                                            
                                            <template v-for="(item, index) in guiaAgendaTipos">
                                                <option v-bind:key="index" :value="item.codigo">
                                                    {{item.descricao}}
                                                </option>
                                            </template>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">                                    
                                    <b-field expanded :label="$t('AGENDAMENTO.INTEGRARAGENDA')">
                                        <b-tooltip  size="is-small" position="is-top" label="Inserir o Código (ID) da agenda desejada">
                                            <b-input  type="number" max="100" v-model="model.agendaIntegraId"></b-input>
                                        </b-tooltip>
                                    </b-field>                                    
                                </div>
                            </div>                                                                                        
                        </article>

                        <article class="panel">
                            <div class="panel-heading has-text-centered">
                                {{$t('TIPOAGENDAMENTOCADASTRO.ESCALA')}}
                            </div>
                            <div class="panel-block" style="overflow-x: auto;">
                                <table class="table is-bordered  is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{{$t('SISTEMA.ID')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.DIADASEMANA')}}</th>
                                            <th>{{$t('SISTEMA.NOME')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.HORAINICIO')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.HORAFIM')}}</th>
                                            <th>{{$t('TIPOAGENDAMENTOCADASTRO.INTERVALO')}}</th>                                                                                     
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in model.escalas?.filter(i => i.show===true)" v-bind:key="index">
                                            <td><b-button
                                                type="is-danger"
                                                native-type="button"
                                                icon-left="delete"
                                                class="button-delete"
                                                @click ="deleteItem(item,index)" >
                                            </b-button>                                            
                                            </td>
                                            <td>{{item.id}}</td>
                                            <td> 
                                                <b-select class="is-fullwidth" v-model="item.diaDaSemana">
                                                    <option value="1" >Segunda</option>
                                                    <option value="2" >Terça</option>
                                                    <option value="3" >Quarta</option>
                                                    <option value="4" >Quinta</option>
                                                    <option value="5" >Sexta</option>
                                                    <option value="6" >Sábado</option>
                                                    <option value="0" >Domingo</option>
                                                </b-select>                                            
                                            
                                            </td>
                                            <td> <input class="input" type="text" v-model="item.nome" maxlength="100"> </td>                                                 
                                            <td> <input class="input" type="text" v-mask="'##:##'" v-model="item.horaInicioEscala"> </td>
                                            <td> <input class="input" type="text" v-mask="'##:##'" v-model="item.horaFimEscala"> </td>                                  
                                            <td> <input class="input" type="text" v-mask="'##'" v-model="item.intervaloDuracaoAtendimento"> </td>
                                                     
                   
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>     
                                <div class="panel-heading has-text-centered">
                                    <b-button
                                        type="is-success"
                                        native-type="button"
                                        icon-left="plus"
                                        class="center"
                                        @click="addItem()">
                                        {{$t('SISTEMA.INCLUIR')}}
                                    </b-button>  
                                </div>                                                     
                        </article>                             
                                    

                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('tipos-agendamento-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'    

    export default {
        components: {
            titlebar            
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    cor: null,
                    desativado: false,
                    escalas: [],
                    tipo:null
                },                
                
                guiaAgendaTipos: [],
                corSelecionada: null,
                salvandoDados: false,
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/tipoagendamento?id=' + this.$route.params.id)
                    .then(res => {                        
                        this.model = res.data;  

                        this.model.escalas.forEach((escala) => { 
                            escala.show = true;                             
                        });
                                                                          
                        this.corSelecionada = "#" + this.model.cor;     
                        this.model = res.data;
                    });
            }


            this.$http.get('/api/search/guiaAgendaTipo')
            .then(res => {
                if (res.data != null) {
                    this.guiaAgendaTipos = res.data
                    this.guiaAgendaTipos = this.guiaAgendaTipos.filter(a=>a.descricao!='Outro');
                }
            });              
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('TIPOAGENDAMENTOCADASTRO.TIPOAGENDAMENTO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([                                
                'unidadeId',                
            ]),                          
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {

            addItem() {
                this.model.escalas.push({
                    id: 0,
                    nome: null,
                    horaInicioEscala: null,
                    horaFimEscala: null,
                    intervaloDuracaoAtendimento: null,
                    show:true,                    
                })
            },
            
            deleteItem(item,index) {
                if(item.id==0){
                    this.model.escalas.splice(index, 1);
                }else{
                    item.show = false; 
                }
                
                this.$forceUpdate();                
            },               
           
            onSubmit() {
                this.salvandoDados = true;
                let todosEscalasPreenchidas = true;  
                
                this.model.escalas.filter(i => i.show===true).forEach((escala) => {
                                                     
                    if(!escala.nome || !escala.intervaloDuracaoAtendimento
                    || !escala.horaInicioEscala || !escala.horaFimEscala  ){
                        todosEscalasPreenchidas= false;
                     }
                });                

                if(this.model.id == null){
                    this.model.id = 0
                }

                this.model.UnidadeDeCadastro = { id : this.unidadeId};

                if(todosEscalasPreenchidas ){
                                        
                    this.$http.post(`/api/manutencao/tipoagendamento`, this.model)
                            .then((res) => {
                                this.salvandoDados = false;
                                this.$router.back();                                
                            
                            })
                            .catch((error) => {
                                this.salvandoDados = false;
                                throw error
                            });                      

                }else{
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.CAMPOSOBRIGATORIOS'),
                        type: 'is-warning',
                        hasIcon: true                                          
                    });                    
                    this.salvandoDados = false;
                    return null;
                }                
                                
            },
        }
    }
</script>