<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-info">
            <p class="modal-card-title">{{$t('MODALTRANSFORMAENTREGAFUTURAPLANTAS.AVISO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="incluirObservacao">{{$t('SISTEMA.INCLUIR')}}</b-button>
            <b-button @click="$emit('close'); $emit('removerconfirmartransformaentregafuturaplantas')   ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Object                                            
        },
        data() {
            return {                              
                observacoes: null,
                salvandoDados :false
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        methods: {

            async incluirObservacao(){

                this.salvandoDados=true;
                

                if (this.observacoes== null  || this.observacoes == '' || this.observacoes == undefined) {
                    this.salvandoDados = false;
                    return;
                }      
                       
                this.salvandoDados=true;
                    const params = [];                    
                    if (this.id) params.push(`id=${this.id}`);                                                                                          
                    if(this.observacoes) params.push(`observacoes=${this.observacoes}`);
                
                try {
                    this.$http.get(`/api/venda/TransformaEntregaFuturaPlantas?${params.join('&')}`)
                        .then(res => {
                            this.salvandoDados=false;
                            return true;

                        }).finally(() => {                                
                            this.salvandoDados=false;    
                            this.observacoes = null;

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('GUIAATENDIMENTO.MENSAGEMOBSERVACAOSALVA'),
                                type: 'is-success'
                            }); 
                            
                            this.$emit('loadData')                            
                            this.$emit('close'); 
                            this.$router.back();                                                                                   
                        }); 
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                    
            },            
            
        }
    }
</script>