<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('SISTEMA.SELECIONEDATAHORA')}}</p>
        </header>
        <section class="modal-card-body">
            <b-field>
                <b-datepicker rounded
                                  :placeholder="$t('SISTEMA.SELECIONEDATAHORA')"
                                  icon="calendar-today"
                                  v-model="dataSelecionada"
                                  inline
                                  horizontal-time-picker>
                </b-datepicker>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                      @click="alterarData">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataSelecionada: new Date()
            }
        },
        methods: {
        
            alterarData() {

                this.$emit('alteracaoDataAgendas', this.$moment(this.dataSelecionada).toDate())                
                this.$emit('close')
            }
        }
    }
</script>