<template>
  <section class="hero is-medium">
    <div class="hero-body">
      <div class="container has-text-centered">
        <img alt="FLORENSE" width="300" src="../assets/logo.png">
        <h2 class="subtitle">
          {{$t('SOBRE.APRESENTACAO')}}
        </h2>    
        <h2 class="subtitle">
          <span class="tag is-black">{{$t('SOBRE.VERSAO')}} {{config.releaseNumber}}</span>
          <span class="tag is-info">{{config.releaseDate}}</span>
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
    import { mapState } from 'vuex'
    import modalNotificacao from '@/components/modalNotificacao.vue'
    import jwt from 'jsonwebtoken'

    export default {
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'usuario'
            ]),
        },
        methods:{
          abrirModalNotificacao(podeCancelar) {

                const existingModal = document.querySelector('.modal'); 
                if(!existingModal){
                  this.$buefy.modal.open({
                        parent: this,
                        component: modalNotificacao,                                 
                        hasModalCard: true,
                        trapFocus: true,                    
                        canCancel: false
                    })              

                }            
            
            },

            loadTotalNotificacoesFuncionario() {


                    this.$http.get('/api/notificacao/RetornaTotalMensagensFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                          if(data>0){
                            this.abrirModalNotificacao();
                          }else{
                
                              this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                                      .then(res => res.data)
                                      .then(data => {     
                                                                      
                                      if(data>0)
                                        this.abrirModalNotificacao();
                                  })
                                  .catch((error) => {                        
                                      throw error
                                  });                            
                          }
                            
                                                                            
                    })
                    .catch((error) => {                        
                        throw error
                    });
                    
                    
                    
                
            }, 
          checkAbrirGuia(){       

             // caso em que usuario ja esta com sessao logada, ou seja nao passa pela tela de login
             if (this.$route.query.token) {                    
                  this.Token = this.$route.query.token;
                  const json = jwt.decode(this.Token);
                  this.$store.commit('CLEAR_ABRIRGUIA');
                  this.$store.commit('SET_UNIDADE', json.UnidadeId);
                  this.$router.push({name: 'guia', params: {id: json.GuiaId}})  
              }
                
            

              // caso em que usuario nao estava logado
              if(this.usuario.abrirGuiaId!=null && this.usuario.abrirGuiaId!=""
               && this.usuario.abrirUnidadeId!=null  && this.usuario.abrirUnidadeId!=""){                
                const guiaId = this.usuario.abrirGuiaId;
                const unidadeId = this.usuario.abrirUnidadeId;

                this.$store.commit('CLEAR_ABRIRGUIA');
                this.$store.commit('SET_UNIDADE', unidadeId);
                this.$router.push({name: 'guia', params: {id: guiaId}})                
              }

          }                         
        },
        mounted() {                        
            this.loadTotalNotificacoesFuncionario();
            setTimeout(() => {this.checkAbrirGuia()},500);

        },
             
    }
</script>
