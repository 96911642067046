<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="Nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="100" :has-counter="true" ></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('FORNECEDORCADASTRO.CONTATO')">
                                        <b-input v-model="model.contato" maxlength="40" :has-counter="true"  ></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <buscaCepEndereco :cep.sync="model.cep"
                                              :endereco.sync="model.endereco"
                                              :bairro.sync="model.bairro"
                                              :cidadeid.sync="model.cidade.id"
                                              :cidadenome.sync="model.cidade.nome"
                                              :erroCidade="erroCidade"
                                              @changed="validarCidadeAlterada"
                                              ></buscaCepEndereco>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('FORNECEDORCADASTRO.TELEFONE')">
                                        <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                        <label class="label">{{$t('FORNECEDORCADASTRO.CNPJ')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cnpj" v-mask="'##.###.###/####-##'">
                                        </div>
                                </div>        
                                <div class="column">
                                    <label class="label">{{$t('FORNECEDORCADASTRO.IE')}}</label>
                                    <div class="control">
                                        <b-input type="text" v-model="model.inscricaoEstadual" maxlength="18" :has-counter="true" />
                                    </div>
                                </div>  
                                <div class="column">
                                    <label class="label">{{$t('FORNECEDORCADASTRO.DEPARACONTABIL')}}</label>
                                    <div class="control">
                                        <b-input type="text" v-model="model.deparaContabil" maxlength="10" :has-counter="true" />
                                    </div>
                                </div>  
                                <div class="column">
                                    <label class="label">{{$t('FORNECEDORCADASTRO.IM')}}</label>
                                    <div class="control">
                                        <b-input type="text" v-model="model.inscricaoMunicipal" maxlength="12" :has-counter="true" />
                                    </div>
                                </div>                                                                                        
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field :label="$t('FORNECEDORCADASTRO.EMAIL')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input maxlength="50" type="email" v-model="model.email"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('FORNECEDORCADASTRO.OBS')}}</label>
                                        <div class="control">
                                            <textarea class="textarea" maxlength="255" v-model="model.observacoes"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="'Fornecedor'" :chave="model.id"></historico-alteracoes>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('fornecedor-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('FORNECEDORCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('FORNECEDORCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import buscaCepEndereco from '@/components/buscaCepEndereco.vue'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    contato: null,
                    cep: null,
                    endereco: null,
                    bairro: null,
                    cidade: {
                        id: null,
                        nome: null
                    },
                    fone: null,
                    cnpj: null,
                    inscricaoEstadual: null,
                    inscricaoMunicipal: null,
                    email: null,
                    observacoes: null,
                },

                historicoArquivos: [],
                salvandoDados: false,
                erroCidade: null
            };
        },
        components: {
            buscaCepEndereco,
            titlebar,
            historicoAlteracoes
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/manutencao/fornecedor?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                        if (!this.model.cidade){
                            this.model.cidade = {
                                id: null,
                                nome: null
                            }
                        }                        
                    })
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('FORNECEDORCADASTRO.MANUTENCAO'),
                    this.$t('FORNECEDORCADASTRO.FORNECEDOR'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([
                'unidadeId',
                'config'                
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
         
        },
        methods: {
            validarCidadeAlterada(idCidade){
                this.erroCidade = null;
                if (!this.model.cidade){
                    this.model.cidade = {
                        id: idCidade
                    }
                }else{
                    this.model.cidade.id = idCidade;
                }
            },
            validarCidade(){
                const newItem = Object.assign({}, this.model);
                if (!newItem.cidade?.id){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('FORNECEDORCADASTRO.CAMPOOBRIGATORIO'),
                        type: 'is-danger',
                        queue: false
                    })
                    this.erroCidade = this.$t('FORNECEDORCADASTRO.CAMPOOBRIGATORIO');
                    return false;
                }else{
                    this.erroCidade = null;
                }
                return true;
            },
            onSubmit() {
                const newItem = Object.assign({}, this.model);
                if (!this.$route?.params?.id){
                    delete newItem.id;
                }

                if (!this.validarCidade()){
                    return;
                }
                newItem.cidade = this.$store.state.cidades.find(c => c.id === newItem.cidade.id);

                if (!newItem.cnpj?.trim()?.length){
                    newItem.cnpj = null;
                }

                newItem.UnidadeDeCadastro = { id : this.unidadeId};

                this.salvandoDados = true;
                this.$http.post('/api/manutencao/fornecedor', newItem)
                    .then(() => {
                        this.$router.back()
                    })
                    .catch(err => {
                        console.error(err);
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: err.status != 400 ? this.$t('FORNECEDORCADASTRO.ERROSALVAR') + err.body.title : this.$t('FORNECEDORCADASTRO.ERROSALVAR'),
                            type: 'is-danger',
                            queue: false
                        })
                    })
                    .finally(() => {                        
                        this.salvandoDados = false;
                    });
            }
        }
    }
</script>