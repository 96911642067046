<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">

            <div class="column is-2">
                <b-field>
                    <b-input :placeholder="$t('SISTEMA.CODIGO')"
                             v-model="procurarCodigo"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>            
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <div class="is-flex is-justified-between">
                    <router-link v-if="isInRole('cliente-alterar')" :to="{ name: 'cliente'}" class="button is-primary">
                        {{$t('SISTEMA.INCLUIR')}}
                    </router-link>                    
                </div>
            </div>

        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('SISTEMA.PROXPAGINA')"
                 :aria-previous-label="$t('SISTEMA.PAGANTERIOR')"
                 :aria-page-label="$t('SISTEMA.PAGINA')"
                 :aria-current-label="$t('SISTEMA.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"                 
                 @sort="onSort">

                <template >
                
                <b-table-column field="código" :label="$t('SISTEMA.CODIGO')" v-slot="props" >
                    {{ props.row.codigoExterno }}
                </b-table-column>

                <b-table-column field="Nome" :label="$t('SISTEMA.NOME')" v-slot="props" >
                    {{ props.row.nome }}
                </b-table-column>

                <b-table-column field="desativado" label="" v-slot="props">
                    <b-icon v-if="props.row.desativado" type="is-danger" icon="close-circle" :title="$t('CLIENTESLISTA.DESATIVADO')"></b-icon>
                </b-table-column>

                <b-table-column custom-key="actions" numeric v-slot="props">
                    <router-link :to="{ name: 'cliente', params: { id: props.row.id,criarGuia: criarGuia }}" 
                    class="button is-small is-primary" :title="$t('SISTEMA.EDITAR')">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>

                </template>
        </b-table>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? this.$route.params.procurar ?? '',
                procurarCodigo: null,
                criarGuia: Boolean(this.$route.params.criarGuia) ?? false,
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null,         
                                                      
                
            }
        },
        components: {
            titlebar
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),              
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('SISTEMA.CLIENTE')+'s',
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        },          
        methods: {
            loadAsyncData: debounce(function () {
                const params = [                    
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `unidadeId=${this.unidadeId}`,
                    `retornarDesativados=false`                      
                ];

                if(this.procurarCodigo){
                    params.push(`procurarCodigo=${this.procurarCodigo}`);
                }else if (this.procurar){
                    params.push(`procurar=${this.procurar}`);
                }

                this.loading = true
                this.$http.get(`/api/manutencao/clientes?${params.join('&')}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            }, 500),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },

        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>