<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column is-3">
                                    
                    <b-datepicker
                        v-model="filtroAno"
                        placeholder="Escolha o ano"
                        :date-formatter="dateFormatter"
                        icon="calendar"
                        type="month" 
                        @input="loadAsyncData"
                        editable >                                  
                </b-datepicker>
                    
            </div>              
            <div class="column">
                <div class="buttons">
                    <router-link v-if="isInRole('feriado-alterar')" :to="{ name: 'feriado' }" class="button is-primary">
                        {{$t('SISTEMA.INCLUIR')}}
                    </router-link>

                    <b-button icon-left="calendar" class="ml-1"  :title="$t('SISTEMA.IMPORTAR')"
                    @click.prevent.stop="importarFeriados()">{{$t('SISTEMA.IMPORTAR')}}</b-button>                        
                </div>
            </div>

        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 :paginated="false"
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('SISTEMA.PROXPAGINA')"
                 :aria-previous-label="$t('SISTEMA.PAGANTERIOR')"
                 :aria-page-label="$t('SISTEMA.PAGINA')"
                 :aria-current-label="$t('SISTEMA.PAGATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template  @click="abrirCadastro(props.row.id)">

                <b-table-column v-slot="props" field="Data" :label="$t('SISTEMA.DATA')" >                    
                    {{ props.row.data ?  $moment(props.row.data).format("DD/MM/YYYY") : '' }}
                </b-table-column>                

                <b-table-column v-slot="props" field="Nome" :label="$t('SISTEMA.NOME')" >
                    {{ props.row.nome }}
                </b-table-column>

                <b-table-column v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'feriado', params: { id: props.row.id }}" class="button is-small is-primary" :title="$t('FERIADOSLISTA.EDITAR')">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                    <b-button 
                        class="ml-2"
                        icon-left="delete"
                        type="is-danger"
                        size="is-small"
                        :title="$t('SISTEMA.REMOVER')"
                        @click="removerFeriado(props.row.id)"></b-button>                                                                                                                                    
                                                                    
                                                                                        
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<style scoped>
.datepicker-table{
    display: none !important;
}
</style>

<script>
    import { mapGetters,mapState } from 'vuex'    
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? "",
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null,
                filtroAno: new Date()
            }
        },
        components: {
            titlebar
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),            
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('FERIADOSLISTA.FERIADOS'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        },        
        methods: {
            importarFeriados(){
                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('FERIADOSLISTA.IMPORTARFERIADO', {'0': this.filtroAno.getFullYear()}),
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),                    
                    cancelText: this.$t('SISTEMA.CANCELAR'),                      
                    type: 'is-info',
                    hasIcon: true,
                    onConfirm: () => {

                        this.loading = true;    
                        this.$http.get('/api/manutencao/ObterFeriados?ano='+ this.filtroAno.getFullYear()+'&unidadeId='+this.unidadeId)
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadAsyncData()
                                }
                            }).finally(() => {                                
                                this.loading = false;  
                                this.loadAsyncData();  
                            });
                            
                    }
                })                

            },
            removerFeriado(id){                
                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('FERIADOSLISTA.REMOVERFERIADO'),
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),                    
                    cancelText: this.$t('SISTEMA.CANCELAR'),                      
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        this.loading = true;    
                        this.$http.get('/api/manutencao/ExcluirFeriado?id='+ id)
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadAsyncData()
                                }
                            }).finally(() => {                                
                                this.loading = false;  
                                this.loadAsyncData();  
                            });
                            
                    }
                })
            },            
            dateFormatter(date) {
                return date.getFullYear()
           },            
           loadAsyncData() {
                const params = [
                    `procurar=${this.procurar}`,
                    `ano=${this.filtroAno.getFullYear()}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `unidadeId=${this.unidadeId}`,
                    `perPage=${this.perPage}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/manutencao/feriados?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {                
                this.$router.push({ name: 'feriado', params: {id: this.selecionado.id} })                                            
            },

        },
        mounted() {
            this.loadAsyncData();            
            
        }
    }
</script>