
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Sobre from '../views/sobre.vue'


/*MENUS*/ 
import manutencao from '../views/dashboard/manutencao.vue'
import atendimento from '../views/dashboard/atendimento.vue'
import relatorio from '../views/dashboard/relatorio.vue'
import venda from '../views/dashboard/venda.vue'
import posvenda from '../views/dashboard/posvenda.vue'


/*MENU MANUTENÇÃO*/ 
import clienteVue from '../views/cadastro/cliente.vue'
import niveldeacesso from '../views/cadastro/niveldeacesso.vue'
import departamentoVue from '../views/cadastro/departamento.vue'
import setor from '../views/cadastro/setor.vue'
import unidade from '../views/cadastro/unidade.vue'
import condicaopagamento from '../views/cadastro/condicaopagamento.vue'
import feriado from '../views/cadastro/feriado.vue'
import arquiteto from '../views/cadastro/arquiteto.vue'
import tipoanexo from '../views/cadastro/tipoanexo.vue'
import prazo from '../views/cadastro/prazo.vue'
import modelodocumento from '../views/cadastro/modelodocumento.vue'
import custo from '../views/cadastro/custo.vue'
import especiepagamento from '../views/cadastro/especiepagamento.vue'
import funcionarioVue from '../views/cadastro/funcionario.vue'
import fornecedorVue from '../views/cadastro/fornecedor.vue'
import tipoAgendamento from '../views/cadastro/tipoagendamento.vue'
import motivoVue from '../views/cadastro/motivo.vue'

import tiposdeAgendamento from '../views/lista/tiposdeagendamento.vue'
import clientesVue from '../views/lista/clientes.vue'
import niveisDeAcessoVue from '../views/lista/niveisdeacesso.vue'
import departamentosVue from '../views/lista/departamentos.vue'
import setoresVue from '../views/lista/setores.vue'
import unidadesVue from '../views/lista/unidades.vue'
import condicoespagamentosVue from '../views/lista/condicoespagamentos.vue'
import feriadosVue from '../views/lista/feriados.vue'
import arquitetosVue from '../views/lista/arquitetos.vue'
import tiposanexosVue from '../views/lista/tiposanexos.vue'
import prazosVue from '../views/lista/prazos.vue'
import modelodocumentosVue from '../views/lista/modelodocumentos.vue'
import custosVue from '../views/lista/custos.vue'
import especiesPagamentosVue from '../views/lista/especiespagamentos.vue'
import fornecedoresVue from '../views/lista/fornecedores.vue'
import funcionariosVue from '../views/lista/funcionarios.vue'
import motivosVue from '../views/lista/motivos.vue'


/*MENU ATENDIMENTO*/ 
import agendaVue from '../views/atendimento/agenda.vue'
import agendamentoVue from '../views/atendimento/agendamento.vue'
import agendaRelacaoVue from '../views/atendimento/agenda-relacao.vue'
import guiaVue from '../views/atendimento/guia.vue'
import guiaList from '../views/atendimento/guia-list.vue'


/*MENU RELATORIO*/ 
import notificacaoRelVue from '../views/relatorio/notificacao.vue'
import logsRelVue from '../views/relatorio/logs.vue'
import clientesRelVue from '../views/relatorio/clientes.vue'
import funcionariosRelVue from '../views/relatorio/funcionarios.vue'
import inatividadeRelVue from '../views/relatorio/inatividade.vue'
import desistenciasRelVue from '../views/relatorio/desistencias.vue'
import anexosduplicadosRelVue from '../views/relatorio/anexosduplicados.vue'
import anexosindefinidosRelVue from '../views/relatorio/anexosindefinidos.vue'
import atrasadosRelVue from '../views/relatorio/atrasados.vue'


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Sobre',
		component: Sobre,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'guia-list',
		path: '/atendimento/List',
		component: guiaList,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'novaguia',
		path: '/atendimento/cliente/:clienteId',
		component: guiaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'guia',
		path: '/atendimento/guia/:id?',
		component: guiaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'agenda-relacao',
		path: '/atendimento/agenda-relacao',
		component: agendaRelacaoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'agendamento',
		path: '/atendimento/agendamento/:id?',
		component: agendamentoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'motivos',
		path: '/manutencao/motivos',
		component: motivosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'motivo',
		path: '/manutencao/motivo',
		component: motivoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},			
	{
		name: 'departamentos',
		path: '/manutencao/departamentos',
		component: departamentosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'departamento',
		path: '/manutencao/departamento',
		component: departamentoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'clientes',
		path: '/manutencao/clientes/:procurar?/',
		component: clientesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'cliente',
		path: '/manutencao/cliente/:id?/',
		component: clienteVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'niveldeacesso',
		path: '/manutencao/niveldeacesso',
		component: niveldeacesso,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'setor',
		path: '/manutencao/setor',
		component: setor,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'unidade',
		path: '/manutencao/unidade',
		component: unidade,
		meta: {
			roles: ["Funcionario"]
		}
	},
	

	{
		name: 'condicaopagamento',
		path: '/manutencao/condicaopagamento',
		component: condicaopagamento,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'feriado',
		path: '/manutencao/feriado',
		component: feriado,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'arquiteto',
		path: '/manutencao/arquiteto',
		component: arquiteto,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'tipoanexo',
		path: '/manutencao/tipoanexo',
		component: tipoanexo,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'prazo',
		path: '/manutencao/prazo',
		component: prazo,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'modelodocumento',
		path: '/manutencao/modelodocumento',
		component: modelodocumento,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'custo',
		path: '/manutencao/custo',
		component: custo,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'niveisdeacesso',
		path: '/manutencao/niveisdeacesso',
		component: niveisDeAcessoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'setores',
		path: '/manutencao/setores',
		component: setoresVue,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'unidades',
		path: '/manutencao/unidades',
		component: unidadesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'condicoespagamentos',
		path: '/manutencao/condicoespagamentos',
		component: condicoespagamentosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	
	{
		name: 'feriados',
		path: '/manutencao/feriados',
		component: feriadosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'arquitetos',
		path: '/manutencao/arquitetos',
		component: arquitetosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'tipoanexos',
		path: '/manutencao/tipoanexos',
		component: tiposanexosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'prazos',
		path: '/manutencao/prazos',
		component: prazosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'modelodocumentos',
		path: '/manutencao/modelodocumentos',
		component: modelodocumentosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'custos',
		path: '/manutencao/custos',
		component: custosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'fornecedores',
		path: '/manutencao/fornecedores',
		component: fornecedoresVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'fornecedor',
		path: '/manutencao/fornecedor',
		component: fornecedorVue	,
		meta: {
			roles: ["Funcionario"]
		}
	},
	
	{
		name: 'especiepagamento',
		path: '/manutencao/especiepagamento',
		component: especiepagamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'especiespagamentos',
		path: '/manutencao/especiespagamentos',
		component: especiesPagamentosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	
	{
		name: 'funcionarios',
		path: '/manutencao/funcionarios',
		component: funcionariosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'funcionario',
		path: '/manutencao/funcionario',
		component: funcionarioVue,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'manutencao',
		path: '/manutencao',
		component: manutencao,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'atendimento',
		path: '/atendimento',
		component: atendimento,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'relatorio',
		path: '/relatorio',
		component: relatorio,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'notificacao',
		path: '/relatorio/notificacao',
		component: notificacaoRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'logs',
		path: '/relatorio/logs',
		component: logsRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'clientesRel',
		path: '/relatorio/clientes',
		component: clientesRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'funcionariosRel',
		path: '/relatorio/funcionarios',
		component: funcionariosRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'inatividadeRel',
		path: '/relatorio/inatividade',
		component: inatividadeRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},		
	{
		name: 'desistencias',
		path: '/relatorio/desistencias',
		component: desistenciasRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'anexosduplicados',
		path: '/relatorio/anexosduplicados',
		component: anexosduplicadosRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'anexosindefinidos',
		path: '/relatorio/anexosindefinidos',
		component: anexosindefinidosRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'atrasados',
		path: '/relatorio/atrasados',
		component: atrasadosRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},								
	{
		name: 'venda',
		path: '/venda',
		component: venda,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'posvenda',
		path: '/posvenda',
		component: posvenda,
		meta: {
			roles: ["Funcionario"]
		}
	},						
	{
		name: 'tiposdeagendamento',
		path: '/manutencao/tiposdeagendamento',
		component: tiposdeAgendamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'tipoagendamento',
		path: '/manutencao/tipoagendamento',
		component: tipoAgendamento,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'agenda',
		path: '/atendimento/agenda',
		component: agendaVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active'
})

const storeInit = store.dispatch('init')

router.beforeEach(async (to, from, next) => {
	await storeInit.then(function() {

		if(store.state.usuario.id != undefined){	
			if(store.state.usuario.tipos[0] == "Funcionario"){
				if (to.matched.some(record => record.meta.roles.filter(role => role == "Funcionario").length > 0)) {
					next()
				} else {
					next("/")
				}
			}
		}
		else {
			next()
		}
	})
    .catch(e => {
		console.error(e);
    })

})

export default router
