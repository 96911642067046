<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-5">
                            <periodoHora
                            :dataInicial.sync="dataInicial"
                            :dataFinal.sync="dataFinal"
                            :placeHolder="$t('AGENDARELACAO.SELECIONEPERIODO')"
                            :selecionadas="[dataInicial,dataFinal]"
                            />
                        </div>
                        <div class="column is-1">
                            <button class="button is-info" type="button" @click="filtrar({dataInicial, dataFinal})">{{$t('AGENDARELACAO.FILTRAR')}}</button>
                        </div>    
                    </div>
                    <div class="columns">
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.BUSCACLIENTE')}}</b>
                            <b-field>
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                        v-model="procurar"
                                        icon="magnify"
                                        @input="loadAsyncData"></b-input>
                            </b-field>
                        </div>                        
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARRESPONSAVEL')}}</b>
                            <b-select v-model="funcionarioIdFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.funcionariosAtivosFiltro">
                                    <option v-if="funcionarioIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARAGENDAMENTO')}}</b>
                            <b-select v-model="tipoAgendamentoIdFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.tiposAgendamentoAtivosFiltro">
                                    <option v-if="tipoAgendamentoIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARSTATUS')}}</b>
                            <b-select v-model="statusFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.statusAgenda">
                                    <option v-if="statusFiltro == item.valor" selected v-bind:key="index" :value="item.valor">
                                        {{item.descricao}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.valor">
                                        {{item.descricao}}
                                    </option>
                                </template>
                            </b-select>
                        </div>

                    </div>

                     <br />

                    <b-table class="box" :data="model" striped hoverable>

                        <template>
                            <b-table-column v-slot="props" field="id" label="Id">
                                {{ props.row.id}}
                                <b v-if="isConfirmado(props.row) && !isCancelado(props.row)" :title="$t('AGENDA.CLIENTECONFIRMADO')" class="has-text-success">&radic;</b>
                                <b-icon v-if="isCancelado(props.row)"
                                    icon="close-circle"
                                    size="is-small"                                    
                                    :title="$t('AGENDARELACAO.AGENDAMENTOCANCELADO')"
                                    type="is-danger"></b-icon>                                 
                            </b-table-column>                            
                            <b-table-column v-slot="props" field="data" :label="$t('SISTEMA.DATA')">
                                {{ props.row.dataHora | datahora}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="cliente" :label="$t('SISTEMA.CLIENTE')">
                                {{ props.row.clienteNome ?? props.row.nome}}
                            </b-table-column>                
                            <b-table-column  v-slot="props" field="fone" :label="$t('AGENDARELACAO.FONE')">
                                {{ props.row.clienteFone}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="Observacao" :label="$t('SISTEMA.OBSERVACAO')">                                
                                 <span v-html="tratamentoQuebraLinha(props.row.observacao)"></span>
                            </b-table-column>
                            <b-table-column  v-slot="props" field="tipoagendamento" :label="$t('AGENDARELACAO.TIPOAGENDAMENTO')">
                                {{ props.row.tipoAgendamentoNome}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="funcionarioresponsavel" :label="$t('AGENDARELACAO.FUNCIONARIORESPONSAVEL')">
                                {{ props.row.funcionarioResponsavelNome}}
                            </b-table-column>   
                            <b-table-column v-slot="props" field="log" :label="$t('AGENDARELACAO.ACOES')">                                
                                <b-dropdown
                                    v-show="isAgendado(props.row)"
                                    append-to-body                    
                                    position="is-bottom-left"                        
                                    aria-role="list"
                                    trap-focus >
                                    <template #trigger="{ active }">
                                        <b-button tag="a" :icon-right="tipoIcone(active)" />                        
                                    </template>
                                
                                    <b-dropdown-item aria-role="listitem"  @click="verGuiasCliente(props.row)">{{$t('AGENDA.VERGUIACLIENTE')}} </b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem"  @click="incluirNovoHorarioDia(props.row, props.row.dataHora)">{{$t('SISTEMA.EDITAR')}}</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" v-if="!isConfirmado(props.row)"  @click="confirmarCliente(props.row)">{{$t('SISTEMA.CONFIRMAR')}} </b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" v-if="!isCancelado(props.row)"   @click="confirmarCancelamento(props.row)">{{$t('SISTEMA.CANCELAR')}} </b-dropdown-item>                                                                                
                                    <hr class="dropdown-divider" aria-role="menuitem">                    
                                    <b-dropdown-item aria-role="listitem"  @click="exibirHistorico(props.row)"><b-icon  icon="clock" ></b-icon>{{$t('SISTEMA.HISTORICO')}} </b-dropdown-item>                                               
                                </b-dropdown>
                            </b-table-column>

                        </template>
                    </b-table>
                </article>
            </div>
        </div>        
    </section>
</template>

<style lang="scss" scoped>

</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import moment from 'moment';    
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";    
    import modalCancelandoAgenda from '@/components/atendimento/modalCancelandoAgenda'
    import debounce from 'lodash/debounce';
    import { mapState } from 'vuex'

    export default {
        components: {
            titlebar,
            periodoHora            
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),             
            titleStack() {
                return [
                    this.$t('SISTEMA.ATENDIMENTO'),
                    this.$t('AGENDARELACAO.RELACAOAGENDAMENTO'),
                ]
            },
            ...mapState([
                'config',
            ]),
        },
        
        data() {
            return {
                dataInicial: moment().add('month',-1).set('hours',0).set('minutes',0).toDate(),
                dataFinal: moment().set('hours',23).set('minutes',59).toDate(),
                model: [],
                atendimentoInterno: true,
                coletaDomiciliar: true,
                item: {
                    tipoAgendamento: null,
                },
                funcionarioIdFiltro: null,
                funcionariosAtivosFiltro: [],
                tipoAgendamentoIdFiltro: null,
                statusFiltro: null,
                tiposAgendamentoAtivosFiltro: [],
                statusAgenda:[],
                isLoading: false,
                loadedData: false,
                todos: null,
                procurar:  this.$route.params.clienteNome ?? null,                
            }
        },
        created(){                        
            try {
                if (this.$route.query.dataInicial) this.dataInicial = moment(this.$route.query.dataInicial,'DD/MM/YYYY HH:mm').toDate();
                if (this.$route.query.dataFinal) this.dataFinal = moment(this.$route.query.dataFinal,'DD/MM/YYYY HH:mm').toDate();            
                
                this.loadData();
            }catch(ex){
                console.error(ex);
            }
        },
        mounted() {
            this.$http.get('/api/search/FuncionariosAtivos')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.funcionariosAtivosFiltro = data;
                }
            });

            this.$http.get('/api/search/ListarStatusAgenda')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.statusAgenda = data;
                }
            });

            this.$http.get('/api/search/tiposagendamentos' + 
                (this.$route.query.unidadeId != null ? `?unidadeId=${this.$route.query.unidadeId}` : ''))
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.tiposAgendamentoAtivosFiltro = data;
                }
            });            
        },
        filters: {
            datahora:  (valor) => {
            if (!valor) return null;    
            
            return  moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        },           
        methods: {  

            confirmarCancelamento(evento){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCancelandoAgenda,
                    props: {
                        id: evento.id
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },
            incluirNovoHorarioDia(evento, horario){
                
                const params = {};

                if (evento.tipoAgendamentoId != null) 
                    params.tipoAgendamentoId= evento.tipoAgendamentoId;

                if (horario != null) 
                    params.horario=  moment(horario).toDate(); 
                   
                //Editar Paciente
                if(evento.id && evento.id>0){
                    params.id = evento.id;
                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })
                }                    
              
            },            
            confirmarCliente(evento){
                this.$buefy.dialog.confirm({
                    title: 'Confirmar',
                    message: this.$t('AGENDA.CONFIRMACLIENTE'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {
                        this.agendaStatus(evento,'confirmar')
                    }
                })
            },       
            async agendaStatus(evento,acao) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);

                params.push(`acao=${acao}`);

                try {
                    const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                    const res = await this.$http.get(url);
                    this.loadAsyncData();
                } catch (e) {
                    console.error(e);
                }
            },                     
            tipoIcone(active){                
                return 'format-list-bulleted';                
            },             
            verGuiasCliente(evento){
                this.$router.push({ name: 'guia-list', query: { clienteId: evento.clienteId } })
            },        
            isAgendado(evento){              
                
                return (evento.clienteNome || evento.nome);        
            },  
            isConfirmado(evento){                          
                return (evento.clienteConfirmado);                
            },      
            isCancelado(evento){                          
                return (evento.clienteCancelado);                
            },                                      
            tratamentoQuebraLinha(texto){                
                return (texto) ? texto.replaceAll('\\n', '<br/>') : '';
            }, 
            exibirHistorico(item) {
                this.$buefy.modal.open({
                    parent: this,
                    component: historicoAlteracoes,
                    props: {
                        tabela: '',
                        chave: item.id,
                        toggle: true,
                        agendaLog: true,
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },            
            filtrar(datas) {
                this.dataInicial = datas.dataInicial;
                this.dataFinal = datas.dataFinal;
                this.loadData();
            },



            loadData(){
                const params = [];

                if (this.dataInicial != null) params.push(`dataInicial=${moment(this.dataInicial).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.dataFinal != null) params.push(`dataFinal=${moment(this.dataFinal).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.statusFiltro != null) params.push(`status=${this.statusFiltro}`);
                if (this.tipoAgendamentoIdFiltro != null) params.push(`tipoAgendamentoId=${this.tipoAgendamentoIdFiltro}`);
                if (this.funcionarioIdFiltro != null) params.push(`funcionarioId=${this.funcionarioIdFiltro}`);                
                if (this.unidadeId != null) params.push(`unidadeId=${this.unidadeId}`); 
                if (this.procurar != null) params.push(`cliente=${this.procurar}`);
                if (this.$route.params.clienteId != null) params.push(`clienteId=${this.$route.params.clienteId}`)
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/RelacaoAgenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.model = data?.map(d => {
                                    return {
                                        ...d,
                                        dataHora: moment(d.dataHora).toDate(),
                                        atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                                        atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                                    }
                                });
                            }
                            this.isLoading = false;
                            this.loadedData = true;
                        }).catch(e => {
                            throw e;
                        });
                }catch(e){
                    console.error(e);
                    this.isLoading = false;
                }

            },
            loadAsyncData: debounce(function () {
                this.loadData();
            }, 500),
           
        }
    }
</script>