<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">            
            <p  class="modal-card-title">{{$t('MODALALTERARPRAZOAPRESENTACAO.PRAZOAPRESENTACAO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                                
                <p >{{$t('MODALALTERARPRAZOAPRESENTACAO.AVISO')}}</p>
            </b-notification>            
            <div class="panel">

                <p class="panel-block">
                    <b-field :label="$t('MODALALTERARPRAZOAPRESENTACAO.DATAPREVISTA')">
                    </b-field>
                </p>
                <div class="panel-block">                    
                    <b-datetimepicker ref="data"                                        
                                    :placeholder="$t('AGENDAMENTO.SELECIONARHORA')"
                                    v-model="datahorabloqueado"
                                    icon="calendar-today"                                    
                                    :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                                    horizontal-time-picker>
                    </b-datetimepicker>                                                                         
                </div>
                
                <span v-if="guia.possuiAlteracao">
                    <br>
                    <br>
                    <br>
                </span>                    
                        
                <p v-if="!guia.possuiAlteracao" class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div v-if="!guia.possuiAlteracao" class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            guia: Object,                                                                       
        },
        data() {
            return {                
              
                observacoes: null,
                salvandoDados :false,
                datahorabloqueado: null                
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        methods: {

            async confirmar() {

                if(this.datahorabloqueado !=null ){
                                                                                                                       
                    if(!this.guia.possuiAlteracao && (this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20) )){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }     
                                                            
                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.guia.id}`);                                                                                                
                    if(this.observacoes) params.push(`observacoes=${this.observacoes}`);                                                            
                    
                    params.push(`datahorabloqueado=${this.$moment(this.datahorabloqueado).format('YYYY-MM-DDTHH:mm:ss')}`);      
                                        
                    try {                            
                        this.$http.get(`/api/atendimento/dataHoraPrevisaoApresentacao?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;    
                            this.$emit('loadData')                            
                            this.$emit('close');                                                              
                        });                             
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    } 
                    
                }   
                                     

            }
            

        }
    }
</script>