<template>
    <section style="overflow: hidden;">
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">

                                <div class="column is-2">
                                    <ValidationProvider rules="required" name="Código" v-slot="{ errors, valid }">
                                        <b-field expanded :label="$t('SISTEMA.CODIGO')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="number"  min="0" v-model="model.codigoExterno"></b-input>
                                        </b-field>
                                    </ValidationProvider>                                
                                </div>
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SISTEMA.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input  maxlength="150" type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>

                                    
                                </div>
                                <div v-if="false" class="column is-2">
                                    <label class="label"> &nbsp; &nbsp;</label>
                                    <b-switch v-model="nomeSocial">
                                        {{$t('CLIENTECADASTRO.NOMESOCIAL')}}
                                    </b-switch>                                    
                                </div>
                            </div>                         

                            <div v-if="false" class="columns">
                                <div class="column">
                                    <b-field :label="$t('CLIENTECADASTRO.NOMESOCIAL')">
                                        <b-input maxlength="150" type="text" v-model="model.nomeSocial"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div v-if="false" class="columns is-multiline is-mobile">
                                <div class="column is-full-mobile">
                                    <ValidationProvider name="sexo" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CLIENTECADASTRO.SEXO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select expanded :placeholder="$t('CLIENTECADASTRO.SELECIONESEXO')" v-model="model.sexo">
                                                <option value="F">{{$t('CLIENTECADASTRO.FEM')}}</option>
                                                <option value="M">{{$t('CLIENTECADASTRO.MASC')}}</option>                                                
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">                                    
                                        <b-field :label="$t('CLIENTECADASTRO.EMAIL')">
                                            <b-input maxlength="50" type="email" v-model="model.email" @blur="verificarEmail" :readonly="model.possuiUsuario"></b-input>
                                        </b-field>                                    
                                </div> 
                                <div class="column">                                                                        
                                        <b-field :label="$t('CLIENTECADASTRO.CPF')">
                                            <b-input type="text" v-model="model.cpf" v-mask="'###.###.###-##'"></b-input>
                                        </b-field>                                    
                                </div>   
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CLIENTECADASTRO.RG')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.rg" v-mask="'XXXXXXXXXXXXXXX'">
                                        </div>
                                    </div>
                                </div>                                                              

                            </div>                            


                            <div v-if="false" class="columns">
                                <div class="column is-half-mobile">                                    
                                        <b-field :label="$t('CLIENTECADASTRO.NASCIMENTO')">
                                            <b-datepicker v-model="model.dataDeNascimento" trap-focus editable :max-date="new Date()" 
                                                :min-date="new Date(new Date().getFullYear() - 200, new Date().getMonth(), new Date().getDate())"></b-datepicker>
                                        </b-field>                                    
                                </div>
                                <div class="column is-half-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('CLIENTECADASTRO.IDADE')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" @change="alterouIdade" :value="idade">
                                        </div>
                                    </div>
                                </div>
                                                          
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CLIENTECADASTRO.PASSAPORTE')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.passaporte">
                                        </div>
                                    </div>                  
                                </div>
                                <div class="column">
                                </div>
                            </div>  


                            <div  v-if="false" class="columns">
                                <div class="column">
                                    <b-field :label="$t('CLIENTECADASTRO.FONE')">
                                        <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CLIENTECADASTRO.CELULAR')">
                                        <b-input expanded
                                                 v-model="model.celular" 
                                                 v-mask="['(##) ####-####', '(##) #####-####']"
                                                 :icon-right-clickable="podeEnviarSms"
                                                 @icon-right-click="abrirModalSms"
                                                 :icon-right="iconeEnviarSms"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <label class="label"> &nbsp; &nbsp;</label>
                                    <div class="field">
                                        <b-switch v-model="model.enviaSMS">
                                            {{$t('CLIENTECADASTRO.ENVIAMSG')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </div>


                            <buscaCepEndereco v-if="false"  :cep.sync="model.cep"
                                              :endereco.sync="model.endereco"
                                              :bairro.sync="model.bairro"
                                              :cidadeid.sync="model.cidadeId"
                                              :cidadenome.sync="model.cidadeNome"></buscaCepEndereco>

                            
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CLIENTECADASTRO.OBSERVACOES')}}</label>
                                        <div class="control">
                                            <textarea class="textarea" maxlength="500" v-model="model.observacoes"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </article>
                    </div>
                    <div class="tile is-parent is-3">
                        <article class="tile is-child is-12">
       
                            <historico-alteracoes :tabela="'Cliente'" :chave="model.id"></historico-alteracoes>

                            <article v-if="model.id" class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('SISTEMA.ATENDIMENTO')}}
                                </div>
                                <div class="panel-block">                                    
                                    <b-button expanded class="button " @click="criarGuia = true; onSubmit()" :title="$t('CLIENTECADASTRO.CRIARMENSAGEM')"> {{$t('CLIENTECADASTRO.CRIAR')}} </b-button>
                                    <b-button expanded class="button is-info" @click="listarAtendimentos()" :title="$t('CLIENTECADASTRO.LISTARMENSAGEM')"> {{$t('CLIENTECADASTRO.LISTAR')}}</b-button>
                                </div>
                            </article>

                            <article v-if="model.id" class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('CLIENTECADASTRO.AGENDAMENTOS')}}
                                </div>
                                
                                <div v-for="(agendamento, index) in agendamentos" v-bind:key="index">
                                    <a class="panel-block">
                                        <span class="panel-icon block">                                            
                                            <b-icon :style="tipoAgendamentoStyle(agendamento)"  size="is-small" icon="square-rounded"></b-icon>                                            
                                        </span>                                        
                                        <p> {{agendamento.tipoAgendamentoNome}} - {{ agendamento.dataHora }}</p>
                                    </a>
                                </div>
                                <div class="panel-heading has-text-centered">
                                    <b-button expanded @click="abrirVerMais()" class="button is-info">Ver mais</b-button>
                                </div>
                            </article>   

                            <article v-if="model.id" class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('CLIENTECADASTRO.DOC')}}
                                </div>
                                <div class="panel-block">
                                    <b-button expanded @click="modalIncluirAnexo">{{$t('CLIENTECADASTRO.DOCPESSOAIS')}}</b-button>
                                </div>
                            </article>                            
                                                     
                            

                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('cliente-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>



<script>
    import { mapGetters, mapState } from 'vuex'
    import buscaCepEndereco from '@/components/buscaCepEndereco.vue'
    import titlebar from '@/components/titlebar.vue'    
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import modalEnviarSms from '@/components/modalEnviarSms.vue'
    import moment from 'moment'
    import calcularIdade  from '../../assets/js/views/cliente-idade/calcularIdade.js'
    import modalAnexosGenerico from "@/components/modalAnexosGenerico.vue";

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: this.$route.params.nome ?? null,
                    sexo: null,
                    dataDeNascimento: null,
                    cep: null,
                    endereco: null,
                    bairro: null,
                    cidadeId: null,
                    rg: null,
                    cpf: null,                                        
                    email: null,
                    observacoes: null,                    
                    criaUsuario: false,
                    indicacao: null,                                        
                    foto: null,                    
                    unidadeIdade: null,                    
                    isLoading:false,
                },                
                dataAtual: new Date(new Date().setHours(0,0,0,0)),                                                
                carregando: true,                
                salvandoDados: false,                
                nomeSocial: false,
                fotoAlterada: false,
                criarGuia: Boolean(this.$route.params.criarGuia) ?? false,                                
                arquivosCarregados: false,
                agendamentos: null,

                opcoesIndicacao: [
                    'Google',
                    'Facebook',
                    'Instagram',
                    'Panfleto',
                    'Revista',
                    'Solicitante',
                    'Amigo',
                    'Outros'
                ]
            };
        },
        components: {
            buscaCepEndereco,
            titlebar,            
            historicoAlteracoes
        },     
            
        created() {
            this.isLoading= true;
            if (this.$route.params.id > 0) {

                let url = '/api/manutencao/cliente?id=' + this.$route.params.id;

                if(this.$route.params.codigoExterno){
                    url = '/api/manutencao/cliente?id=' + this.$route.params.id+"&codigoExterno=true&unidadeId="+this.unidadeId;
                }


                this.$http.get(url)
                    .then(res => {
                        this.model = res.data;

                        if(this.model.dataDeNascimento){
                            this.model.dataDeNascimento = new Date(this.model.dataDeNascimento)
                        }

                        if (this.model?.foto){
                            if (this.model.foto.indexOf('data:image/') < 0){
                                this.model.foto = ` data:image/*;charset=utf-8;base64,${this.model.foto}`;
                            }
                        }

                        this.nomeSocial = this.model.nomeSocial != null
                        
                        this.model.criaUsuario = this.model.usuario != null
                        
                        this.loadDataAgendamentos();
                        this.carregando = false
                    }).finally(() => {
                        this.isLoading= false;
                    });
            }
            else {
                this.carregando = false;
                this.isLoading= false;
            }
                        
            
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),                                     
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('SISTEMA.CLIENTE'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            podeEnviarSms() {
                return this.model.celular && this.model.celular.length >= 14
            },
            iconeEnviarSms() {
                return this.podeEnviarSms
                    ? "cellphone-message"
                    : null
            },
            idade() {
                if(this.model.dataDeNascimento == null){
                    return "";
                }

                return this.calcularIdade();
            },
            ...mapState([
                'unidadeId'                
            ]),
            ...mapGetters([
                'isInRole'
            ]),
        },
        methods: {
            listarAtendimentos(){
              this.$router.push({ name: 'guia-list', query: { clienteId: this.model.id } });                                  
            },
            modalIncluirAnexo() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAnexosGenerico,
                    props: {
                        id: this.model.id,
                        titleName: 'Anexos',
                        controller: {
                            mainController: 'manutencao',
                            methodAnexoController: 'anexoCliente',
                            methodRemoverController: 'excluirAnexo'
                        },
                        aliasName: 'Cliente',
                        tipoParam: {
                            firstType: 'id',
                            secondType: 'cliente'
                        },
                    },
                    hasModalCard: true,
                    trapFocus: true
                });
            },            
            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },
            loadDataAgendamentos() {               
                const params = [];

                if (this.dataAtual != null)
                    params.push(`dataInicial=${moment(this.dataAtual).format('YYYY-MM-DDTHH:mm:ss')}`);
                if(this.model.id)
                    params.push(`clienteId=${this.model.id}`);

                params.push(`unidadeId=${this.unidadeId}`);

                try {
                    this.$http.get(`/api/agenda/RelacaoCliente?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.agendamentos = data?.map( (d) => {
                                    return {
                                        id: d.id,                                        
                                        clienteId: d.clienteId,                                        
                                        tipoAgendamentoId: d.tipoAgendamentoId,
                                        tipoAgendamentoCor: d.tipoAgendamentoCor,
                                        tipoAgendamentoNome : d.tipoAgendamentoNome,
                                        dataHora: moment(d.dataHora).format('DD/MM/YYYY HH:mm:ss')
                                    }
                                });
                            }
                        }).catch(e => {
                            throw e;
                        }).finally(() => {
                            this.$forceUpdate();
                            this.carregando = false;
                        });
                } catch (e) {
                    console.error(e);
                    this.carregando = false;
                    throw e;
                }
                
            },            
            abrirVerMais(){
                const params = {};
                params.clienteNome = this.model.nome;

                this.$router.push({ 
                    name: 'agenda-relacao',
                    params: params
                 })
            },                  
            calcularIdade(){
                return calcularIdade.Idade(this.model.dataDeNascimento);
            },

            alterouIdade(event) {
                const id = event.target.value.replace(",", ".");

                if (id < 1) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('CLIENTECADASTRO.IDADEINVALIDA'),
                        type: 'is-danger',
                        queue: false
                    })

                    return;
                }

                if (this.model.unidadeIdade == "D") {
                    const dataNascimento = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                    const quantidade = (id * 24 * 60 * 60 * 1000);
                    this.model.dataDeNascimento = new Date(dataNascimento.valueOf() - quantidade);
                }
                else if (this.model.unidadeIdade == "M") {
                    const dataNascimento = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                    const quantidade = (id * 31 * 24 * 60 * 60 * 1000);
                    this.model.dataDeNascimento = new Date(dataNascimento.valueOf() - quantidade);
                }
                else {
                    this.model.dataDeNascimento = new Date(new Date().getFullYear() - id, new Date().getMonth(), new Date().getDate()-1);
                }
            },

            verificarEmail() {
                this.$http.get(`/api/manutencao/VerificarUsuarioEmail?email=${this.model.email}`)
                    .then(res => {
                        if (res.data?.existe && !(res.data.tipo == 'cliente' && res.data.id == this.model.id)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('CLIENTECADASTRO.EMAILMSG') + res.data.email + this.$t('CLIENTECADASTRO.EMUSO') + res.data.tipo + ' código ' + res.data.id,
                                type: 'is-danger',
                                queue: false
                            })
                            this.model.email = null
                        }
                    });
            },

            async validarCodigoExterno(){

                let retorno = false;
                if(this.model.id==null && this.model.codigoExterno > 0){

                    await this.$http.get(`/api/manutencao/CheckCodigoExterno?codigoExterno=${this.model.codigoExterno}&unidadeId=${this.unidadeId}`)
                        .then(res => {
                            this.salvandoDados = false;
                            if(res.data > 0){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('CLIENTECADASTRO.CODIGOEXTERNOEMUSO'),
                                    type: 'is-danger',
                                    queue: false
                                });                                
                            }else{
                                retorno = true;
                            }
                        })
                        .catch(err => {
                            this.salvandoDados = false;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('CLIENTECADASTRO.ERROSALVARCLIENTE') + err.body.title,
                                type: 'is-danger',
                                queue: false
                            });
                            return false;
                        });
                    
                }else{
                    retorno = true;
                }

                return retorno;
                
            },
          
            async onSubmit() {

                this.salvandoDados = true;

                if (!this.nomeSocial && this.model.nomeSocial != null) {
                    this.model.nomeSocial = null
                }

                this.model.UnidadeDeCadastro = { id : this.unidadeId};                
                
                if(this.model.criaUsuario && this.model.email == null){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('CLIENTECADASTRO.FALHAUSUARIO'),
                        type: 'is-danger',
                        queue: false
                    });
                    this.$refs.email.focus();
                    this.salvandoDados = false;
                    return;
                }

                if(await this.validarCodigoExterno()){
                    this.salvarCliente();                                        
                }

            },
            salvarCliente(){
                
                this.model.nome = this.model.nome.toUpperCase();
                this.$http.post('/api/manutencao/cliente', this.model)
                    .then((res) => {
          
                        if(res.data.mensagem != null){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.mensagem,
                                type: 'is-success',
                                queue: false
                            })
                        }
                                                
                        if (this.criarGuia && !this.model.desativado) {                            
                            this.$router.push({ name: 'novaguia', params: { clienteId: res.data.id } });                                
                        }else{
                            this.$router.back();
                        }                        
                        
                                                
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        console.error(err);
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('CLIENTECADASTRO.ERROCLIENTE') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },

            abrirModalSms() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalEnviarSms,
                    props: {
                        numero: this.model.celular,
                        id: this.model.id
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
          


        }
    }
</script>