<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALISTARDETALHESMENSAGEM.DETALHES')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">

                <div class="panel-block">                     
                    <table  v-if="detalhes !=null && detalhes.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>                                
                                <th>Envolvido</th>
                                <th>Lido?</th> 
                            </tr>                                                
                        </thead>                        
                        <tbody>                                                
                            <tr v-for="(obs, index) in detalhes" :key="index">
                                                                       
                                <td><b>{{ obs.nomeFuncionario  }}</b></td>
                                <td>   
                                    <span v-if="obs.lido">
                                        <b-switch :disabled="true" v-model="obs.lido"  type="is-success">
                                            Sim
                                        </b-switch> 
                                        <span v-if="obs.lido">
                                            <br>
                                            <small class="has-text-success">
                                                <b>{{$moment(obs.dataHoraLido).format("DD/MM/YYYY HH:mm") }} </b>                                                       
                                            </small>
                                        </span>                                           
                                    </span>                                 
                                  

                                </td>                                
                            </tr>
                        </tbody>                        
                    </table>  
                </div>                     
                            

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button @click="$emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            observacaoId: Number   
        },
        data() {
            return {                                              
                salvandoDados :false,                
                detalhes:[]                                     
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.loadDetalhes();
        },
        methods: {

            loadDetalhes() {    
                this.salvandoDados = true;
                
                this.$http.get(`/api/atendimento/ListarDetalhesMensagem?observacaoId=${this.observacaoId}`)
                .then(res => res.data)
                .then(data => {

                    if(data && data!=null){

                        this.detalhes = data;                          
                    }
     
                });               
                                                                                         
            },                        

        }
    }
</script>